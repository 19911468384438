import styled from 'styled-components';
import BgSimulation from '../../images/img_bg_simulate.jpg';
import BgSimulationMobile from '../../images/img_bg_simulate_mobile.jpg';

export const CntSimulation = styled.section`
  background: url(${BgSimulation}) center center no-repeat;
  background-attachment: fixed;
  /* min-height: 651px; */
  background-size: cover;
  padding-top: 11.8rem;
  margin-top: ${(props) => (props.hasBlog ? '41.5rem' : '0px')};
  @media screen and (max-width: 600px) {
    background: url(${BgSimulationMobile}) center center no-repeat;
    background-attachment: fixed;
    background-size: cover;
    margin-top: ${(props) => (props.hasBlog ? '45.5rem' : '0px')};
  }
  .cnt-blog {
    margin-top: -465px;
    margin-bottom: 15.9rem;
    @media screen and (max-width: 800px) {
      margin-bottom: 5.6rem;
    }
    .top {
      @media screen and (max-width: 800px) {
        * {
          text-align: center;
        }
      }
      .sup-title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 140%;
        letter-spacing: 0.1em;
        color: #6690f8;
        margin-bottom: 1rem;
      }

      .cnt-top {
        margin-bottom: 5.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 500;
          font-size: 3.2rem;
          line-height: 124.5%;
          color: #0f204d;
          max-width: 740px;
          @media screen and (max-width: 600px) {
            font-size: 3rem;
          }
          @media screen and (max-width: 400px) {
            font-size: 2.8rem;
          }
        }

        .go-blog {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 20px;
          color: #fe4059;
          display: flex;
          align-items: center;
          img {
            margin-left: 15px;
          }
          @media screen and (max-width: 800px) {
            display: none;
          }
        }
      }
    }
    .swiper {
      .swiper-slide {
        max-width: 100%;
        max-width: 399px;
      }
    }
    .go-blog {
      &.mob {
        color: white;
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 20px;
        align-items: center;
        display: none;
        margin-top: 6.9rem;
        padding-bottom: 6.9rem;
        justify-content: center;
        border-bottom: 1px solid rgba(102, 144, 248, 0.35);
        @media screen and (max-width: 800px) {
          display: flex;
        }
      }
      img {
        margin-left: 15px;
      }
    }
    .cards {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      a {
        flex: 1;
        min-width: 270px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
      /* gap: 12px; */
    }
  }
  .cnt-simu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    .text {
      max-width: 473px;
      min-height: 525px;
      flex: 1;
      @media screen and (max-width: 800px) {
        margin: 0 auto;
        text-align: center;
      }
      .sup-title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1.7rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #48fbff;
        margin-bottom: 2.8rem;
      }
      .title {
        max-width: 423px;
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 600;
        font-size: 4.6rem;
        line-height: 130%;
        color: #ffffff;
        margin-bottom: 2.8rem;
        @media screen and (max-width: 800px) {
          margin: 0 auto 2.8rem;
          max-width: 90%;
        }
      }

      .text-desc {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 150%;
        color: #ffffff;
        margin-bottom: 4.4rem;
      }

      .btn-default {
        @media screen and (max-width: 800px) {
          margin: 0 auto;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
          max-width: 343px;
          margin: 0 auto;
        }
      }
    }

    .imgs {
      margin-bottom: -320px;
      flex: 1;
      max-width: 640px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      canvas {
        max-width: 313px;
        object-fit: contain;
        margin-top: -107px;
      }
      @media screen and (max-width: 1000px) {
        margin-right: -170px;
      }
      @media screen and (max-width: 800px) {
        display: none;
      }
      .first {
        transform: translateY(280px);
      }
      .last {
        transform: translateY(40px);
      }
    }
  }
`;
