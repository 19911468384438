import React from 'react';

import { CntAd } from './styles';
import ArrowRightAd from '../../images/icon_arrow_right_blue.svg';

const TopAd = () => (
  <CntAd>
    <div className="container">
      <span>
        Soluções com juros justos que dão aquela forcinha
        na hora do aperto.
        <a href={`${process.env.REACT_APP_WEBAPP_URL}`}>Aproveite para fazer um contato agora</a>
      </span>
      <img src={ArrowRightAd} alt="" />
    </div>
  </CntAd>
);

export default TopAd;
