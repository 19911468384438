import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import SignupForm from 'components/signup-form';
import LpFooter from 'components/lp-footer';
import axios from 'axios';
import { getAppToken } from 'services/auth';
import {
  Header, Content, Middle, Container,
  BackgroundImage,
} from './styles';
import AliLogo from '../../images/Logo-ali-2023.svg';
import BubbleWallpaper from '../../images/bubbles.svg';

const LandingPageCampaigns = () => {
  const { company } = useParams();
  const [pathData, setPathData] = React.useState(null);
  const [isValid, setIsValid] = React.useState(null);
  const history = useHistory();

  React.useEffect(() => {
    const checkEndpoint = async () => {
      try {
        const token = await getAppToken('form');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}landing-pages/path/${company}`, { headers: { Authorization: `Bearer ${token}` } });

        if (response.status === 200) {
          setPathData(response.data);
          setIsValid(true);
        }
      } catch (error) {
        setIsValid(false);
      }
    };

    checkEndpoint();
  }, [company]);

  React.useEffect(() => {
    if (isValid === false) {
      history.push('/');
    }
  }, [isValid, history]);

  if (isValid === null) {
    return false;
  }

  if (isValid) {
    return (
      <Container>
        <Helmet>
          <title>
            Ali |&nbsp;
            {company.charAt(0).toUpperCase() + company.slice(1)}
          </title>
        </Helmet>
        <Header>
          <img src={AliLogo} alt="Ali logo" style={{ height: '40px' }} />
          <img src={pathData.logoUrl} alt="Ali logo" style={{ height: '40px' }} />
        </Header>
        <Content>
          <Middle>
            <h1>
              Ative o seu benefício de crédito Ali para redução de dívidas
            </h1>
            <SignupForm company={pathData.id} origin="form" />
          </Middle>
          <BackgroundImage>
            <img src={BubbleWallpaper} alt="wallpaper" />
          </BackgroundImage>
        </Content>
        <LpFooter />
      </Container>
    );
  }

  return null;
};

export default LandingPageCampaigns;
