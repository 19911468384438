import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import { useLax } from 'use-lax';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { Link } from 'react-router-dom';
import AnimatedNumber from '../../components/animatedNumber/AnimatedNumber';
import { getEconomia, getItemAmountByValue } from '../../helpers/functions';

import {
  CntAboutUs,
  CntHeroAboutUs,
  WhatWeDo,
  HelpingYou,
  TeamAli,
  OurMission,
  Cards,
  SaveMoney,
  BePartner,
} from './styles';

import arrowRight from '../../images/icon_arrow_right_white.svg';
import iconSafe from '../../images/icon_about_us_safe.svg';
import imgWhatWeDo from '../../images/icon_about_us_what_we_do.svg';
import iconDigital from '../../images/icon_about_us_digital.svg';
import iconSupport from '../../images/icon_about_us_support.svg';
import iconTransparency from '../../images/icon_about_us_transparency.svg';
import iconCash from '../../images/icon_cash.svg';
import iconGlobe from '../../images/icon_tab_globe.svg';
import iconPerson from '../../images/icon_person.svg';
import iconWhatsWhite from '../../images/icon_whats_white.svg';
import iconBox1 from '../../images/icon_about_us_box1.svg';
import iconBox2 from '../../images/icon_about_us_box2.svg';
import iconBox3 from '../../images/icon_about_us_box3.svg';
import iconBox4 from '../../images/icon_about_us_box4.svg';
import iconBox5 from '../../images/icon_about_us_box5.svg';
import iconBox6 from '../../images/icon_about_us_box6.svg';

import team1 from '../../images/img_team_ali1.jpg';
import team2 from '../../images/img_team_ali2.png';
import team3 from '../../images/img_team_ali3.jpg';
import team4 from '../../images/img_team_ali4.png';
import iconMissionArrow from '../../images/icon_our_mission_arrow.svg';
import iconLogoBlue from '../../images/logo_ali_small_blue.svg';
import iconOurMission1 from '../../images/icon_our_mission_1.svg';
import iconOurMission2 from '../../images/icon_our_mission_2.svg';
import iconOurMission3 from '../../images/icon_our_mission_3.svg';
import iconOurMission4 from '../../images/icon_our_mission_4.svg';
import iconArrowDown from '../../images/icon_our_team_arrow_down.svg';
import iconChevronBlueRight from '../../images/icon_chevron_right_blue.svg';
import iconCar from '../../images/icon_save_money_car.svg';
import iconTv from '../../images/icon_save_money_tv.svg';
import iconCell from '../../images/icon_save_money_cell.svg';
import iconKey from '../../images/icon_save_money_key.svg';
import icon3Dots from '../../images/icon_3dots.svg';
import logoAliSmall from '../../images/logo_ali_small_white.svg';
import iconChevronRight from '../../images/icon_right_chevron.svg';
import IconStepCheck from '../../images/icon_step_check.svg';
import IconDots from '../../images/icon_dots_form.svg';

SwiperCore.use([Navigation, Pagination]);

const AboutUs = () => {
  useLax();
  useHubspotForm({
    portalId: '20869742',
    formId: '3b157591-e008-4855-9cd3-ba7e55777eca',
    target: '#my-hubspot-form',
  });
  return (
    <CntAboutUs>
      <CntHeroAboutUs>
        <div className="city lax" data-lax-translate-y="(vh) -200, (-elh) 200" data-lax-scale=" 500 1,1000 1.4" data-lax-anchor="self" />
        <div className="persons lax" data-lax-translate-y="(vh) -50, (-elh) 50" data-lax-anchor="self" />
        <div className="cloud1 lax" data-lax-translate-y="(vh) 200, (-elh) -400" data-lax-anchor="self" />
        <div className="cloud2 lax" data-lax-translate-y="(vh) 200, (-elh) -200" data-lax-anchor="self" />
        <div className="container">
          <div className="up-title">ACESSO A CRÉDITO JUSTO DE VERDADE</div>
          <h1 className="title">
            Impactando positivamente a vida
            de milhares de brasileiros
          </h1>
          <div className="cnt-btn">
            <a href={`${process.env.REACT_APP_WEBAPP_URL}`} target="_blank" rel="noreferrer" className="btn-default">
              Simule aqui
              <img src={arrowRight} alt="" />
            </a>
            <Link to="/para-empresas" className="desc">Seja um parceiro Ali</Link>
          </div>
        </div>
      </CntHeroAboutUs>
      <WhatWeDo>
        <div className="container">
          <div className="square">
            <img src={logoAliSmall} alt="" />
          </div>
          <div className="cnt-text">
            <div className="up-title">A SUA MAIOR ALIADA FINANCEIRA</div>
            <h2 className="title">Somos apaixonados pelo o que fazemos</h2>
            <div className="desc">
              A Ali nasceu em 2018 com o objetivo de ser a sua aliada financeira.
              Aqui oferecemos muito mais do que crédito com taxas justas, temos o compromisso
              em efetivamente reduzir o endividamento dos brasileiros. Por isso, queremos estar
              ao lado das pessoas não apenas nos momentos de urgência,
              mas também queremos participar de toda a sua jornada rumo à liberdade financeira.
            </div>
            <div className="cnt-itens">
              <div className="item">
                <div className="icon"><img src={iconSafe} alt="" /></div>
                <div className="txt">O nosso processo é seguro & rápido</div>
              </div>
              <div className="line" />
              <div className="item">
                <div className="icon"><img src={iconDigital} alt="" /></div>
                <div className="txt">Somos 100% conectados e digitais</div>
              </div>
              <div className="line" />
              <div className="item">
                <div className="icon"><img src={iconSupport} alt="" /></div>
                <div className="txt">Temos um suporte extremamente ágil</div>
              </div>
              <div className="line" />
              <div className="item">
                <div className="icon"><img src={iconTransparency} alt="" /></div>
                <div className="txt">Totalmente transparente com todas as informações</div>
              </div>
            </div>
          </div>
          <div className="cnt-imgs" data-aos="fade-left">
            <img src={imgWhatWeDo} alt="" />
          </div>
        </div>
      </WhatWeDo>
      <HelpingYou>
        <div className="container">
          <div className="cnt-text">
            <div className="up-title" data-aos="fade-up">COMO FUNCIONA ESSE PROCESSO</div>
            <h2 className="title" data-aos="fade-up" data-aos-delay="100">Ajudamos você a conquistar a sua liberdade financeira</h2>
            <div className="desc" data-aos="fade-up" data-aos-delay="200">Apostamos na construção de uma relação de empatia e confiança com nossos clientes para oferecer uma experiência personalizada que verdadeiramente resolva problemas e facilite vidas.</div>
          </div>
          <div className="cnt-flow">
            <div className="step1">
              <div className="square">
                <div className="border" />
                <div className="icon">
                  <img src={iconCash} alt="" />
                </div>
                <div className="text">
                  Crédito consignado da Ali
                </div>
              </div>
            </div>
            <div className="step2">
              <div className="box first">
                <img src={iconGlobe} alt="" />
                <span>Para a empresa</span>
              </div>
              <div className="line" />
              <div className="box second">
                <img src={iconPerson} alt="" />
                <span>Para o colaborador</span>
              </div>
            </div>
            <div className="step3">
              <div className="cnt-step3">
                <a href={`${process.env.REACT_APP_DUVIDAS_URL}`} target="_blank" rel="noreferrer" className="btn-default whats">
                  <img src={iconWhatsWhite} alt="" />
                  <span>Olá, precisa de ajuda? Fale conosco</span>
                </a>
                <div className="cnt-boxes">
                  <div className="box">
                    <div className="border" />
                    <div className="icon">
                      <img src={iconBox1} alt="" />
                    </div>
                    <div className="box-text">
                      Sem custos para empresa
                    </div>
                  </div>
                  <div className="box">
                    <div className="border" />
                    <div className="icon">
                      <img src={iconBox2} alt="" />
                    </div>
                    <div className="box-text">
                      Diferentes dos bancos
                    </div>
                  </div>
                  <div className="box">
                    <div className="border" />
                    <div className="icon">
                      <img src={iconBox3} alt="" />
                    </div>
                    <div className="box-text">
                      Rapidez
                    </div>
                  </div>
                  <div className="box">
                    <div className="border" />
                    <div className="icon">
                      <img src={iconBox4} alt="" />
                    </div>
                    <div className="box-text">
                      100% digital
                    </div>
                  </div>
                  <div className="box">
                    <div className="border" />
                    <div className="icon">
                      <img src={iconBox5} alt="" />
                    </div>
                    <div className="box-text">
                      Educação
                      financeira
                    </div>
                  </div>
                  <div className="box">
                    <div className="border" />
                    <div className="icon">
                      <img src={iconBox6} alt="" />
                    </div>
                    <div className="box-text">
                      Crédito com taxas justas
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HelpingYou>
      <TeamAli>
        <div className="container">
          <div className="top">
            <div className="infos">
              <h3 className="sub-title">TIME ALI</h3>
              <h2 className="title">Nosso time de apaixonados pelo que fazem</h2>
            </div>
          </div>
          <div className="swiper">
            <Swiper
              spaceBetween={32}
              slidesPerView="auto"
              pagination={{
                el: '.swiper-paginator-cards',
                type: 'fraction',
                renderFraction: (currentClass, totalClass) => (`
            <span class="${currentClass}"></span> 
            de 
            <span class="${totalClass}"></span>`),
              }}
              navigation={{
                nextEl: '.swiper-button-next-cards',
                prevEl: '.swiper-button-prev-cards',
              }}
            >
              <SwiperSlide>
                <div className="box" data-aos="fade-up">
                  <img src={team1} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box" data-aos="fade-up" data-aos-delay="100">
                  <img src={team2} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box" data-aos="fade-up" data-aos-delay="200">
                  <img src={team3} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box" data-aos="fade-up" data-aos-delay="300">
                  <img src={team4} alt="" />
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="navigation">
              <div className="swiper-paginator-cards" />
              <div className="swiper-button-prev-cards" />
              <div className="swiper-button-next-cards" />
            </div>
          </div>
        </div>
      </TeamAli>
      <OurMission>
        <div className="container">
          <div className="left" data-aos="fade-right">
            <div className="up-title">
              <span>INQUIETUDE NOS MOVE</span>
              <img src={iconMissionArrow} alt="" />
            </div>
            <h2 className="title">A nossa missão é conquistar para todos a oportunidade de ter independência financeira</h2>
            <div className="desc">Você nem precisa sair de casa. Em todas as etapas da sua jornada, desde a simulação até a assinatura de contrato, tudo é feito de forma digital pelo nosso website ou WhatsApp da Ali.</div>
            <div className="icon-ali">
              <img src={iconLogoBlue} alt="" />
            </div>
          </div>
          <div className="cnt-boxes" data-aos="fade-left">
            <div className="box">
              <div className="icon">
                <img src={iconOurMission1} alt="" />
              </div>
              <div className="title">A preferida das empresas</div>
              <div className="box-desc">Oferecemos a melhor taxa de juros e, com isso, queremos reduzir o endividamento das pessoas.</div>
            </div>
            <div className="box">
              <div className="icon">
                <img src={iconOurMission2} alt="" />
              </div>
              <div className="title">Dados de inteligência</div>
              <div className="box-desc">Acompanhe o impacto positivo na vida de todos seus colaboradores.</div>
            </div>
            <div className="box">
              <div className="icon">
                <img src={iconOurMission3} alt="" />
              </div>
              <div className="title">Zero custo</div>
              <div className="box-desc">Somos integrados à folha de pagamento e não oferecemos nenhum custo adicional.</div>
            </div>
            <div className="box">
              <div className="icon">
                <img src={iconOurMission4} alt="" />
              </div>
              <div className="title">Saúde Financeira</div>
              <div className="box-desc">Desenvolvemos a educação financeira através de conteúdos e tecnologias exclusivas.</div>
            </div>
          </div>
          <div className="chevron-mobile">
            <img src={iconChevronBlueRight} alt="" />
          </div>
        </div>
      </OurMission>
      <Cards>
        <div className="container">
          <div className="chevron">
            <img src={iconChevronBlueRight} alt="" />
          </div>
          <div className="arrow-top">
            <img src={iconArrowDown} alt="" />
          </div>
        </div>
        <div className="bot">
          <div className="container">
            <div className="left">
              <div className="up-title">FAÇA PARTE DA EQUIPE ALI</div>
              <h3 className="title">Quer trabalhar conosco?</h3>
            </div>
            <div className="right">
              <a href="#contact" className="desc">
                Fazer contato
                <img src={iconChevronRight} alt="" />
              </a>
              <a href="https://ali.gupy.io/" target="_blank" rel="noreferrer" className="btn-default">Trabalhe conosco</a>
            </div>
          </div>
        </div>
      </Cards>
      <SaveMoney>
        <div className="container">
          <div className="cnt-text">
            <div className="up-title">CONTROLE DA SUA VIDA FINANCEIRA</div>
            <h2 className="title">O nosso economizômetro mostra o quanto nossos clientes já economizaram</h2>
            <div className="down-text">Aqui sua dívida é reduzida e você escolhe se quer parcelas menores ou menos parcelas, de qualquer forma, você economiza e tem liberdade econômica com a Ali.</div>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="btn-default">
              Simule no WhatsApp
              <div className="icon">
                <img src={arrowRight} alt="" />
              </div>
            </a>
          </div>
          <div className="img">
            <div className="top">
              <div className="box lax" data-lax-translate-y="(vh) -50, (-elh) 50" data-lax-anchor="self">
                <div className="cnt-box">
                  <img src={iconCar} alt="" />
                  <span>
                    Dá para comprar
                    {' '}
                    { getItemAmountByValue('carro') }
                    {' '}
                    carros
                  </span>
                </div>
              </div>
              <div className="box lax" data-lax-translate-y="(vh) 50, (-elh) -50" data-lax-anchor="self">
                <div className="cnt-box">
                  <img src={iconTv} alt="" />
                  <span>
                    Dá para comprar
                    {' '}
                    { getItemAmountByValue('tv') }
                    {' '}
                    Smart Tvs
                  </span>
                </div>
              </div>
            </div>
            <div className="mid">
              <div className="box lax" data-lax-translate-y="(vh) -20, (-elh) 20" data-lax-anchor="self">
                <div className="top-desc">
                  <div className="text">Já economizamos para os nossos clientes:</div>
                  <div className="dots">
                    <img src={icon3Dots} alt="Icone" />
                  </div>
                </div>
                <div className="box-white">
                  <div className="value" id="value-eco">
                    <AnimatedNumber savings={getEconomia()} />
                  </div>
                </div>
              </div>
            </div>
            <div className="bot">
              <div className="box lax" data-lax-translate-y="(vh) 50, (-elh) -50" data-lax-anchor="self">
                <div className="cnt-box">
                  <img src={iconKey} alt="" />
                  <span>
                    Dá para comprar
                    {' '}
                    { getItemAmountByValue('casa') }
                    {' '}
                    casas
                  </span>
                </div>
              </div>
              <div className="box lax" data-lax-translate-y="(vh) -50, (-elh) 50" data-lax-anchor="self">
                <div className="cnt-box">
                  <img src={iconCell} alt="" />
                  <span>
                    Dá para comprar
                    {' '}
                    { getItemAmountByValue('celular') }
                    {' '}
                    Smartphones
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SaveMoney>
      <BePartner>
        <div className="container" id="contact">
          <div className="cnt-text">
            <h3 className="sup-title">FAÇA A DIFERENÇA NA SUA EMPRESA</h3>
            <h2 className="title">
              Tenha um impacto positivo na vida de todos os seus colaboradores
            </h2>
            <div className="cnt-steps">
              <div className="group">
                <div className="icon">
                  <img src={IconStepCheck} alt="" />
                </div>
                <div className="step">Simplifica a rotina do RH</div>
              </div>
              <div className="group">
                <div className="icon">
                  <img src={IconStepCheck} alt="" />
                </div>
                <div className="step">Plano de saúde financeira</div>
              </div>
              <div className="group">
                <div className="icon">
                  <img src={IconStepCheck} alt="" />
                </div>
                <div className="step">Nenhum custo para a empresa</div>
              </div>
              <div className="group">
                <div className="icon">
                  <img src={IconStepCheck} alt="" />
                </div>
                <div className="step">Dashboards de acompanhamento </div>
              </div>
            </div>
          </div>
          <div className="cnt-form">
            <div className="bg-box-form">
              <div className="box-form">
                <div className="top-info">
                  <div className="box-title">Seja  parceira da Ali</div>
                  <div className="dots">
                    <img src={IconDots} alt="" />
                  </div>
                </div>
                <div id="my-hubspot-form" />
              </div>
            </div>
          </div>
        </div>
      </BePartner>
    </CntAboutUs>
  );
};

export default AboutUs;
