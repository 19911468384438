import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import HamburgerMenu from 'react-hamburger-menu';

import TopAd from '../ad/TopAd';
import WarningAd from '../ad/WarningAd';
import { CntHeader, CntMenuMobile } from './styles';
import logo from '../../images/logo_ali.svg';
import LoginIcon from '../../images/icon_login.svg';
import iconChevron from '../../images/icon_right_chevron.svg';

const Header = () => {
  const [showAd] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    let offSet = 0;
    window.onscroll = () => {
      if (offSet > window.pageYOffset) {
        document.querySelector('#header').classList.remove('hide');
      } else {
        document.querySelector('#header').classList.add('hide');
      }
      offSet = window.pageYOffset;
    };
  }, []);
  React.useEffect(() => {
    if (location.pathname === '/blog') {
      document.querySelector('#footer').classList.add('hide');
    }
  }, [location]);
  const handleClick = () => {
    if (!menuOpen) {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('.js-menu-mobile').classList.add('active');
    } else {
      document.querySelector('html').style.overflow = 'initial';
      document.querySelector('.js-menu-mobile').classList.remove('active');
    }
    setMenuOpen(!menuOpen);
  };
  const closeMobileMenu = () => {
    setMenuOpen(false);
    document.querySelector('html').style.overflow = 'initial';
    document.querySelector('.js-menu-mobile').classList.remove('active');
  };

  return (
    <>
      {showAd && <TopAd />}
      <WarningAd />
      <CntHeader id="header">
        <div className="container">
          <Link to="/" onClick={closeMobileMenu} className="logo">
            <img src={logo} alt="" />
          </Link>
          <div className="links">
            <NavLink
              to="/para-empresas"
              activeClassName="is-active"
              className="link"
            >
              Para empresas
            </NavLink>
            <NavLink
              to="/para-colaboradores"
              activeClassName="is-active"
              className="link"
            >
              Para colaboradores
            </NavLink>
            <NavLink
              to="/sobre-nos"
              activeClassName="is-active"
              className="link"
            >
              Sobre nós
            </NavLink>
            <NavLink
              to="/aliverso"
              activeClassName="is-active"
              className="link"
            >
              Aliverso
            </NavLink>
            <NavLink to="/blog" activeClassName="is-active" className="link">
              Blog
            </NavLink>
            <NavLink
              to="/duvidas-frequentes"
              activeClassName="is-active"
              className="link"
            >
              Dúvidas frequentes
            </NavLink>
          </div>
          <div className="btns">
            <a
              href={`${process.env.REACT_APP_WEBAPP_URL}`}
              target="_blank"
              rel="noreferrer"
              className="login"
            >
              <div className="icon">
                <img src={LoginIcon} alt="" />
              </div>
              <span>Fazer login</span>
            </a>
            <a
              href={`${process.env.REACT_APP_WEBAPP_URL}`}
              target="_blank"
              rel="noreferrer"
              className="account"
            >
              Criar conta
            </a>
            <div className="mobile">
              <HamburgerMenu
                isOpen={menuOpen}
                menuClicked={handleClick}
                width={24}
                height={16}
                strokeWidth={2}
                rotate={0}
                color="#FE4059"
                borderRadius={0}
                animationDuration={0.5}
              />
            </div>
          </div>
        </div>
      </CntHeader>
      <CntMenuMobile className="js-menu-mobile" onClick={handleClick}>
        <div className="container">
          <div className="links">
            <Link to="/para-empresas">
              Para empresas
              {' '}
              <img src={iconChevron} alt="" />
            </Link>
            <Link to="/para-colaboradores">
              Para colaboradores
              {' '}
              <img src={iconChevron} alt="" />
            </Link>
            <Link to="/sobre-nos">
              Sobre nós
              {' '}
              <img src={iconChevron} alt="" />
            </Link>
            <Link to="/aliverso">
              Aliverso
              {' '}
              <img src={iconChevron} alt="" />
            </Link>
            <Link to="/blog">
              Blog
              {' '}
              <img src={iconChevron} alt="" />
            </Link>
            <Link to="/duvidas-frequentes">
              Dúvidas frequentes
              {' '}
              <img src={iconChevron} alt="" />
            </Link>
          </div>
          <a
            href={`${process.env.REACT_APP_WEBAPP_URL}`}
            target="_blank"
            rel="noreferrer"
            className="btn"
          >
            Criar conta
          </a>
        </div>
      </CntMenuMobile>
    </>
  );
};

export default Header;
