import styled from 'styled-components';
import bgHero from '../../images/bg_hero_about_us.svg';
import iconHero from '../../images/icon_about_us_hero_persons.svg';
import bgSquareStep1 from '../../images/bg_square_step1.svg';
import bgBox1 from '../../images/icon_bg_box1.svg';
import bgBox1Mob from '../../images/icon_bg_box1_mobile.svg';
import bgBox2 from '../../images/icon_bg_box2.svg';
import bgBox2Mob from '../../images/icon_bg_box2_mobile.svg';
import bgLine from '../../images/icon_line.svg';
import bgLineMob from '../../images/icon_line_mobile.svg';
import BgBoxHero from '../../images/bg_colaboradores_box_hero.svg';
import BgSaveMoney from '../../images/bg_save_money.jpg';
import BoxEffect from '../../images/top_box_effect.svg';
import BoxEffectBePartner from '../../images/bg_form_box.svg';
import BoxEffectMobile from '../../images/top_box_effect_mobile.svg';

import IconChevronLeft from '../../images/icon_chevron_right.svg';
import IconChevronLeftRed from '../../images/icon_chevron_right_red.svg';
import iconHeroCloud1 from '../../images/bg_hero_about_us_cloud1.svg';
import iconHeroCloud2 from '../../images/bg_hero_about_us_cloud2.svg';

export const CntAboutUs = styled.div`
  overflow: hidden;
`;
export const CntHeroAboutUs = styled.section`
  background-color: #f9fbfe;

  background-position: center bottom, center bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, cover;
  padding-top: 7.1rem;
  padding-bottom: 37rem;
  position: relative;
  overflow: hidden;
  .persons,
  .cloud1,
  .cloud2,
  .city {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    pointer-events: none;
  }
  .persons {
    background-image: url(${iconHero});
    background-position: center bottom;
    z-index: 4;
  }
  .city {
    background-image: url(${bgHero});
    background-position: center 70px;
    z-index: 1;
    background-size: cover;
  }
  .cloud1 {
    background-image: url(${iconHeroCloud1});
    background-position: 5% 50%;
    z-index: 2;
  }
  .cloud2 {
    background-image: url(${iconHeroCloud2});
    background-position: 95% 35%;
    z-index: 2;
  }

  .container {
    max-width: 861px;
    z-index: 5;
    position: relative;
    .up-title {
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .title {
      font-size: 4.6rem;
      text-align: center;
      margin-bottom: 2.8rem;
      @media screen and (max-width: 600px) {
        font-size: 3.2rem;
      }
    }

    .cnt-btn {
      display: flex;
      align-items: center;
      max-width: 557px;
      margin: 0 auto;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      .btn-default {
        margin-right: 3.6rem;
        font-size: 1.6rem;
        line-height: 2rem;
        @media screen and (max-width: 768px) {
          margin-right: 0;
          max-width: 100%;
          width: 100%;
          height: 62px;
        }
        img {
          margin-left: 6px;
        }
      }

      .desc {
        max-width: 241px;
        font-family: Space Grotesk;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 150%;
        color: #606f8e;
        text-decoration: underline;
        @media screen and (max-width: 768px) {
          margin-top: 2.9rem;
          text-align: center;
          text-decoration-line: underline;
        }
      }
    }
  }
`;
export const WhatWeDo = styled.section`
  padding-top: 11.9rem;
  padding-bottom: 14.4rem;
  .container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 968px) {
      flex-direction: column;
    }
    @media screen and (max-width: 450px) {
      padding-bottom: 55rem;
    }
    .square {
      width: 62px;
      height: 62px;
      position: absolute;
      bottom: -17.5rem;
      right: 1rem;
      background: #182d67;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      @media screen and (max-width: 968px) {
        display: none;
      }
    }
    .cnt-text {
      max-width: 488px;
      @media screen and (max-width: 968px) {
        max-width: 100%;
        margin: 0 auto;
      }
      .up-title {
        @media screen and (max-width: 968px) {
          text-align: center;
        }
      }

      .title {
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 140%;
        color: #343a40;
        max-width: 385px;
        margin: 1.5rem 0 2.3rem;
        @media screen and (max-width: 968px) {
          text-align: center;
          margin: 1.9rem auto 2.8rem;
        }
      }

      .desc {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 150%;
        color: #606f8e;
        margin-bottom: 4.1rem;
        @media screen and (max-width: 968px) {
          text-align: center;
          max-width: 462px;
          margin-bottom: 5.7rem;
        }
      }

      .cnt-itens {
        @media screen and (max-width: 968px) {
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
          display: grid;
          grid-gap: 1.5rem;
        }
        .line {
          height: 1px;
          width: 279px;
          background: #f0f6ff;
          @media screen and (max-width: 968px) {
            display: none;
          }
        }
        .item {
          display: flex;
          /* border-bottom: 1px solid #f0f6ff; */
          padding-bottom: 20px;
          padding-top: 20px;

          &:last-of-type {
            border-bottom: none;
          }
          &:first-of-type {
            padding-top: 0px;
          }
          @media screen and (max-width: 968px) {
            width: 220px;
            padding-top: 0;

            padding-bottom: 15px;
            border-bottom: 1px solid #f0f6ff;
            &:last-of-type {
              border-bottom: 1px solid #f0f6ff;
            }
          }
          @media screen and (max-width: 450px) {
            margin: 0 auto;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 3.6rem;
            padding-top: 3.6rem;
            &:last-of-type {
              border-bottom: none;
            }
            * {
              text-align: center;
            }
          }
          .icon {
            margin-right: 2.1rem;
          }

          .txt {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 150%;
            color: #0f204d;
            @media screen and (max-width: 968px) {
              font-size: 1.4rem;
            }
            @media screen and (max-width: 450px) {
              font-size: 1.6rem;
            }
          }
        }
      }
    }

    .cnt-imgs {
      margin-top: 6.8rem;
      flex: 1;
      text-align: right;
      img {
        max-width: 100%;
      }
      @media screen and (max-width: 968px) {
        width: 100%;
        text-align: center;
      }
      @media screen and (max-width: 450px) {
        width: 700px;
        position: absolute;
        bottom: 0;
        right: 0;
        text-align: right;
      }
    }
  }
`;
export const HelpingYou = styled.section`
  background: #0f204d;
  padding-top: 10.2rem;
  position: relative;
  overflow: hidden;
  padding-bottom: 57.6rem;

  @media screen and (max-width: 1100px) {
    padding-bottom: 0rem;
  }
  .container {
    @media screen and (max-width: 1100px) {
      padding: 0;
    }
    .cnt-text {
      position: relative;
      z-index: 1;
      @media screen and (max-width: 1100px) {
        padding: 0 15px;
        * {
          text-align: center;
        }
      }
      .up-title {
        color: #48fbff;
      }
      .title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 140%;
        color: #ffffff;
        margin: 1.3rem 0;
        max-width: 494px;
        @media screen and (max-width: 1100px) {
          max-width: 100%;
        }
      }
      .desc {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 150%;
        color: #ffffff;
        opacity: 0.8;
        max-width: 492px;
        @media screen and (max-width: 1100px) {
          max-width: 100%;
        }
      }
    }
    .cnt-flow {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      @media screen and (max-width: 1100px) {
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .step1 {
        position: relative;
        z-index: 1;
        max-width: 520px;
        flex: 1;
        @media screen and (max-width: 1100px) {
          margin-top: 6.5rem;
          max-width: 100%;
          flex: unset;
          width: 100%;
          border-top: 1.15px solid;
          border-bottom: 1.15px solid;
          border-image-slice: 1;
          border-image-source: linear-gradient(
            99.34deg,
            rgba(255, 255, 255, 0.52) -11.1%,
            rgba(48, 103, 249, 0.2) 64.15%,
            rgba(59, 249, 254, 0.35) 99.18%
          );
        }
        .square {
          width: 280px;
          height: 278px;
          position: relative;
          background: #0f204d;
          border-radius: 8.78776px;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          top: 398px;
          @media screen and (max-width: 1100px) {
            position: unset;
          }
          &:before {
            content: ' ';
            background: url(${bgSquareStep1});
            position: absolute;
            right: 0;
            bottom: -381px;
            width: 528px;
            height: 754px;
            @media screen and (max-width: 1100px) {
              display: none;
            }
          }
          .border {
            position: absolute;
            z-index: -1;
            width: calc(100% + 2.3px);
            height: calc(100% + 2.3px);
            background: linear-gradient(
              99.34deg,
              rgba(255, 255, 255, 0.52) -11.1%,
              rgba(48, 103, 249, 0.2) 64.15%,
              rgba(59, 249, 254, 0.35) 99.18%
            );
            border-radius: 8.78776px;
            top: 0;
            left: -1px;
            right: 0;
            bottom: 0;
            margin: auto;
            @media screen and (max-width: 1100px) {
              display: none;
            }
          }
          .text {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 1.4528rem;
            line-height: 150%;
            color: #ffffff;
            margin-top: 3.4rem;
          }
        }
      }
      .step2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 312px;
        border: 1.15px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(
          43.42deg,
          rgba(255, 255, 255, 0) -8.21%,
          rgba(48, 103, 249, 0.26) 42.95%,
          rgba(59, 249, 254, 0.07) 66.77%,
          rgba(59, 249, 254, 0.08) 66.77%
        );
        @media screen and (max-width: 1100px) {
          border: none;
          flex-direction: row;
          max-width: 100%;
          max-height: 100%;
          align-items: center;
          padding: 13rem 0 3.7rem;
          position: relative;
        }
        .line {
          background: url(${bgLine}) no-repeat;
          background-size: contain;
          height: 13px;
          width: 384px;
          margin: -7px 0;
          left: -75px;
          position: relative;
          @media screen and (max-width: 1100px) {
            background: url(${bgLineMob}) no-repeat;
            background-size: contain;
            width: 13px;
            height: 220px;
            position: unset;
            margin: -63px -6px 0 -8px;
          }
          @media screen and (max-width: 375px) {
            width: 10px;
            background-size: cover;
            position: absolute;
            right: 7px;
            left: 0;
            margin: auto;
            top: 0;
            bottom: -25px;
          }
        }
        .box {
          img {
            margin-right: 1.45rem;
          }
          padding-left: 5rem;
          display: flex;
          align-items: center;
          width: 283px;
          height: 214px;
          display: flex;
          align-items: center;
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 500;
          font-size: 1.6344rem;
          line-height: 124.5%;
          color: #ffffff;
          left: -3px;
          position: relative;
          border-radius: 8.78776px;
          @media screen and (max-width: 1100px) {
            padding: 0;
            display: flex;
            flex-direction: column;
            max-width: 177px;
            width: 100%;
            height: 160px;
            align-items: center;
            justify-content: center;
            border-radius: 0;
          }
          &.first {
            background: url(${bgBox1}) no-repeat;
            background-color: #0f204d;
            @media screen and (max-width: 1100px) {
              background: url(${bgBox1Mob}) no-repeat;
            }
            @media screen and (max-width: 375px) {
              background-size: contain;
            }
          }
          &.second {
            background: url(${bgBox2}) no-repeat;
            /* background-color: #0f204d; */
            @media screen and (max-width: 1100px) {
              background: url(${bgBox2Mob}) no-repeat;
            }
            @media screen and (max-width: 375px) {
              background-size: contain;
            }
          }
        }
      }
      .step3 {
        width: 100%;
        max-width: 384px;
        border-right: 1.15px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(
          43.42deg,
          rgba(255, 255, 255, 0) -8.21%,
          rgba(48, 103, 249, 0.26) 42.95%,
          rgba(59, 249, 254, 0.07) 66.77%,
          rgba(59, 249, 254, 0.08) 66.77%
        );
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* margin-bottom: 120px; */
        margin-right: 2.5rem;

        @media screen and (max-width: 1100px) {
          border: none;
          max-width: 346px;
          margin-right: 0rem;
        }
        .cnt-step3 {
          @media screen and (max-width: 1100px) {
            display: flex;
            flex-direction: column-reverse;
          }
           z-index: 1;

          .cnt-boxes {
            position: relative;
            z-index: 1;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));
            grid-gap: 30px;
            @media screen and (max-width: 1100px) {
              grid-template-columns: repeat(auto-fill, minmax(164px, 1fr));
              grid-gap: 16px;
            }
            @media screen and (max-width: 400px) {
              grid-template-columns: repeat(auto-fill, minmax(144px, 1fr));
              margin: 1px;
            }
            .box {
              position: relative;
              width: 176px;
              height: 176px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              background: #0f204d;
              border-radius: 8.78776px;
              @media screen and (max-width: 1100px) {
                width: 164px;
                height: 164px;
              }
              @media screen and (max-width: 400px) {
                width: 151px;
                height: 151px;
              }
              .border {
                position: absolute;
                z-index: -1;
                width: calc(100% + 2.3px);
                height: calc(100% + 2.3px);
                background: linear-gradient(
                  99.34deg,
                  rgba(255, 255, 255, 0.52) -11.1%,
                  rgba(48, 103, 249, 0.2) 64.15%,
                  rgba(59, 249, 254, 0.35) 99.18%
                );
                border-radius: 8.78776px;
                top: 0;
                left: -1px;
                right: 0;
                bottom: 0;
                margin: auto;
              }
              .icon {
                text-align: center;
                img {
                }
              }

              .box-text {
                font-family: Inter;
                font-style: normal;
                font-weight: bold;
                font-size: 1.6rem;
                line-height: 150%;
                text-align: center;
                color: #ffffff;
                margin-top: 0.8rem;
                max-width: 98px;
              }
              &:last-of-type {
                .box-text {
                  max-width: 119px;
                }
              }
            }
          }
          .btn-default {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 150%;
            color: #ffffff;
            height: 54px;
            max-width: 100%;
            width: 100%;
            margin-bottom: 6.5rem;
            @media screen and (max-width: 1100px) {
              margin-top: 10.8rem;
            }
            @media screen and (max-width: 400px) {
              font-size: 1.4rem;
              width: 95%;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }
  }
  .bot {
    margin-top: 57.6rem;
    background: #0f204d;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 1100px) {
      margin-top: 0rem;
    }
    .line {
      background: linear-gradient(
        99.34deg,
        rgba(255, 255, 255, 0.52) -11.1%,
        rgba(48, 103, 249, 0.2) 64.15%,
        rgba(59, 249, 254, 0.35) 99.18%
      );
      height: 1px;
      width: 100%;
    }
    .container {
      height: 139px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: baseline;
        height: auto;
      }
      .col {
        flex: 1;
        display: flex;
        @media screen and (max-width: 800px) {
          align-items: center;
          justify-content: space-between;
          min-height: 112px;
          flex: unset;
          width: 100%;
          border-bottom: 1.15px solid;
          border-image-slice: 1;
          border-image-source: linear-gradient(
            99.34deg,
            rgba(255, 255, 255, 0.52) -11.1%,
            rgba(48, 103, 249, 0.2) 64.15%,
            rgba(59, 249, 254, 0.35) 99.18%
          );
        }
        .cnt-text {
          @media screen and (max-width: 1100px) {
            * {
              text-align: left;
            }
          }
          .top-text {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 150%;
            color: #ffffff;
            margin-bottom: 0.6rem;
            @media screen and (max-width: 600px) {
              font-size: 1.4rem;
            }
          }

          .bot-text {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 140%;
            text-decoration-line: underline;
            color: #d0ddf7;
            @media screen and (max-width: 1100px) {
              display: none;
            }
          }
        }
        .icon {
          margin-left: 5rem;
          @media screen and (max-width: 1100px) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
`;
export const TeamAli = styled.section`
  padding-top: 17rem;
  .container {
    .top {
      .infos {
        margin-bottom: 5.7rem;
        .sub-title {
          font-family: 'Inter';
          font-style: normal;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 140%;
          letter-spacing: 0.1em;
          color: #6690f8;
          @media screen and (max-width: 900px) {
            text-align: center;
          }
        }
        .title {
          font-family: Space Grotesk;
          font-style: normal;
          font-weight: 500;
          font-size: 3.2rem;
          line-height: 140%;
          color: #0f204d;
          @media screen and (max-width: 900px) {
            text-align: center;
          }
        }
      }
    }
  }
  .swiper {
    position: relative;
    .swiper-slide {
      max-width: 280px;
    }
    .navigation {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      display: none;
      @media screen and (max-width: 900px) {
        top: unset;
        bottom: -150px;
        left: 0;
        right: 0;
        margin: auto;
        width: 190px;
      }
    }
    .swiper-paginator-cards {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 1.2rem;
      line-height: 130%;
      letter-spacing: 0.3em;
      color: #0f204d;
      top: -85px;
      position: absolute;
      z-index: 2;
      right: 120px;
      left: auto;
      width: fit-content;
      height: fit-content;
      text-transform: uppercase;
    }
    .swiper-button-prev-cards,
    .swiper-button-next-cards {
      width: 42px;
      height: 42px;
      border: 1px solid #fe4059;
      background: #fe4059;
      border-radius: 50%;
      top: -99px;
      position: absolute;
      right: 0;
      cursor: pointer;
      &:after {
        content: ' ';
        width: 9px;
        height: 15px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .swiper-button-prev-cards {
      right: 55px;
      left: auto;
      &:after {
        background: url(${IconChevronLeft}) no-repeat center center;
        transform: rotate(180deg);
      }
    }
    .swiper-button-next-cards {
      right: 0;
      &:after {
        background: url(${IconChevronLeft}) no-repeat center center;
      }
    }
    .swiper-button-next-cards.swiper-button-disabled,
    .swiper-button-prev-cards.swiper-button-disabled {
      background: transparent;
      cursor: initial;
      &:after {
        background: transparent;
      }
    }
    .swiper-button-next-cards.swiper-button-disabled {
      background: url(${IconChevronLeftRed}) no-repeat center center;
      transform: rotate(180deg);
    }
    .swiper-button-prev-cards.swiper-button-disabled {
      background: url(${IconChevronLeftRed}) no-repeat center center;
    }
    .box {
      max-width: 280px;
      background: #ffffff;
      /* border-radius: 8.04494px; */
      display: block;
      overflow: hidden;
      height: 340px;
      img {
        max-width: 100%;
        max-height: 100%;
        transition: all 0.2s ease-in-out;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
      &:hover {
        img {
          transform: scale(1.05) translateY(6px);
        }
      }
    }
  }
`;
export const OurMission = styled.section`
  background: #f5f8fd;
  padding-top: 32.9rem;
  margin-top: -17rem;
  padding-bottom: 12.9rem;
  @media screen and (max-width: 600px) {
    padding-bottom: 9rem;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 1005px) {
      flex-direction: column;
    }
    .left {
      max-width: 510px;
      flex: 1;
      margin-right: 2.5rem;
      @media screen and (max-width: 1005px) {
        margin: 0 auto;
        * {
          text-align: center;
        }
      }
      .up-title {
        margin-bottom: 2rem;
        img {
          margin-left: 4.2rem;
          @media screen and (max-width: 1005px) {
            display: none;
          }
        }
      }
      .title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 140%;
        color: #0f204d;
        margin-bottom: 2.4rem;
        @media screen and (max-width: 600px) {
          font-size: 2.2rem;
        }
      }
      .desc {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 150%;
        color: #606f8e;
        max-width: 381px;
        margin-bottom: 5.4rem;
        @media screen and (max-width: 1005px) {
          max-width: 100%;
        }
        @media screen and (max-width: 600px) {
          font-size: 1.4rem;
        }
      }

      .icon-ali {
        width: 72px;
        height: 72px;
        background: #ffffff;
        border-radius: 8px;

        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 1005px) {
          display: none;
        }
      }
    }

    .cnt-boxes {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      grid-gap: 30px;
      max-width: 592px;
      @media screen and (max-width: 1005px) {
        margin: 0 auto;
        display: flex;
        max-width: 100%;
        overflow: scroll;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
      .box {
        width: 280px;
        border-bottom: 1px dashed #c0cdec;
        @media screen and (max-width: 1005px) {
          border: none;
          min-width: 320px;
          width: 100%;
          * {
            text-align: center;
          }
        }
        :nth-last-child(-n + 2) {
          border: none;
        }
        .icon {
          margin-bottom: 1.7rem;
        }
        .title {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 150%;
          color: #0f204d;
          margin-bottom: 1rem;
        }

        .box-desc {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 150%;
          color: #606f8e;
        }
      }
    }
    .chevron-mobile {
      display: none;
      @media screen and (max-width: 1005px) {
        display: block;
        margin: 40px auto 0;
      }
    }
  }
`;
export const Cards = styled.section`
  background: #f5f8fd;
  .container {
    .chevron {
      width: 100%;
      text-align: center;
      @media screen and (max-width: 1005px) {
        display: none;
      }
      img {
        transform: rotate(90deg);
      }
    }
    .arrow-top {
      text-align: right;
      margin-right: 14rem;
      margin-bottom: -2rem;
      @media screen and (max-width: 832px) {
        display: none;
      }
    }
  }
  .bot {
    background: white;

    .container {
      min-height: 337px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      @media screen and (max-width: 875px) {
        justify-content: center;
        flex-direction: column;
        padding-top: 8.3rem;
        * {
          text-align: center;
        }
      }
      .left {
        .up-title {
          margin-bottom: 1.4rem;
        }

        .title {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 500;
          font-size: 3.2rem;
          line-height: 140%;
          color: #0f204d;
        }
      }

      .right {
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 875px) {
          max-width: 462px;
          flex-direction: column;
          width: 100%;
          text-align: center;
        }
        .desc {
          display: flex;
          align-items: center;
          @media screen and (max-width: 875px) {
            order: 2;
            justify-content: center;
            margin-top: 4.2rem;
            margin-bottom: 7.6rem;
          }
          @media screen and (max-width: 600px) {
            margin: 4rem auto 8.2rem;
          }
          img {
            margin-left: 1rem;
          }
          margin-right: 5.3rem;
        }

        .btn-default {
          @media screen and (max-width: 875px) {
            margin-top: 3rem;
            width: 100%;
            max-width: 462px;
            height: 62px;
          }
          @media screen and (max-width: 600px) {
            justify-content: center;
            width: 297px;
            height: 48px;
            background: rgba(255, 213, 220, 0.5);
            font-size: 1.4rem;
            color: #fe4059;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
`;
export const SaveMoney = styled.section`
  background: url(${BgSaveMoney});
  background-size: cover;
  background-attachment: fixed;
  padding-top: 8.9rem;
  padding-bottom: 10rem;
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 1005px) {
      flex-direction: column;
    }
    .cnt-text {
      max-width: 488px;
      flex: 1;
      @media screen and (max-width: 1005px) {
        margin: 0 auto;
        max-width: 671px;
        * {
          text-align: center;
        }
      }
      .up-title {
        color: #48fbff;
      }
      .title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 130%;
        color: #ffffff;
        margin: 1.8rem 0 3.4rem;
        @media screen and (max-width: 600px) {
          font-size: 2.2rem;
        }
      }

      .down-text {
        margin-bottom: 6.8rem;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 150%;
        color: #ffffff;
        opacity: 0.8;
        @media screen and (max-width: 600px) {
          font-size: 1.4rem;
        }
      }

      .btn-default {
        width: 280px;
        height: 60.2px;
        font-size: 1.6rem;
        line-height: 2rem;
        text-align: center;
        @media screen and (max-width: 1005px) {
          width: 100%;
          max-width: 463px;
          margin: 0 auto;
        }
        @media screen and (max-width: 600px) {
          display: none;
        }
        .icon {
          /* border: 1px solid rgba(255, 255, 255, 0.3); */
          border-radius: 50%;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0.9rem;
          img {
            margin: 0;
          }
        }
      }

      .small-text {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 124.5%;
        color: #d0ddf7;
        opacity: 0.7;
        max-width: 21.3rem;
        margin-top: 3.5rem;
        @media screen and (max-width: 1005px) {
          display: none;
        }
      }
    }

    .img {
      flex: 1;
      .top {
        .box {
          &:first-child {
            bottom: 6.5rem;
          }
        }
      }
      .bot {
        margin-top: 6rem;
        .box {
          &:nth-child(2n) {
            top: 6.5rem;
          }
        }
      }
      .top,
      .bot {
        display: flex;
        @media screen and (max-width: 1005px) {
          display: none;
        }
        .box {
          position: relative;
          /* width: 286.24px; */
          height: 70.4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          width: max-content;
          margin: 25px;
          &.bot {
            bottom: 115px;
            right: -165px;
          }
          &.top {
            top: 140px;
            left: 140px;
          }
          .cnt-box {
            background: #ffffff;
            backdrop-filter: blur(50.5848px);
            width: 100%;
            padding: 0 15px;
            height: 51.95px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            /* justify-content: center; */
          }
          span {
            z-index: 1;
            position: relative;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: bold;
            font-size: 1.3974rem;
            line-height: 124.5%;
            color: #606f8e;
          }
          &:before {
            background: url(${BgBoxHero}) no-repeat;
            background-size: cover;
            content: ' ';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
          img {
            margin-right: 8px;
          }
        }
      }
      .mid {
        padding-right: 55px;
        @media screen and (max-width: 1005px) {
          padding: 0;
          margin-top: 15.1rem;
        }
        @media screen and (max-width: 600px) {
          margin-top: 6.6rem;
        }
        @media screen and (max-width: 355px) {
          max-width: 280px;
          margin: 6.6rem auto 0;
        }
        .box {
          margin-left: auto;
          background: linear-gradient(
            118.34deg,
            rgba(255, 229, 233, 0) -11.88%,
            rgba(137, 191, 215, 0.807384) 59.56%,
            #acbeff 155.35%
          );
          backdrop-filter: blur(30px);
          border-radius: 8px;
          max-width: 493px;
          min-height: 197px;
          padding: 1rem;
          display: flex;
          flex-direction: column;
          position: relative;
          @media screen and (max-width: 1080px) {
            max-width: 400px;
          }
          @media screen and (max-width: 1023px) {
            margin: 35px auto;
            .box-white {
              padding: 0 2.5rem;
            }
          }
          @media screen and (max-width: 1005px) {
            margin: 0 auto;
          }
          @media screen and (max-width: 900px) {
            max-width: 450px;
            margin-bottom: -180px;
          }
          @media screen and (max-width: 600px) {
            width: 100%;
            margin-bottom: 0;
          }
          &::after {
            position: absolute;
            content: ' ';
            width: 100%;
            height: 100%;
            background: url(${BoxEffect}) no-repeat center right;
            z-index: -1;
            bottom: -26px;
            right: -26px;
            border-radius: 8px;
            @media screen and (max-width: 900px) {
              background: url(${BoxEffectMobile}) no-repeat center center;
              bottom: -39px;
              background-size: contain;
              right: 0px;
            }
          }

          .top-desc {
            margin-bottom: 1.1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .text {
              margin-left: 1.9rem;
              font-family: 'Space Grotesk';
              font-style: normal;
              font-weight: 500;
              font-size: 1.4rem;
              line-height: 150%;
              color: #ffffff;
            }

            .dots {
              margin-right: 1.1rem;
              @media screen and (max-width: 600px) {
                display: none;
              }
            }
          }

          .box-white {
            background: #ffffff;
            backdrop-filter: blur(100px);
            border-radius: 8px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 25px;
            .value {
              font-family: 'Space Grotesk';
              font-style: normal;
              font-weight: 600;
              font-size: 4.6rem;
              line-height: 130%;
              color: #021c47;
              display: flex;
              @media screen and (max-width: 500px) {
                font-size: 3.2rem;
              }
              @media screen and (max-width: 355px) {
                font-size: 2.6rem;
              }
            }
          }
        }
      }
    }
  }
`;
export const Evolution = styled.section`
  /* overflow: hidden; */
  .container {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;

    .cnt-text {
      max-width: 600px;
      margin-top: 17rem;
      margin-bottom: 11.7rem;
      @media screen and (max-width: 1005px) {
        display: flex;
        max-width: 100%;
        margin: 4.7rem auto;
        flex-wrap: wrap;
        justify-content: center;
        .cnt-titles {
          max-width: 345px;
          margin-right: 2.5rem;
          min-width: 280px;
          flex: 1;
          margin-top: 4rem;
        }
      }
      @media screen and (max-width: 1005px) {
        margin: 18.7rem auto 13rem;
      }
      @media screen and (max-width: 600px) {
        margin: 8.7rem auto;
        * {
          text-align: center;
        }
      }
      .title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 130%;
        color: #0f204d;
        margin-bottom: 2.2rem;
        @media screen and (max-width: 1005px) {
          font-size: 1.8rem;
        }
      }
      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 150%;
        color: #0f204d;
        max-width: 500px;
        @media screen and (max-width: 1005px) {
          font-size: 1.4rem;
          color: #606f8e;
        }
      }
      .cnt-itens {
        margin-top: 5.2rem;
        @media screen and (max-width: 1005px) {
          margin-top: 4rem;
          min-width: 280px;
        }

        .item {
          display: flex;
          align-items: center;
          margin-bottom: 3.4rem;
          @media screen and (max-width: 600px) {
            justify-content: center;
          }
          .icon {
            margin-right: 1.8rem;
            display: flex;
          }
          .desc {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 150%;
            color: #0f204d;
          }
        }
      }
    }

    .cnt-img {
      position: relative;
      right: -16.5rem;
      @media screen and (max-width: 1005px) {
        display: none;
      }
      .anchor {
        left: -20px;
        position: absolute;
        bottom: 0;
        top: 0;
        margin: auto;
        display: flex;
        align-items: center;
        height: fit-content;
        width: fit-content;
      }
      .img-full {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .animate {
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 5;
        &.center {
          bottom: 10px;
        }
      }
    }
  }
`;
export const BePartner = styled.section`
  min-height: 814px;
  padding-top: 7.7rem;
  position: relative;
  padding-bottom: 10rem;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    overflow: unset;
    margin-bottom: 500px;
    margin-top: 10rem;
  }
  @media screen and (max-width: 600px) {
    background-size: cover;
    margin-bottom: 350px;
    margin-top: 1rem;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
    .cnt-text {
      max-width: 586px;
      flex: 1;
      margin-right: 2.5rem;
      margin-top: 7rem;
      @media screen and (max-width: 900px) {
        margin: 0 auto;
      }
      .sup-title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 140%;
        letter-spacing: 0.1em;
        color: #3067f9;
        margin-bottom: 2rem;
        @media screen and (max-width: 600px) {
          font-size: 1.2rem;
          text-align: center;
        }
      }

      .title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 600;
        font-size: 6.2rem;
        line-height: 120%;
        color: #0f204d;
        padding-bottom: 8.2rem;
        border-bottom: 1px dashed rgba(15, 32, 77, 0.2);
        @media screen and (max-width: 900px) {
          font-size: 4.2rem;
          text-align: center;
        }
        @media screen and (max-width: 600px) {
          font-size: 2.2rem;
          text-align: center;
        }
      }

      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 150%;
        color: #606f8e;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
        padding-bottom: 5.8rem;
        @media screen and (max-width: 600px) {
          font-size: 1.4rem;
          text-align: center;
        }
      }

      .cnt-steps {
        flex: 1;
        margin-top: 5.8rem;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        display: grid;
        grid-gap: 3.2rem;
        margin-bottom: 3rem;
        @media screen and (max-width: 425px) {
          grid-gap: 3rem;
        }
        .group {
          display: flex;
          align-items: flex-start;
          .icon {
            margin-top: 3px;
            margin-right: 1.8rem;
          }
          .step {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 150%;
            color: #606f8e;
            max-width: 180px;
            @media screen and (max-width: 425px) {
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    .cnt-form {
      max-width: 447px;
      flex: 1;
      @media screen and (max-width: 900px) {
        margin: 5rem auto;
        max-width: 500px;
        width: 100%;
      }
      .bg-box-form {
        background: linear-gradient(
          118.34deg,
          rgba(255, 229, 233, 0) -11.88%,
          rgba(137, 191, 215, 0.807384) 59.56%,
          #acbeff 155.35%
        );
        backdrop-filter: blur(30px);
        border-radius: 8px;
        min-height: 600px;
        padding: 1.6rem 1.4rem;
        display: flex;
        flex-direction: column;
        position: relative;
        &::after {
          position: absolute;
          content: ' ';
          width: 125%;
          height: 125%;
          background: url(${BoxEffectBePartner}) no-repeat center right;
          background-size: revert;
          z-index: -1;
          bottom: -143px;
          left: -59px;
          @media screen and (max-width: 900px) {
            /* background: url(${BoxEffectMobile}) no-repeat center center; */
            /* background-size: contain;
            width: 100%;
            height: 100%;
            bottom: -251px;
            right: 0px; */
          }
        }
        @media screen and (max-width: 900px) {
          margin-bottom: -500px;
          width: 100%;
        }
        .box-form {
          background: #ffffff;
          border-radius: 8px;
          flex: 1;
          padding: 4.6rem 5.6rem;
          @media screen and (max-width: 400px) {
            padding: 2rem;
          }
          .top-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2.9rem;
            .box-title {
              font-family: Inter;
              font-style: normal;
              font-weight: bold;
              font-size: 1.6rem;
              line-height: 150%;
              color: #0f204d;
            }

            .dots {
              img {
              }
            }
          }

          form {
            .btn-default {
              height: 55px;
              width: 100%;
              max-width: unset;
              margin-top: 5.1rem;
              font-family: 'Space Grotesk';
              font-style: normal;
              font-weight: bold;
              font-size: 1.6rem;
              line-height: 130%;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .icon-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    position: absolute;
    bottom: 3.8rem;
    @media screen and (max-width: 900px) {
      display: none;
    }
    .mouse {
      margin-bottom: 1rem;
    }
  }
`;
