import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding: 2rem;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  background-color: white;
  z-index: 3;
  position: relative;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

export const SecondFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 2rem;
  align-items: center;
  background-color: white;
  z-index: 3;
  position: relative;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;
