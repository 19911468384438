import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding: 1.5rem;
  border-radius: 15px;
  z-index: 2;

  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem;
  }
`;

export const Button = styled.button`
  display: flex;
  width: 100%;
  padding: 1.5rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #0F62FE;
  outline: none;
  border-radius: 50px;
  color: white;
  font-weight: 400;
  font-size: 18px;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: #cccccc;
    pointer-events: none;
  }
`;
