/* eslint-disable react/no-danger */
import React from 'react';
import LpFooter from 'components/lp-footer';
import { AddToHomeScreen, AttachMoney, CheckCircleOutline } from '@material-ui/icons';
import { Helmet } from 'react-helmet-async';
import SignupForm from 'components/signup-form';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import EconomizometroMockup from '../../images/economizometro_mockup.png';
import ElipseBackground from '../../images/elipse-bg.svg';
import {
  BackgroundImage,
  WithAli,
  EconomizometroHeader,
  EconomizometroContent,
  Shortcut,
  ShortcutBox,
  MockupBox,
  DoubtsBox,
  Button,
  Header,
  Content,
  Middle,
  InfoMiddle,
  FormBox,
  KnowAli,
  VideoBox,
  StyledVideo,
  When,
  Megaphone,
} from './styles';
import AliLogo from '../../images/Logo-ali-2023.svg';
import AliCalendar from '../../images/calendar.svg';
import AliMegaphone from '../../images/ali-megafone.svg';
import AliLive from '../../images/live_ali.svg';

const LandingPageContent = ({
  logoUrl, videoUrl, pageTitle, pageContent, id, live, liveDate,
}) => {
  const formatText = (text) => {
    const formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    return formattedText.replace(/\n/g, '<br/>');
  };

  return (
    <>
      <Helmet>
        <title>
          Ali |&nbsp;
        </title>
      </Helmet>
      <Header>
        <img src={AliLogo} alt="Ali logo" style={{ height: '40px' }} />
        <img src={logoUrl} alt="Ali logo" style={{ height: '40px' }} />
      </Header>
      <Content>
        <Middle>
          <InfoMiddle>
            {live && (
              <img src={AliLive} alt="Live" style={{ height: '70px' }} />
            )}
            <h1>
              {pageTitle ?? 'Participe do encontro online com a Ali,'}
            </h1>
            <p dangerouslySetInnerHTML={{ __html: formatText(pageContent ?? 'e conheça o novo benefício de crédito para redução de dívidas que seu RH preparou para você.') }} />
            {live && (
              <When>
                <img src={AliCalendar} alt="calendário" style={{ height: '40px' }} />
                <div>
                  <p>Quando?</p>
                  <h2>{format(liveDate, "d 'de' MMMM 'às' HH'h'mm", { locale: ptBR }) ?? 'Em breve'}</h2>
                </div>
              </When>
            )}
          </InfoMiddle>
          <FormBox>
            <SignupForm company={id} origin={live ? 'live' : 'institutional'} />
            {live && (
              <Megaphone>
                <img src={AliMegaphone} alt="megafone" />
              </Megaphone>
            )}
          </FormBox>
        </Middle>
      </Content>
      <KnowAli>
        <h1>Conheça a Ali</h1>
        <div>
          <h2>
            <b>Somos uma empresa que descomplica a vida financeira das pessoas</b>
            {' '}
            , com soluções que cabem no bolso, de verdade.
          </h2>
          <h2>
            Trabalhamos para evitar novas dívidas e resolver a sua saúde
            financeira por meio de crédito sustentável
          </h2>
        </div>
      </KnowAli>
      <VideoBox>
        <StyledVideo>
          <iframe title="Video" src={videoUrl} allow="autoplay;" />
        </StyledVideo>
      </VideoBox>
      <WithAli>
        <h1>Mais parcelas,</h1>
        <h1>ou parcelas menores?</h1>
        <div>
          Com a Ali é assim, você decide como economizar
        </div>
      </WithAli>
      <EconomizometroHeader>
        <div>
          <h1>
            Economize hoje mesmo
          </h1>
          <h1>
            com o
            {' '}
            <b>Economizômetro®</b>
          </h1>
          <h1>da Ali</h1>
        </div>
        <MockupBox>
          <img src={EconomizometroMockup} alt="Economizometro mockup" />
        </MockupBox>
      </EconomizometroHeader>
      <EconomizometroContent>
        <div>
          <h1>Com a nossa</h1>
          <h1><b>própria tecnologia, o Economizômetro®</b></h1>
          <p>
            você troca a dívida cara por outra mais barata e decide se vai pagar
            em parcelas mais leves ou menos parcelas.
            Tudo em poucos cliques. E você ainda sabe exatamente quanto economiza.
          </p>
        </div>
        <ShortcutBox>
          <Shortcut>
            <div>
              <AddToHomeScreen />
              <h2>Atendimento 100% digital</h2>
            </div>
            <p>
              Você pode reduzir suas dívidas falando com a gente no WhatsApp ou no site Ali,
              sem precisar baixar aplicativos.
            </p>
          </Shortcut>
          <Shortcut>
            <div>
              <AttachMoney />
              <h2>Solução para sua dívida</h2>
            </div>
            <p>
              Reduzimos suas dívidas com condições mais justas e
              ainda mostramos o quanto você economiza.
            </p>
          </Shortcut>
          <Shortcut>
            <div>
              <CheckCircleOutline />
              <h2>Praticidade sempre </h2>
            </div>
            <p>
              Decidir como reduzir suas dívidas é simples: você escolherá
              entre menos parcelas ou parcelas mais leves.
            </p>
          </Shortcut>
        </ShortcutBox>
        <BackgroundImage>
          <img src={ElipseBackground} alt="Elipse background" />
        </BackgroundImage>
      </EconomizometroContent>
      <DoubtsBox>
        <h1>Dúvidas?</h1>
        <p>Fale com a gente e confira seu limite.</p>
        <Button href="https://wa.me/551140039023" target="_blank">
          Fale com a gente no WhatsApp
        </Button>
      </DoubtsBox>
      <LpFooter />
    </>
  );
};

LandingPageContent.propTypes = {
  logoUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  pageTitle: PropTypes.string,
  pageContent: PropTypes.string,
  id: PropTypes.number.isRequired,
  live: PropTypes.bool.isRequired,
  liveDate: PropTypes.string,
};

LandingPageContent.defaultProps = {
  logoUrl: '',
  videoUrl: '',
  pageTitle: '',
  pageContent: '',
  liveDate: '',
};

export default LandingPageContent;
