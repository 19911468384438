import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';

import { CntDepostion } from './styles';
import Avatar1 from '../../images/icon_avatar1.svg';
import Avatar2 from '../../images/icon_avatar2.svg';
import Avatar3 from '../../images/icon_avatar3.svg';
import Avatar4 from '../../images/icon_avatar4.svg';
import Avatar5 from '../../images/icon_avatar5.svg';
import Avatar6 from '../../images/icon_avatar6.svg';
import IconQuotes from '../../images/icon_quotes.svg';
import IconStars from '../../images/icon_stars.svg';
import IconGoBottom from '../../images/icon_go_bottom.svg';

SwiperCore.use([Navigation, Pagination]);

const SliderDepositions = () => (
  <CntDepostion>
    <div className="container">
      <div className="top">
        <div className="infos">
          <h3 className="sub-title">DEPOIMENTOS</h3>
          <h2 className="title">Satisfação em 1º lugar</h2>
        </div>
      </div>
      <div className="swiper">
        <Swiper
          spaceBetween={29}
          slidesPerView="auto"
          pagination={{
            el: '.swiper-paginator-depo',
            type: 'fraction',
            renderFraction: (currentClass, totalClass) => (`
            <span class="${currentClass}"></span> 
            de 
            <span class="${totalClass}"></span>`),
          }}
          navigation={{
            nextEl: '.swiper-button-next-depo',
            prevEl: '.swiper-button-prev-depo',
          }}
        >
          <SwiperSlide>
            <a href="/" className="box" data-aos="fade-up">
              <div className="top">
                <div className="avatar">
                  <img src={Avatar1} alt="" />
                </div>
                <div className="user">
                  <div className="name">Fernanda S.</div>
                  <div className="job">Funcionária na EY</div>
                </div>
              </div>
              <div className="mid">
                Eu fiz o empréstimo pelo site e o que mais gostei foi:
                taxa de juros cobrada, rapidez na liberação do valor
                e facilidade de navegação no site.
              </div>
              <div className="bot">
                <div className="stars">
                  <img src={IconStars} alt="" />
                </div>
                <div className="quote">
                  <img src={IconQuotes} alt="" />
                </div>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="/" className="box" data-aos="fade-up" data-aos-delay="100">
              <div className="top">
                <div className="avatar">
                  <img src={Avatar2} alt="" />
                </div>
                <div className="user">
                  <div className="name">Joana D.</div>
                  <div className="job">Funcionária na Deloitte</div>
                </div>
              </div>
              <div className="mid">
                Gostei muito da praticidade, da agilidade e transparência.
                Excelentes profissionais no atendimento.
              </div>
              <div className="bot">
                <div className="stars">
                  <img src={IconStars} alt="" />
                </div>
                <div className="quote">
                  <img src={IconQuotes} alt="" />
                </div>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="/" className="box" data-aos="fade-up" data-aos-delay="200">
              <div className="top">
                <div className="avatar">
                  <img src={Avatar3} alt="" />
                </div>
                <div className="user">
                  <div className="name">Amarildo S</div>
                  <div className="job">Funcionário da Senior Sistemas</div>
                </div>
              </div>
              <div className="mid">
                Gostei muito da Ali, sua equipe é muito boa,
                atendimento super rápido via WhatsApp,
                sem burocracia, sem enrolação e o dinheiro cai na conta rapidinho.
              </div>
              <div className="bot">
                <div className="stars">
                  <img src={IconStars} alt="" />
                </div>
                <div className="quote">
                  <img src={IconQuotes} alt="" />
                </div>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="/" className="box" data-aos="fade-up" data-aos-delay="200">
              <div className="top">
                <div className="avatar">
                  <img src={Avatar4} alt="" />
                </div>
                <div className="user">
                  <div className="name">Emanoel F.</div>
                  <div className="job">Funcionário da Aço Cearense</div>
                </div>
              </div>
              <div className="mid">
                Consegui comprar algo que sempre sonhei em ter graças a ajuda da Ali.
              </div>
              <div className="bot">
                <div className="stars">
                  <img src={IconStars} alt="" />
                </div>
                <div className="quote">
                  <img src={IconQuotes} alt="" />
                </div>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="/" className="box" data-aos="fade-up" data-aos-delay="200">
              <div className="top">
                <div className="avatar">
                  <img src={Avatar5} alt="" />
                </div>
                <div className="user">
                  <div className="name">Eduardo B.</div>
                  <div className="job">Funcionário da EY</div>
                </div>
              </div>
              <div className="mid">
                Gostaria de agradecer a equipe da Ali por serem muito atenciosos.
                Aproveitei o crédito para realizar a quitação das minhas despesas
                pois estavam sobrecarregando meu orçamento e me ajudou muito, no momento certo!
              </div>
              <div className="bot">
                <div className="stars">
                  <img src={IconStars} alt="" />
                </div>
                <div className="quote">
                  <img src={IconQuotes} alt="" />
                </div>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="/" className="box" data-aos="fade-up" data-aos-delay="200">
              <div className="top">
                <div className="avatar">
                  <img src={Avatar6} alt="" />
                </div>
                <div className="user">
                  <div className="name">Juan M.</div>
                  <div className="job">Funcionário na Senior Sistemas</div>
                </div>
              </div>
              <div className="mid">
                A análise de crédito é simples e rápida.
                E acredite, mesmo que você tenha algo em aberto,
                a possibilidade de você ser aprovado(a) é alta.
                A minha experiência com a Ali foi nota 10.
              </div>
              <div className="bot">
                <div className="stars">
                  <img src={IconStars} alt="" />
                </div>
                <div className="quote">
                  <img src={IconQuotes} alt="" />
                </div>
              </div>
            </a>
          </SwiperSlide>
        </Swiper>
        <div className="navigation">
          <div className="swiper-paginator-depo" />
          <div className="swiper-button-prev-depo" />
          <div className="swiper-button-next-depo" />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="anchor">
        <img src={IconGoBottom} alt="Icone" />
      </div>
    </div>
  </CntDepostion>
);

export default SliderDepositions;
