import styled from 'styled-components';

export const CntFooter = styled.footer`

  .top {
    border: 1px solid #e4ecfd;

    @media screen and (max-width: 385px) {
      padding: 4rem 0;
    }
    .container {
      min-height: 119px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 800px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      &.hide {
          visibility: hidden;
        }
      .logo {
        display: flex;
        align-items: center;
        .slogan {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 140%;
          letter-spacing: 0.1em;
          color: #606f8e;
          margin-left: 33px;
          @media screen and (max-width: 800px) {
            display: none;
          }
        }
       
      }

      .btns {
        display: flex;
        align-items: center;
        @media screen and (max-width: 800px) {
          flex-direction: row-reverse;
          flex: 1;
          min-width: 280px;
        }
        @media screen and (max-width: 385px) {
          flex-direction: column-reverse;
        }
        .login {
          display: flex;
          align-items: center;
          margin-right: 5.2rem;
          cursor: pointer;
          @media screen and (max-width: 800px) {
            margin-right: 0;
            min-width: 110px;
          }
          .icon {
            display: flex;
            align-items: center;
            margin-right: 10px;
          }
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: #0f204d;
        }
        .account {
          width: 176px;
          height: 40px;
          border: 1px solid #fe4059;
          box-sizing: border-box;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: #0f204d;
          transition: all 0.2s ease-in-out;
          @media screen and (max-width: 800px) {
            margin: 0 5%;
            width: 70%;
          }
          @media screen and (max-width: 385px) {
            margin: 3.4rem 5%;
            width: 90%;
          }
          &:hover {
            background: #fe4059;
            color: #ffffff;
          }
        }
      }
    }
  }
  .mid {
    margin-top: 73px;
    display: flex;
    flex-wrap: wrap;
    /* gap: 20px; */
    justify-content: space-between;
    .anchor {
      text-align: right;
      align-self: flex-end;
      margin-bottom: 2.5rem;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 150%;
      text-decoration-line: underline;
      color: #0f204d;
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
    .links {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 150%;
      color: #0f204d;
      @media screen and (max-width: 500px) {
        margin: 1rem;
      }

      .t-link {
        margin-bottom: 3.2rem;
      }
      .link {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 124.5%;
        color: #606f8e;
        margin-bottom: 1.9rem;
        display: block;
      }
      .social-media {
        display: flex;
        flex-wrap: wrap;
        a {
          margin-right: 36px;
        }
      }
    }
    .cnt-social {
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
  }

  .bot {
    margin-top: 6.2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
    .safe {
      flex: 1;
      @media screen and (max-width: 800px) {
        margin: 0 auto;
      }
      .box {
        width: 184px;
        height: 40px;
        background: #f0f6ff;
        border-radius: 50px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 1.5rem;
        letter-spacing: 0.1em;
        color: #3067f9;

        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 8px;
        }
      }
    }

    .cnt-digital {
      flex: 3;
      .t-digital {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 150%;
        color: #0f204d;
        margin-bottom: 2.1rem;
        margin-top: 8px;
      }
      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 174.5%;
        color: #606f8e;
        margin-bottom: 0.8rem;
        &.small{
          font-size: 1.2rem;

        }
      }
      @media screen and (max-width: 800px) {
        margin: 6.5rem auto 0;
        * {
          text-align: center;
        }
      }
    }
  }
  .cnt-social-mob {
    display: none;
    margin: 8rem auto 6rem;
    width: fit-content;
    &.links {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 150%;
      color: #0f204d;
      .t-link {
        margin-bottom: 3.2rem;
      }
      .link {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 124.5%;
        color: #606f8e;
        margin-bottom: 1.9rem;
        display: block;
      }
      .social-media {
        display: flex;
        flex-wrap: wrap;
        a {
          margin-right: 36px;
        }
        @media screen and (max-width: 800px) {
          justify-content: center;
          a {
            margin: 0 18px;
          }
        }
      }
    }
    @media screen and (max-width: 800px) {
      display: block;
    }
  }
  .copy {
    border-top: 1px solid #e4ecfd;
    margin-top: 69px;
    .container {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 124.5%;
      color: #606f8e;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      min-height: 109px;
      @media screen and (max-width: 800px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding: 3.8rem 1rem 5rem;
        .copyright {
          text-align: center;
          margin-top: 3.3rem;
        }
      }
    }

    .insany-design {
      display: flex;
      align-items: center;
      img {
        margin-left: 17px;
      }
    }
  }
`;
