import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getAppToken } from 'services/auth';
import axios from 'axios';
import LandingPageContent from 'components/content-landing-page';
import BtgLandingPageContent from 'components/btg-content-landing-page';
import { Container } from './styles';

const LandingPageInstitutional = () => {
  const { institutional } = useParams();
  const [pathData, setPathData] = React.useState(null);
  const [institutionalIsValid, setInstitutionalIsValid] = React.useState(null);
  const history = useHistory();

  React.useEffect(() => {
    const checkEndpoint = async () => {
      try {
        const token = await getAppToken('institutional');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}landing-pages/path/${institutional}`, { headers: { Authorization: `Bearer ${token}` } });

        if (response.status === 200) {
          setPathData(response.data);
          setInstitutionalIsValid(true);
        }
      } catch (error) {
        setInstitutionalIsValid(false);
      }
    };

    checkEndpoint();
  }, [institutional]);

  React.useEffect(() => {
    if (institutionalIsValid === false) {
      history.push('/');
    }
  }, [institutionalIsValid, history]);

  if (institutionalIsValid === null) {
    return false;
  }

  if (institutionalIsValid) {
    return (
      <Container>
        {pathData.partner === 'btg' ? (
          <BtgLandingPageContent
            logoUrl={pathData.logoUrl}
            videoUrl={pathData.videoUrl}
            pageTitle={pathData.pageTitle}
            pageContent={pathData.pageContent}
            id={pathData.id}
            live={false}
          />
        ) : (
          <LandingPageContent
            logoUrl={pathData.logoUrl}
            videoUrl={pathData.videoUrl}
            pageTitle={pathData.pageTitle}
            pageContent={pathData.pageContent}
            id={pathData.id}
            live={false}
          />
        )}
      </Container>
    );
  }

  return null;
};

export default LandingPageInstitutional;
