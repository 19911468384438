/* eslint-disable no-underscore-dangle */
import React from 'react';
import SwiperCore from 'swiper/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Rive from 'rive-react';
import { useLax } from 'use-lax';
import { Link } from 'react-router-dom';

import {
  CntColaboradores,
  HeroColaboradores,
  SimulationCnt,
  BigAlly,
  AliFee,
} from './styles';
import SliderDepositions from '../../components/slider-depositions';
import Faq from '../../components/faq';
import SliderCards from '../../components/slider-cards';

import Avatars from '../../images/icon_colaboradores_avatar.svg';
import IconRightRed from '../../images/icon_arrow_rounded.svg';
import IconWhats from '../../images/icon_whats_red.svg';
import { ReactComponent as IconMinus } from '../../images/icon_minus.svg';
import { ReactComponent as IconPlus } from '../../images/icon_plus.svg';
import IconBigCell from '../../images/icon_big_cell.svg';
import IconJet from '../../images/icon_big_jet.svg';
import IconBars from '../../images/icon_big_bars.svg';
import ImgFees from '../../images/img_fees_bars.svg';
import ImgFeesMobile from '../../images/img_fees_bars_mobile.svg';
import AnimationHero from '../../animations/579-1170-alicredito3.riv';
import IconArrowRight from '../../images/icon_arrow_right_white.svg';

SwiperCore.use([]);

const ParaColaboradores = () => {
  useLax();
  const [FAQ] = React.useState([
    {
      title: 'A Ali pede alguma taxa ou valor antecipado?',
      text: 'Não! A Ali não pede nenhuma taxa ou valor antecipado. Caso isso aconteça com você, fique atento, pois você pode estar sendo vítima de uma tentativa de fraude. Nesta situação, não prossiga com a conversa e entre em contato com a nossa equipe de atendimento assim que possível. Também é importante ressaltar que, no crédito consignado da Ali, nenhum valor é cobrado diretamente para você, já que as mensalidades são descontadas diretamente da sua folha de pagamento.',
    },
    {
      title: 'Posso confiar na Ali?',
      text: 'Somos uma fintech de crédito que concorre com os bancos para conseguir oferecer juros realmente justos e com muito menos burocracia! De forma completamente transparente, queremos ajudar a vida financeira das pessoas, de verdade. Além disso, também temos como prioridade oferecer um processo 100% seguro. Um exemplo disso é o nosso protocolo SSL (Secure Socket Layer), que permite a troca de informações entre clientes e os servidores da Ali em total segurança, além de impossibilitar fraudes e outros riscos. Uma maneira de conferir essa proteção (no nosso site e em outros também!) é através do seu endereço, que deve começar com "https://", onde o S significa “secured” (seguro, em inglês).',
    },
    {
      title: 'Preciso enviar muitos documentos?',
      text: 'Não! O envio de documentos é rápido e simples, de verdade. :) Os documentos necessários para fazer o consignado da Ali são RG ou CNH atualizados, um comprovante de endereço que pode ser uma conta de consumo, como água, luz e internet em seu nome ou de parentesco de primeiro grau, como pai, mãe ou filhos, e um comprovante de renda, que pode ser seu holerite. Nesta parte, também vamos precisar saber em qual empresa você trabalha para checar se temos convênio com a instituição. Mas não se preocupe, seu nome não é exposto em nenhum momento. Ah! E também vamos precisar de uma selfie sua, só para ter mais segurança durante o nosso processo.',
    },
    {
      title: 'Para quem é o crédito da Ali?',
      text: 'Ele é para todos os funcionários das empresas parceiras da Ali que estejam trabalhando na instituição e dentro da política de crédito! Sua empresa ainda não tem uma parceria com a gente? É só falar com o RH da sua empresa ou acessar nosso site e clicar em Seja um parceiro Ali! Esse processo é rápido, fácil, gratuito e sigiloso.',
    },
    {
      title: 'Posso contratar mesmo com restrições no CPF?',
      text: 'Sim! Como o pagamento das parcelas sai direto da folha de pagamento, também oferecemos crédito consignado para pessoas negativadas ou com restrições financeiras. A melhor parte é que, ao reduzir suas dívidas, você finalmente pode acabar com as pendências e ter mais liberdade financeira.',
    },
    {
      title: 'Como e quando recebo o dinheiro?',
      text: 'Depois da assinatura do seu contrato, o dinheiro cai na sua conta em até 24 horas! Além disso, o depósito do crédito é feito na conta informada por você durante o pedido de crédito. A única exigência é que ela tenha a mesma titularidade, ou seja, a conta precisa estar no seu nome!',
    },
  ]);
  const [tabOption, setTabOption] = React.useState(0);
  const [valueCash, setValueCash] = React.useState(15000);
  const [qtdInstallmente, setQtdInstallmente] = React.useState(12);
  const [maxValueInput] = React.useState(100000);

  const handleSelectInstallments = (newValue) => {
    setQtdInstallmente(newValue);
  };

  const handleChange = (event, newValue) => {
    setTabOption(newValue);
  };
  const handleSliderChange = (event, newValue) => {
    setValueCash(newValue);
  };

  const handleInputChange = (event) => {
    setValueCash(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleBlur = () => {
    if (valueCash < 0) {
      setValueCash(0);
    } else if (valueCash > maxValueInput) {
      setValueCash(maxValueInput);
    }
  };
  // const setBlogFormatted = (data) => {
  //   data.forEach((element) => {
  //     let title = '';
  //     element._embedded['wp:term'][0].forEach((el) => {
  //       if (title) {
  //         title = `${title} | ${el.name}`;
  //       } else {
  //         title = el.name;
  //       }
  //     });
  //     setBlogCards((prevState) => [...prevState, {
  //       section: 'Aprenda tudo sobre educação financeira',
  //       link: element.slug,
  //       urlImg: element.jetpack_featured_media_url,
  //       title,
  //       text: element.title.rendered,
  //     }]);
  //   });
  // };
  // React.useEffect(() => {
  //   AliBlogApi.get('posts?_embed=wp:term&per_page=9&sticky=false').then((response) => {
  //     setBlogCards([]);
  //     if (response.data.length > 0) {
  //       setBlogFormatted(response.data);
  //     }
  //   });
  //   setTimeout(() => {
  //     setTabOption(0);
  //   }, 1300);
  // }, []);
  return (
    <CntColaboradores>
      <HeroColaboradores>
        <div className="container">
          <div className="left">
            <div className="up-title">AS MELHORES TAXAS DE JUROS</div>
            <h1 className="title">Você no controle do seu dinheiro</h1>
            <div className="cnt-btn">
              <a href={`${process.env.REACT_APP_WEBAPP_URL}`} target="_blank" rel="noreferrer" className="btn-default">
                Simule aqui
                <img src={IconArrowRight} alt="" />
              </a>
              <Link to="/para-empresas" className="desc">Ofereça para os colaboradores da sua empresa</Link>
            </div>
            <div className="avatars">
              <img src={Avatars} alt="" />
            </div>
            <Link to="/blog" className="bottom-info">
              <div className="texts">
                <div className="highlight">Sua independência financeira pode começar agora</div>
                <div className="text">Aprenda como no Blog da Ali</div>
              </div>
              <div className="icon">
                <img src={IconRightRed} alt="" />
              </div>
            </Link>
          </div>
          <div className="right">
            <Rive src={AnimationHero} />
          </div>
        </div>
      </HeroColaboradores>
      <SimulationCnt>
        <div className="container">
          <div className="up-title">LEVA MENOS DE 2 MINUTOS</div>
          <div className="row">
            <h2 className="title">Simule o seu crédito agora</h2>
            <a href={`${process.env.REACT_APP_DUVIDAS_URL}`} target="_blank" rel="noreferrer" className="help">
              <div className="icon">
                <img src={IconWhats} alt="" />
              </div>
              <span>Olá, precisa de ajuda?</span>
            </a>
          </div>
          <div className="box-steps">
            <AppBar position="static">
              <Tabs value={tabOption} onChange={handleChange} aria-label="icon simple tabs example">
                <Tab label="ESCOLHA O VALOR" icon={<div className="tab-icon">1º</div>} />
                {/* <Tab label="FAÇA A SUA CONTA" icon={<div className="tab-icon">2º</div>} />
                <Tab label="INDICAR A EMPRESA" icon={<div className="tab-icon">3º</div>} /> */}
              </Tabs>
            </AppBar>
            <div
              role="tabpanel"
              hidden={tabOption !== 0}
              id={`simple-tabpanel-${0}`}
              aria-labelledby={`simple-tab-${0}`}
              className="tabpanel"
            >
              {tabOption === 0 && (
                <div className="box-money-needed">
                  <h3 className="box-title">Quanto de dinheiro você precisa?</h3>
                  <div className="cnt-value">
                    <div className="minus" role="none" onClick={(e) => handleSliderChange(e, valueCash - 100)}>
                      <IconMinus />
                    </div>
                    <div className="value">
                      <Input
                        className="custom-input"
                        value={valueCash}
                        margin="dense"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        inputProps={{
                          step: 100,
                          min: 0,
                          max: 100000,
                          type: 'number',
                          'aria-labelledby': 'input-slider',
                        }}
                      />
                    </div>
                    <div className="plus" role="none" onClick={(e) => handleSliderChange(e, valueCash + 100)}>
                      <IconPlus />
                    </div>
                  </div>
                  <div className="slider">
                    <Slider
                      value={typeof valueCash === 'number' ? valueCash : 0}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                      defaultValue={15000}
                      min={0}
                      max={maxValueInput}
                    />
                  </div>
                  <div className="installments-title">Em quantas vezes?</div>
                  <div className="installments">
                    <div className="box-installments">
                      <div className={qtdInstallmente === 6 ? 'box active' : 'box'} role="none" onClick={() => handleSelectInstallments(6)}>
                        6x
                      </div>
                      <div className={qtdInstallmente === 12 ? 'box active' : 'box'} role="none" onClick={() => handleSelectInstallments(12)}>
                        12x
                      </div>
                      <div className={qtdInstallmente === 18 ? 'box active' : 'box'} role="none" onClick={() => handleSelectInstallments(18)}>
                        18x
                      </div>
                      <div className={qtdInstallmente === 24 ? 'box active' : 'box'} role="none" onClick={() => handleSelectInstallments(24)}>
                        24x
                      </div>
                    </div>
                  </div>
                  <a href={`${process.env.REACT_APP_WEBAPP_URL}`} className="btn-default">Próximo passo &gt;</a>
                  <div className="warning">* Esta simulação é apenas uma estimativa e está sujeita a análise de crédito e é baseada na média do prazo de vencimento das parcelas da Nota Fiscal.</div>
                </div>
              )}
            </div>
            {/* <div
              role="tabpanel"
              hidden={tabOption !== 1}
              id={`simple-tabpanel-${1}`}
              aria-labelledby={`simple-tab-${1}`}
              className="tabpanel"
            >
              {tabOption === 1 && (
                <div className="cnt-tabs">
                  Faça a sua conta
                </div>
              )}
            </div> */}
          </div>
        </div>
      </SimulationCnt>
      <BigAlly>
        <div className="container">
          <div className="col" data-aos="fade-right">
            <div className="up-title">SUA MAIOR ALIADA FINANCEIRA</div>
            <h2 className="title">
              Diga adeus aos juros abusivos e reduza suas dívidas com a Ali
            </h2>
            <div className="text">Conquistar bem-estar nas finanças não é só a ausência de dívidas, é sobre ter poder de escolha sob seu próprio dinheiro. Seja para fazer um investimento para o futuro, uma compra importante no presente, ou reduzir aquela dívida do passado.</div>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="btn-default">
              Simule no WhatsApp
              <img src={IconArrowRight} alt="" />
            </a>
          </div>
          <div className="cellphone">
            <img src={IconBigCell} alt="" />
          </div>
          <div className="col items" data-aos="fade-left">
            <div className="item">
              <div className="icon">
                <img src={IconJet} alt="" />
              </div>
              <h3 className="item-title">+20 milhões</h3>
              <div className="text">Já economizados pelos nossos clientes com a melhor taxa de juros. </div>
            </div>
            <div className="item">
              <div className="icon">
                <img src={IconBars} alt="" />
              </div>
              <h3 className="item-title">2 min</h3>
              <div className="text">Para saber como você pode reduzir as duas dívidas.</div>
            </div>
          </div>
        </div>
      </BigAlly>
      <AliFee>
        <div className="container">
          <div className="up-title" data-aos="fade-up">AQUI A GENTE É 100% JUSTO COM VOCÊ</div>
          <h2 className="title" data-aos="fade-up" data-aos-delay="100">Não é só crédito, é resolver a sua vida de forma rápida, transparente e justa.</h2>
          <div className="cnt-img">
            <img src={ImgFees} className="desk" alt="" />
            <img src={ImgFeesMobile} className="mobile" alt="" />
          </div>
        </div>
      </AliFee>
      <SliderCards className="SliderCards" />
      <SliderDepositions />
      <Faq faqAccordion={FAQ} />
      {/* <Simulation blogCards={BlogCards} /> */}
    </CntColaboradores>
  );
};

export default ParaColaboradores;
