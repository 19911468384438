import styled from 'styled-components';
import IconChevronLeft from '../../images/icon_chevron_right.svg';
import IconChevronLeftRed from '../../images/icon_chevron_right_red.svg';

export const CntSliderCards = styled.section`
  background: linear-gradient(#f5f8fd 78.4%, #ffffff 78.4%);
  padding-top: 12.7rem;
  .container {
    .top {
      .infos {
        .sub-title {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 150%;
          letter-spacing: 0.1em;
          color: #6690f8;
          margin-bottom: 1rem;
          @media screen and (max-width: 900px) {
            text-align: center;
          }
        }

        .title {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 500;
          font-size: 3.2rem;
          line-height: 140%;
          margin-bottom: 5.7rem;
          color: #0f204d;
          @media screen and (max-width: 900px) {
            text-align: center;
          }
        }
      }
    }

    .swiper {
      position: relative;
      .navigation {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        @media screen and (max-width: 900px) {
          top: unset;
          bottom: -150px;
          left: 0;
          right: 0;
          margin: auto;
          width: 190px;
        }
      }
      .swiper-paginator-cards {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 130%;
        letter-spacing: 0.3em;
        color: #0f204d;
        top: -85px;
        position: absolute;
        z-index: 2;
        right: 120px;
        left: auto;
        width: fit-content;
        height: fit-content;
        text-transform: uppercase;
      }
      .swiper-button-prev-cards,
      .swiper-button-next-cards {
        width: 42px;
        height: 42px;
        border: 1px solid #fe4059;
        background: #fe4059;
        border-radius: 50%;
        top: -99px;
        position: absolute;
        right: 0;
        cursor: pointer;
        &:after {
          content: ' ';
          width: 9px;
          height: 15px;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .swiper-button-prev-cards {
        right: 55px;
        left: auto;
        &:after {
          background: url(${IconChevronLeft}) no-repeat center center;
          transform: rotate(180deg);
        }
      }
      .swiper-button-next-cards {
        right: 0;
        &:after {
          background: url(${IconChevronLeft}) no-repeat center center;
        }
      }
      .swiper-button-next-cards.swiper-button-disabled,
      .swiper-button-prev-cards.swiper-button-disabled {
        background: transparent;
        cursor: initial;
        &:after {
          background: transparent;
        }
      }
      .swiper-button-next-cards.swiper-button-disabled {
        background: url(${IconChevronLeftRed}) no-repeat center center;
        transform: rotate(180deg);
      }
      .swiper-button-prev-cards.swiper-button-disabled {
        background: url(${IconChevronLeftRed}) no-repeat center center;
      }
      .swiper-slide {
        max-width: fit-content;
      }
      .box {
        width: 280px;
        background: #ffffff;
        border-radius: 8.04494px;
        display: block;
        overflow: hidden;
        height: 360px;
        @media screen and (max-width: 1080px) {
          height: auto;
        }
        .info {
          padding: 3.2rem 2.6rem 0;
          margin-bottom: 4.8rem;
          min-height: 170px;
          display: flex;
          flex-direction: column;
          .tag {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 1.2rem;
            line-height: 1.5rem;
            letter-spacing: 0.1em;
            color: #3067f9;
            margin-bottom: 0.8rem;

            width: 126px;
            height: 31px;

            background: #f0f6ff;
            backdrop-filter: blur(40px);
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .box-title {
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 600;
            font-size: 2.2rem;
            line-height: 124.5%;
            margin-bottom: 2.8rem;
            color: #0f204d;
          }
          .btn-know-more {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 1.4079rem;
            line-height: 1.7rem;
            color: #fe4059;
            display: flex;
            align-items: center;
            margin-top: auto;
            img {
              margin-left: 1rem;
            }
          }
        }
        &:hover {
          .bottom-icons {
            img {
              transform: scale(1.1) translateY(6px);
            }
          }
        }
        .bottom-icons {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          /* width: 280px; */
          height: 141.97px;
          border-radius: 0px 0px 8px 8px;
          &.mtn {
            img {
              top: -30px;
            }
          }
          &.red {
            background: #fe4059;
            img {
              /* top: -30px; */
              @media screen and (max-width: 1205px) {
                /* top: -20px; */
              }
            }
          }
          &.yel {
            background: #fcfa5a;
            img {
              /* top: -8px; */
              @media screen and (max-width: 1205px) {
                top: 0px;
                bottom: 0;
              }
            }
          }
          &.blu {
            background: #6690f8;
            img {
              /* top: -8px; */
              @media screen and (max-width: 1205px) {
                top: 0px;
                bottom: 0;
              }
            }
          }
          &.gre {
            background: #48fbff;
            img {
              /* top: -8px; */
              @media screen and (max-width: 1205px) {
                top: 0px;
                bottom: 0;
              }
            }
          }
          img {
            position: relative;
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }
  }
`;
