import styled from 'styled-components';
import IconChevronLeft from '../../images/icon_chevron_right.svg';
import IconChevronLeftRed from '../../images/icon_chevron_right_red.svg';

export const CntDepostion = styled.section`
  background: white;
  padding: 17.7rem 0 15rem;
  position: relative;
  .container {
    .anchor {
      position: absolute;
      bottom: -28px;
    }
    .top {
      .infos {
        .sub-title {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 150%;
          letter-spacing: 0.1em;
          color: #6690f8;
          margin-bottom: 1rem;
          @media screen and (max-width: 900px) {
            text-align: center;
          }
        }

        .title {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 500;
          font-size: 3.2rem;
          line-height: 140%;
          margin-bottom: 5.7rem;
          color: #0f204d;
          @media screen and (max-width: 900px) {
            text-align: center;
          }
        }
      }
    }

    .swiper {
      position: relative;
      .navigation {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        @media screen and (max-width: 900px) {
          top: unset;
          bottom: -150px;
          left: 0;
          right: 0;
          margin: auto;
          width: 190px;
        }
      }
      .swiper-slide {
        width: 100%;
        max-width: 385px;
      }
      .swiper-paginator-depo {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 130%;
        letter-spacing: 0.3em;
        color: #0f204d;
        top: -85px;
        position: absolute;
        z-index: 2;
        right: 120px;
        left: auto;
        width: fit-content;
        height: fit-content;
        text-transform: uppercase;
      }
      .swiper-button-prev-depo,
      .swiper-button-next-depo {
        width: 42px;
        height: 42px;
        border: 1px solid #fe4059;
        background: #fe4059;
        border-radius: 50%;
        top: -99px;
        position: absolute;
        right: 0;
        cursor: pointer;
        &:after {
          content: ' ';
          width: 9px;
          height: 15px;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .swiper-button-prev-depo {
        right: 55px;
        left: auto;
        &:after {
          background: url(${IconChevronLeft}) no-repeat center center;
          transform: rotate(180deg);
        }
      }
      .swiper-button-next-depo {
        right: 0;
        &:after {
          background: url(${IconChevronLeft}) no-repeat center center;
        }
      }
      .swiper-button-next-depo.swiper-button-disabled,
      .swiper-button-prev-depo.swiper-button-disabled {
        background: transparent;
        cursor: initial;
        &:after {
          background: transparent;
        }
      }
      .swiper-button-next-depo.swiper-button-disabled {
        background: url(${IconChevronLeftRed}) no-repeat center center;
        transform: rotate(180deg);
      }
      .swiper-button-prev-depo.swiper-button-disabled {
        background: url(${IconChevronLeftRed}) no-repeat center center;
      }
      .box {
        background: #ffffff;
        border: 1px solid #e4ecfd;
        box-sizing: border-box;
        border-radius: 8px;
        min-height: 360px;
        display: flex;
        flex-direction: column;
        padding: 3rem;
        max-width: 385px;
        .top {
          display: flex;
          align-items: center;
          .avatar {
            margin-right: 1.4rem;
          }

          .user {
            font-family: 'Space Grotesk';
            font-style: normal;
            .name {
              font-weight: bold;
              font-size: 1.6rem;
              line-height: 120%;
              color: #495057;
              margin-bottom: 1rem;
            }
            .job {
              font-weight: 500;
              font-size: 1.4rem;
              line-height: 124.5%;
              color: #606f8e;
            }
          }
        }

        .mid {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 150%;
          color: #606f8e;
          margin-top: 1.9rem;
          margin-bottom: auto;
        }

        .bot {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .stars {
            display: flex;
          }

          .quote {
          }
        }
      }
    }
  }
`;
