import styled from 'styled-components';

export const MarqueeCnt = styled.section`
  width: 100%;
  height: 76px;
  overflow: hidden;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: linear-gradient(
      118.34deg,
      rgba(255, 229, 233, 0) -11.88%,
      rgba(205, 240, 255, 0.807384) 59.56%,
      #acbeff 155.35%
    ),
    #ffffff;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 124.5%;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #0f204d;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    99.34deg,
    rgba(255, 255, 255, 0.52) -11.1%,
    rgba(48, 103, 249, 0.2) 64.15%,
    rgba(59, 249, 254, 0.35) 99.18%
  );
  .marquee-container {
    height: 100%;
  }
`;
