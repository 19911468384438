import styled from 'styled-components';
import BgHero from '../../images/bg_hero_colaboradores.jpg';
import BgAlly from '../../images/bg_simulation.jpg';
import BgBoxSimulation from '../../images/bg_box_simulation.svg';
import BgAllyMobile from '../../images/bg_simulation_mobile.jpg';
import BgBoxHero from '../../images/bg_colaboradores_box_hero.svg';

export const CntColaboradores = styled.div`
  overflow: hidden;
  .SliderCards {
    margin-bottom: 10rem;
  }
`;
export const HeroColaboradores = styled.section`
  overflow: hidden;
  .container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
    .left {
      max-width: 647px;
      padding-top: 10.2rem;
      @media screen and (max-width: 1100px) {
        max-width: 50%;
      }
      @media screen and (max-width: 1000px) {
        max-width: 100%;
        padding-top: 7rem;
        * {
          text-align: center;
        }
      }
      .up-title {
        margin-bottom: 0.9rem;
      }

      .title {
        margin-bottom: 3.5rem;
        max-width: 510px;
        @media screen and (max-width: 1000px) {
          max-width: 85%;
          margin: 0 auto 3.5rem;
        }
        @media screen and (max-width: 600px) {
          font-size: 3.6rem;
          max-width: 95%;
        }
      }

      .cnt-btn {
        display: flex;
        align-items: center;
        @media screen and (max-width: 1000px) {
          flex-direction: column;
        }
        .btn-default {
          font-size: 1.6rem;
          height: 60px;
          img {
            margin-left: 2.6rem;
            margin-right: 0;
          }
          @media screen and (max-width: 1000px) {
            width: 275px;
            height: 48px;
            font-size: 1.4rem;
            margin-bottom: 3rem;
          }
        }

        .desc {
          margin-left: 3.6rem;
          max-width: 255px;
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 150%;
          color: #606f8e;
          text-decoration: underline;
          @media screen and (max-width: 1000px) {
            text-decoration-line: underline;
            margin-left: 0;
            max-width: 400px;
            margin-bottom: 8rem;
          }
          @media screen and (max-width: 600px) {
            font-size: 1.4rem;
            margin-bottom: 3rem;
          }
        }
      }

      .avatars {
        margin-top: 3.8rem;
      }

      .bottom-info {
        margin-top: 11.6rem;
        padding: 3.2rem 0;

        width: 100%;
        display: flex;
        @media screen and (max-width: 1000px) {
          justify-content: center;
        }
        @media screen and (max-width: 600px) {
          margin-top: 4rem;
        }
        &::after {
          position: absolute;
          content: ' ';
          width: 200%;
          height: 111px;
          border: 1px solid rgba(96, 111, 142, 0.1);
          border-left: none;
          border-right: none;
          margin: -3.2rem 0;
          left: -100%;
        }
        .texts {
          @media screen and (max-width: 1000px) {
            display: flex;
            align-items: center;
          }
          .highlight {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 150%;
            color: #0f204d;
            margin-bottom: 0.5rem;
            @media screen and (max-width: 1000px) {
              margin: 0;
              text-align: left;
            }
            @media screen and (max-width: 600px) {
              font-size: 1.4rem;
            }
          }

          .text {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 140%;
            color: #606f8e;
            @media screen and (max-width: 1000px) {
              display: none;
            }
          }
        }

        .icon {
          margin-left: 4.7rem;
          @media screen and (max-width: 1000px) {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .right {
      flex: 1;
      /* position: relative; */
      /* &::after { */
      position: absolute;
      /* content: ' '; */
      background: url(${BgHero}) no-repeat right top;

      background-size: cover;
      width: 100%;
      height: 100%;
      right: -109px;
      max-width: 600px;
      overflow: hidden;
      canvas {
        height: 782px !important;
        object-fit: cover;
        top: -80px;
        position: relative;
      }
      > div {
        height: 791px !important;
      }
      @media screen and (max-width: 1130px) {
        right: -130px;
      }
      @media screen and (max-width: 1005px) {
        position: unset;
        right: unset;
        top: unset;
        left: unset;
        bottom: unset;
        height: 633px;
      }
      @media screen and (max-width: 1000px) {
        position: relative;
        margin: 6px auto;
        max-height: 425px;
        height: 425px;
        min-height: 425px;
        overflow: hidden;
        canvas {
          height: 791px !important;
          position: absolute;
          top: -130px;
          object-fit: cover;
        }
      }
      .cnt-right {
        .box {
          position: absolute;
          width: 286.24px;
          height: 70.4px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.bot {
            bottom: 115px;
            right: -165px;
          }
          &.top {
            top: 140px;
            left: 140px;
          }
          .cnt-box {
            background: #ffffff;
            backdrop-filter: blur(50.5848px);
            width: 263.6px;
            height: 51.95px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          span {
            z-index: 1;
            position: relative;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: bold;
            font-size: 1.3974rem;
            line-height: 124.5%;
            color: #606f8e;
          }
          &:before {
            background: url(${BgBoxHero}) no-repeat;
            content: ' ';
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
          img {
            margin-right: 8px;
          }
        }
      }
    }
  }
`;
export const SimulationCnt = styled.section`
  padding-top: 15rem;
  // #region tab styles
  .MuiAppBar-colorPrimary {
    color: #fff;
    background: transparent;
    box-shadow: none;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 124.5%;
    /* opacity: 0.5; */
  }
  .MuiTab-wrapper {
    filter: grayscale(1);
    display: flex;
    flex-direction: row;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 124.5%;
    text-transform: none;
    color: #333f55;
    .tab-icon {
      background: #3067f9;
      width: 32px;
      height: 32px;
      margin-right: 2.2rem;

      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 117.7%;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      margin-right: 2rem;
    }
  }
  .MuiTab-textColorInherit {
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
  }
  .MuiTab-textColorInherit.Mui-selected {
    opacity: 1;
    .MuiTab-wrapper {
      filter: grayscale(0);
    }
  }
  .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
    margin-bottom: 0;
  }
  .MuiTabs-flexContainer {
    border-bottom: 1px solid #c0cdec;
    width: fit-content;
    margin: 0 auto;
  }
  .PrivateTabIndicator-colorSecondary-3 {
    background-color: #fe4059;
  }
  .MuiTab-root {
    padding: 6px 3.4rem;
    max-width: 300px;
  }
  // #endregion
  @media screen and (max-width: 768px) {
    padding-top: 6.4rem;
  }
  .container {
    .up-title {
      margin-bottom: 0.5rem;
      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 3.4rem;
      .title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 140%;
        color: #0f204d;
        @media screen and (max-width: 768px) {
          margin: 0 auto;
        }
      }

      .help {
        display: flex;
        @media screen and (max-width: 768px) {
          display: none;
        }
        .icon {
          margin-right: 1.2rem;
        }

        span {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 150%;
          color: #0f204d;
        }
      }
    }
    .box-steps {
      background: #ffffff;
      border: 1px solid #e4ecfd;
      box-sizing: border-box;
      border-radius: 8px;
      position: relative;
      &:before {
        position: absolute;
        content: ' ';
        width: 95%;
        height: 100%;
        bottom: -20px;
        right: 0;
        left: 0;
        margin: auto;
        background: url(${BgBoxSimulation});
        border-radius: 8px;
        background-size: cover;
      }
      header {
        position: relative;
        z-index: 1;
        background: white;
        border-radius: 8px;
      }
      .tabpanel {
        position: relative;
        z-index: 1;
        background: white;
        padding-bottom: 6.3rem;
        border-radius: 8px;
        min-height: 523px;
      }
      .box-money-needed {
        padding-top: 6.5rem;
        max-width: 592px;
        margin: 0 auto;
        .box-title {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 600;
          font-size: 2.2rem;
          line-height: 124.5%;
          text-align: center;
          color: #0f204d;
          margin-bottom: 1.7rem;
          @media screen and (max-width: 978px) {
            font-size: 1.6rem;
            max-width: 75%;
            margin: 0 auto 2.4rem;
          }
        }

        .cnt-value {
          display: flex;
          justify-content: center;
          align-items: center;
          .minus,
          .plus {
            width: 38.64px;
            height: 38.64px;
            border-radius: 50%;
            border: 0.919913px solid #e9f0ff;
            margin: 0 3.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            @media screen and (max-width: 978px) {
              margin: 0 2.5rem;
            }
            @media screen and (max-width: 330px) {
              margin: 0 1rem;
            }
            &:hover {
              svg {
                path {
                  fill: #ffffff;
                }
              }
              rect {
                fill: #ffffff;
              }
              background: #fe4059;
            }
          }
          .minus {
          }

          .value {
            text-align: center;
            .custom-input {
              .MuiTypography-root {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 600;
                font-size: 4.6rem;
                line-height: 130%;
                color: #0f204d;
                @media screen and (max-width: 978px) {
                  font-size: 3.2rem;
                }
              }
              input {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 600;
                font-size: 4.6rem;
                line-height: 130%;
                color: #0f204d;
                text-align: center;
                padding: 0;
                border: none;
                /* min-width: 280px;
                position: relative;
                &::after {
                  position: absolute;
                  content: ' ';
                } */
                @media screen and (max-width: 978px) {
                  font-size: 3.2rem;
                }
              }
              &::after,
              &::before {
                content: '';
                display: none;
              }
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
              }

              input[type='number'] {
                -moz-appearance: textfield; /* Firefox */
              }
            }
          }

          .plus {
          }
        }

        .slider {
          .MuiSlider-track {
            background: #fe4059;
            border-radius: 15px;
            height: 6px;
          }
          .MuiSlider-thumb {
            width: 22px;
            height: 22px;
            background: #ffffff;
            border-radius: 12.5px;
            border: 5px solid #fe4059;
            top: 9px;
          }
        }

        .installments-title {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 150%;
          text-align: center;
          color: #adb6c4;
          margin-bottom: 1.25rem;
        }

        .installments {
          .box-installments {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
            .box {
              margin: 5px;
              width: 52.44px;
              height: 52.44px;
              border: 1.0084px solid #ecedf9;
              box-sizing: border-box;
              border-radius: 5.04202px;
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 1.6rem;
              line-height: 150%;
              color: rgba(15, 32, 77, 0.25);

              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 0.2s ease-in-out;
              cursor: pointer;
              &.active {
                color: #fe4059;
                font-weight: bold;
                background: rgba(254, 64, 89, 0.2);
                border: 1.0084px solid #fe4059;
              }
            }
          }
        }

        .btn-default {
          margin: 0 auto;
          width: 242px;
          height: 57px;
          background: #fe4059;
          border-radius: 8px;
        }

        .warning {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 124.5%;
          text-align: center;
          color: #606f8e;
          margin-top: 4.7rem;
        }
      }
    }
  }
`;
export const BigAlly = styled.section`
  background: url(${BgAlly});
  background-size: cover;
  background-attachment: fixed;

  margin-top: -30rem;
  padding-top: 48.1rem;
  @media screen and (max-width: 968px) {
    background: url(${BgAllyMobile});
    background-size: cover;
    background-attachment: fixed;
  }
  .container {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    @media screen and (max-width: 968px) {
      justify-content: center;
      flex-wrap: wrap;
    }
    .col {
      max-width: 312px;
      @media screen and (max-width: 968px) {
        max-width: 400px;
        margin: 0 2rem;
        min-width: 280px;
        flex: 1;
      }
      @media screen and (max-width: 670px) {
        * {
          text-align: center;
        }
      }
      .up-title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 140%;
        letter-spacing: 0.1em;
        color: #48fbff;
        margin-bottom: 2.4rem;
      }

      .title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 600;
        font-size: 2.2rem;
        line-height: 124.5%;
        color: #ffffff;
        margin-bottom: 1.1rem;
      }

      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 150%;
        color: #ffffff;
        opacity: 0.8;
        margin-bottom: 3.4rem;
      }

      .btn-default {
        width: 280px;
        height: 62px;
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 124.5%;
        color: #ffffff;
        margin-bottom: 2.8rem;
        font-size: 1.6rem;
        height: 60px;
        img {
          margin-left: 2.6rem;
          margin-right: 0;
        }
        @media screen and (max-width: 670px) {
          width: 275px;
          margin: 0 auto 2.8rem;
          border: 1px solid #fe4059;
          background: transparent;
          height: 48px;
        }
      }

      .action {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 124.5%;
        color: #ffffff;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media screen and (max-width: 670px) {
          justify-content: center;
          padding-bottom: 7rem;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          margin-bottom: 5.5rem;
        }
        img {
          margin-left: 7px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 1px solid rgba(252, 115, 132, 0.3);
          padding: 9px;
          transform: rotate(-45deg);
        }
      }
    }
    .cellphone {
      margin-bottom: -10.8rem;
      margin-top: -5rem;
      @media screen and (max-width: 968px) {
        display: none;
      }
      @media screen and (max-width: 670px) {
        display: block;
        order: 3;
        height: 340px;
        overflow: hidden;
        margin: 0;
        img {
          max-width: 100%;
        }
      }
    }
    .items {
      .item {
        min-width: 312px;
        border-bottom: 1px dashed #606f8e;
        margin-bottom: 3.9rem;
        @media screen and (max-width: 670px) {
          min-width: 282px;
        }
        .icon {
        }
        .item-title {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 500;
          font-size: 3.2rem;
          line-height: 140%;
          color: #ffffff;
          margin-bottom: 2.2rem;
        }
        .text {
          max-width: 271.3px;
          @media screen and (max-width: 670px) {
            margin: 0 auto 4.6rem;
          }
        }
        &:not(:first-child) {
          border-bottom: none;
        }
      }
    }
  }
`;
export const AliFee = styled.section`
  margin-top: 17.6rem;
  margin-bottom: 18.8rem;
  @media screen and (max-width: 768px) {
    margin: 10rem auto;
  }
  .container {
    .up-title {
      text-align: center;
    }
    .title {
      max-width: 678px;
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;
      font-size: 3.2rem;
      line-height: 124.5%;
      text-align: center;
      color: #0f204d;
      margin: 1.8rem auto 6.6rem;
      @media screen and (max-width: 600px) {
        font-size: 2.2rem;
      }
    }
    .cnt-img {
      .mobile {
        display: none;
        @media screen and (max-width: 600px) {
          display: block;
        }
      }
      .desk {
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
      img {
        max-width: 100%;
      }
    }
  }
`;
