import styled from 'styled-components';
import CallWallpaper from '../../images/wallpaper_call.svg';
import ManWallpeper from '../../images/ManInCellphone.svg';

export const EconomizometroHeader = styled.div`
  width: 100%;
  background-image: url(${ManWallpeper});
  background-size: cover;
  background-position: center;
  min-height: 830px;
  padding: 5rem 4rem;

  > div:first-child {
    background-color: #ffffff;
    width: 100%;
    border-radius: 2px;
    padding: 8rem 4rem;
    margin-left: auto;
    margin-top: 100px;
  }

  h1 {
    color: black;
    font-size: 28px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 18px;
    font-weight: 300;
  }

  a {
    appearance: none;
    background-color: #195AB4;
    border-radius: 5px;
    padding: 2rem;
    color: #ffffff;
    text-decoration: underline;
    outline: none;
    border: none;
    margin-top: 20px;
    position: relative;
    top: 30px;
    font-size: 14px;
    font-weight: 300;
  }

  @media (min-width: 800px) {
    > div:first-child {
      width: 50%;
    }
  }
`;

export const EconomizometroContent = styled.div`
  width: 100%;
  background-color: #F5F5F6;
  padding: 3rem 4rem;
  margin-top: -200px;

  > div {
    border-radius: 20px;
    padding: 5rem 4rem;
  }

  > div:first-child {
    width: 100%;
    z-index: 2;
    position: relative;
  }

  h1 {
    color: black;
    font-size: 32px;
    margin-bottom: 2rem;
  }

  h2 {
    color: black;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 2rem;
  }

  p {
    font-size: 14px;
  }

  a {
    appearance: none;
    background-color: #195AB4;
    border-radius: 5px;
    padding: 2rem;
    color: #ffffff;
    text-decoration: underline;
    outline: none;
    border: none;
    top: 25px;
    position: relative;
    font-size: 14px;
    font-weight: 300;
  }

  @media (min-width: 790px) {
    margin-top: 0;

    > div:first-child {
      width: 50%;
      z-index: 2;
      position: relative;
    }
  }
`;

export const ShortcutBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  @media (min-width: 890px) {
    width: 45%;
  }

  @media (min-width: 980px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const MockupBox = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  margin-top: 100px;
  margin-bottom: 100px;

  img {
    height: 600px;
    display: flex;
    position: relative;
  }

  @media (min-width: 790px) {
    display: flex;
    width: 90%;
    position: absolute;
    margin-top: 100px;

    img {
      height: 600px;
      top: 0px;
      display: flex;
      position: relative;
      right: 0;
      margin-left: auto;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  background-color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;

  > img:nth-child(1) {
    padding-right: 2rem;
    border-right: 1px solid #C0C0C0;
    margin-right: 1rem;
  }

  > div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    border-right: 1px solid #C0C0C0;
    padding-right: 1rem;
    margin-right: 1rem;

    p {
      font-size: 11px;
      text-align: left;
      margin: 0;

      b {
        font-size: 11px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 630px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url(${CallWallpaper});
  background-size: cover;
  background-position: center;
  padding: 0rem 1rem;
  border-bottom: 10px solid #ededed;
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: auto;
  justify-content: space-evenly;
  gap: 2rem;
  align-items: center;
  text-align: center;
  padding: 1rem 2rem 1rem 1rem;;
  z-index: 2;

  @media (min-width: 800px) {
    padding: 2rem 4rem 2rem 1rem;
    flex-direction: row;
    height: 500px;
    gap: 3rem;
  }
`;

export const InfoMiddle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: flex-start;
  text-align: left;
  align-items: flex-start;
  padding: 1rem; 
  width: 100%;

  h1 {
    font-size: 38px;
    color: white;
  }

  h2 {
    font-size: 30px;
    color: white;
  }

  p {
    font-size: 18px;
    color: white;
    margin: 0;

    strong {
      font-size: 24px;
      font-weight: 500;
    }
  }

  @media (min-width: 800px) {
    width: 50%;
    padding: 1rem; 
  }
`;

export const FormBox = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

  @media (min-width: 800px) {
    width: 40%;
    top: 80px;
    position: relative;
  }

  @media (min-width: 1366px) {
    width: 25%;
  }
`;

export const KnowAli = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  padding: 4rem 10rem;
  background-color: white;
  align-items: center;
  text-align: LEFT;

  img {
    border-radius: 5px;
    max-width: 600px;
    width: 100%;
  }

  h1 {
    font-size: 32px;
    color: black;
    text-align: left;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  h2 {
    font-size: 18px;
    color: black;
    font-weight: 400;
    text-align: left;

    b {
      font-size: 18px;
      font-weight: 600;
    }
  }

  p {
    font-size: 14px;
  }

  @media (min-width: 1000px) {
    flex-direction: row;
  }
`;

export const VideoBox = styled.div`
  width: 100%;
  margin: auto;
  background-color: #000;
  overflow: hidden;
  height: 720px;
`;

export const StyledVideo = styled.div`
  width: 100%;
  height: 100%;
  
  iframe {
    width: 100%;
    height: 100%;
  }
`;

export const When = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  z-index: 2;

  h2 {
    font-size: 24px;
  }
`;

export const Carousel = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;
  background-color: #F5F5F6;
  width: 100%;
  padding: 4rem;
  padding-left: 10rem;

  h1 {
    font-size: 24px;
  }

  > div {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    overflow: hidden;
    position: relative;
    width: ${(props) => props.visibleWidth}px;
  }
`;

export const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border: 1px solid #D7DBDF;
  border-radius: 5px;
  min-width: 380px;
  padding: 4rem;
  gap: 1rem;
  box-sizing: border-box;

  > img {
    max-width: 50px;
  }

  h2 {
    font-size: 18px;
  }
`;

export const CarouselButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
`;

export const NavButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2.5rem;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const Dot = styled.span`
  height: 8px;
  width: 8px;
  background-color: ${(props) => (props.active ? '#000' : '#ccc')};
  border-radius: 50%;
  display: inline-block;
`;

export const CarouselInner = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: ${(props) => `translateX(-${props.translate}px)`};
  gap: 2rem;
`;

export const CheckYourLimit = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 4rem;
  padding-left: 10rem;

  > div {
    p {
      font-size: 16px;
    }

    h1 {
      font-size: 28px;
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 24px;
      font-weight: 300;
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

export const Faq = styled.div`
  display: flex;
  background-color: #FFFFFF;
  flex-direction: column;
  width: 100%;
  padding: 4rem;
  padding-left: 10rem;

  h1 {
    font-size: 28px;
    margin-bottom: 1rem;
  }
`;

export const FaqItem = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #f2f2f2;
`;

export const Question = styled.div`
  padding: 1rem;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  font-weight: 500;
  font-size: 18px;
  user-select: none;
`;

export const Answer = styled.div`
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 0.5rem;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  white-space: pre-wrap;
`;

export const Icon = styled.span`
  font-size: 1.5rem;
  transform: ${(props) => (props.isVisible ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;
