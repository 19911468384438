/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper/core';
import Rive from 'rive-react';
import { useLax } from 'use-lax';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

import {
  CntEmpresas,
  HeroEmpresas,
  DashBoards,
  BestOption,
  Benefits,
  HowWorks,
} from './styles';
import AliMarquee from '../../components/marquee';
import SliderDepositions from '../../components/slider-depositions';
import Faq from '../../components/faq';

import IconStepCheck from '../../images/icon_step_check.svg';
import IconMouse from '../../images/icon_mouse.svg';
import IconMouseDown from '../../images/icon_chevron_down_mouse.svg';
import IconDots from '../../images/icon_dots_form.svg';
import AnimationHero from '../../animations/578-1142-alicredito2.riv';
import IconGoBottom from '../../images/icon_go_bottom.svg';
import IconArrowAttention from '../../images/icon_arrow_attention.svg';
import IconTabGlobe from '../../images/icon_tab_globe.svg';
import IconBenefitsArrow from '../../images/icon_benefits_arrow.svg';
import IconBenefits1 from '../../images/icon_benefits1.svg';
import IconBenefits2 from '../../images/icon_benefits2.svg';
import IconBenefits3 from '../../images/icon_benefits3.svg';
import IconBox1 from '../../images/icon_box_empresa1.svg';
import IconBox2 from '../../images/icon_box_empresa2.svg';
import IconBox3 from '../../images/icon_box_empresa3.svg';
import IconBox4 from '../../images/icon_box_empresa4.svg';
import IconBoxArrow from '../../images/icon_box_arrow.svg';
import iconShowMore from '../../images/icon_show_more_slide.svg';

SwiperCore.use([]);

const ParaEmpresas = () => {
  useLax();
  useHubspotForm({
    portalId: '20869742',
    formId: '3b157591-e008-4855-9cd3-ba7e55777eca',
    target: '#my-hubspot-form',
  });
  const [FAQ] = React.useState([
    {
      title: 'O que a Ali gera de vantagem para minha empresa?',
      text: 'Primeiro de tudo, fazemos o salário do seu colaborador valer mais, sem que a sua empresa gaste mais. Fazemos isso através de uma tecnologia proprietária da Ali que evita ou reduz a dívida dos colaboradores. Com isso, a melhora da produtividade e satisfação dos seus colaboradores com a empresa é nítida, além disso, com a ajuda da nossa solução é possível melhorar taxas de turnover e aumentar a retenção da equipe. O melhor de tudo é que não há custo ou fidelidade para a empresa e também elimina todo e qualquer retrabalho que o RH tenha, graças à automatização e integração com os principais sistemas de folha de pagamento.',
    },
    {
      title: 'Como posso habilitar o benefício para os meus colaboradores?',
      text: 'Você pode entrar em contato através do nosso próprio site, clicando em Seja um parceiro da Ali, ou pelo nosso telefone (11) 4003-4458. Ah! E não se preocupe, esse processo é rápido, gratuito e muito fácil.',
    },
    {
      title: 'Tenho controle sobre quais colaboradores solicitam o crédito?',
      text: 'Sim! Sua empresa terá total autonomia através do nosso dashboard de controle, onde é possível administrar o limite de crédito, liberação por funcionário ou por departamento, entre outras regras estabelecidas por você.',
    },
    {
      title: 'Esse benefício vai gerar mais trabalho para o RH?',
      text: 'Não! Nossa tecnologia automatiza todo o processo e a interface do dashboard é completamente intuitiva, sem necessidade de treinamento. Assim, facilitamos a navegação e não depositamos mais trabalho para o RH da sua empresa.',
    },
    {
      title: 'Quanto tempo demora para disponibilizar esse benefício?',
      text: 'A liberação do crédito consignado acontece em aproximadamente 20 dias. Nesse período, todas as etapas são informadas para você com total clareza e simplificamos todo o processo para que ele seja o mais rápido e fácil possível.',
    },
    {
      title: 'Qual é o limite de crédito para os meus colaboradores?',
      text: 'O limite de crédito é estabelecido pela análise da empresa, pelo tempo de casa do funcionário e pela sua própria instituição, através do dashboard. Além disso, o colaborador pode ter parcelas que comprometem até 30% do salário.',
    },
  ]);

  return (
    <CntEmpresas>
      <HeroEmpresas>
        <div className="container">
          <div className="cnt-text">
            <h2 className="sup-title">LIBERDADE FINANCEIRA, DE VERDADE</h2>
            <h1 className="title">Uma parceria que aumenta o salário dos seus colaboradores!</h1>
            <div className="text">E o melhor: sem custo nenhum para sua empresa. Você reduz as dívidas do seu time com o Economizômetro e ainda acompanha a evolução através de dados de impacto positivo na vida dos colaboradores.</div>
            <div className="cnt-steps">
              <div className="group">
                <div className="icon">
                  <img src={IconStepCheck} alt="" />
                </div>
                <div className="step">Simplifica a rotina do RH</div>
              </div>
              <div className="group">
                <div className="icon">
                  <img src={IconStepCheck} alt="" />
                </div>
                <div className="step">Plano de saúde financeira</div>
              </div>
              <div className="group">
                <div className="icon">
                  <img src={IconStepCheck} alt="" />
                </div>
                <div className="step">Nenhum custo para a empresa</div>
              </div>
              <div className="group">
                <div className="icon">
                  <img src={IconStepCheck} alt="" />
                </div>
                <div className="step">Dashboards de acompanhamento </div>
              </div>
            </div>
          </div>
          <div className="cnt-form">
            <div className="bg-box-form">
              <div className="box-form">
                <div className="top-info">
                  <div className="box-title">Seja  parceira da Ali</div>
                  <div className="dots">
                    <img src={IconDots} alt="" />
                  </div>
                </div>
                <div id="my-hubspot-form" />
              </div>
            </div>
          </div>
        </div>
        <div className="icon-down">
          <img className="mouse" src={IconMouse} alt="" />
          <img className="arrow" src={IconMouseDown} alt="" />
        </div>
      </HeroEmpresas>
      {/* <SliderBrands /> */}
      <DashBoards>
        <div className="container">
          <h3 className="up-title">DASHBOARDS COMPLETOS</h3>
          <h2 className="title">
            <b>Acompanhe toda a evolução </b>
            da economia dos seus colaboradores
          </h2>
        </div>
        <div className="bot-img">
          <div className="container">
            <Rive src={AnimationHero} className="rive" />
            <a className="anchor" href="/">
              <img src={IconGoBottom} alt="Icone" />
            </a>
          </div>
        </div>
      </DashBoards>
      <BestOption>
        <div className="container">
          <div className="cnt-titles" data-aos="fade-right">
            <h3 className="up-title">A MELHOR OPÇÃO DE TODAS</h3>
            <h2 className="title">Não geramos trabalho para sua empresa, cuidamos de tudo para você!</h2>
            <div className="desc mob">
              Conquistar bem-estar financeiro não é só a ausência de dívidas.
              É sobre ter poder de escolha sob seu próprio dinheiro.
              Seja para fazer um investimento para o futuro, uma compra importante no presente,
              ou reduzir aquela dívida do passado.
            </div>
            <div className="desc">
              <div className="arrow">
                <img src={IconArrowAttention} alt="" />
              </div>
              <span>Não há qualquer custo ou taxa para ser conveniado com a Ali.</span>
            </div>
          </div>
          <div className="cnt-info" data-aos="fade-left">
            <div className="top-info">
              <img src={IconTabGlobe} alt="Icone" />
              <span>Para a empresa</span>
            </div>
            <ul>
              <li className="item">
                <b> Diminua o turnover da sua empresa </b>
                e retenha talentos no seu time.
              </li>
              <li className="item">
                <b> Otimize o trabalho do RH </b>
                com nosso processo de aprovação de crédito
                100% digital e que não leva mais que 2 minutos.
              </li>
              <li className="item">
                <b> Obtenha dados mensais de economia </b>
                e outros dados essenciais para acompanhar os impactos do benefício.
              </li>
              <li className="item">
                Aumente o salário dos seus colaboradores com o
                <b> Economizômetro.</b>
              </li>
            </ul>
          </div>
        </div>
      </BestOption>
      <Benefits>
        <div className="container top">
          <h2 className="title">Benefício, de verdade!</h2>
          <div className="cnt-boxes">
            <Swiper
              spaceBetween={25}
              slidesPerView="auto"
            >
              <SwiperSlide>
                <Link to="/para-empresas" className="box">
                  <div className="img green">
                    <img src={IconBenefits1} alt="" />
                  </div>
                  <div className="cnt-text">
                    <h3 className="box-title">AUMENTO DE PRODUTIVIDADE</h3>
                    <div className="box-text">O bem-estar financeiro aumenta a produtividade e evita que as dívidas prejudiquem as jornadas das equipes.</div>
                    <div className="highlight">
                      Seja um parceiro Ali
                      <img src={IconBenefitsArrow} alt="" />
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="/para-empresas" className="box">
                  <div className="img blue">
                    <img src={IconBenefits2} alt="" />
                  </div>
                  <div className="cnt-text">
                    <h3 className="box-title">EDUCAÇÃO FINANCEIRA</h3>
                    <div className="box-text">Nosso Economizômetro ajuda os colaboradores da sua empresa a reduzir dívidas e economizar dinheiro.</div>
                    <div className="highlight">
                      Seja um parceiro Ali
                      <img src={IconBenefitsArrow} alt="" />
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to="/para-empresas" className="box">
                  <div className="img yellow">
                    <img src={IconBenefits3} alt="" />
                  </div>
                  <div className="cnt-text">
                    <h3 className="box-title">IMPACTO POSITIVO</h3>
                    <div className="box-text">Acompanhe o quanto seus colaboradores estão economizando e toda a evolução financeira depois de se tornar um cliente Ali.</div>
                    <div className="highlight">
                      Seja um parceiro Ali
                      <img src={IconBenefitsArrow} alt="" />
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="show-more">
            <img src={iconShowMore} alt="" />
          </div>
        </div>
        <div className="benefits">
          <div className="container bot">
            <h4 className="up-title" data-aos="fade-up">FAÇA A DIFERENÇA NA SUA EMPRESA</h4>
            <h3 className="title" data-aos="fade-up" data-aos-delay="100">
              Tenha um impacto positivo na vida de todos os seus colaboradores
            </h3>
            <div className="bot-cnt" data-aos="fade-up" data-aos-delay="200">
              <Link to="/para-empresas" className="btn-default">Me tornar um parceiro Ali</Link>
              <Link to="/para-empresas" className="text">Ofereça para os colaboradores da sua empresa</Link>
            </div>
          </div>
        </div>
      </Benefits>
      <AliMarquee />
      <HowWorks>
        <div className="container">
          <h3 className="up-title">
            PARA O COLABORADOR
          </h3>
          <h2 className="title">E como funciona tudo isso?</h2>
          <div className="cnt-boxes">
            <Swiper
              spaceBetween={32}
              slidesPerView="auto"
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div className="box">
                  <div className="icon">
                    <img src={IconBox1} alt="" />
                  </div>
                  <div className="box-title">
                    Preenchimento
                    <br />
                    dos dados
                  </div>
                  <div className="box-text">Acesso imediato à margem disponível para conseguir o crédito.</div>
                  <div className="bottom">
                    <div className="info">Começa por aqui</div>
                    <div className="nr">1</div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box">
                  <div className="icon">
                    <img src={IconBox2} alt="" />
                  </div>
                  <div className="box-title">Escolha do valor e quantidade de parcelas</div>
                  <div className="box-text">Ele seleciona o valor e as parcelas para fazer a simulação em tempo real das taxas.</div>
                  <div className="bottom">
                    <div className="info">100% transparente</div>
                    <div className="nr">2</div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box">
                  <div className="icon">
                    <img src={IconBox3} alt="" />
                  </div>
                  <div className="box-title">Agora é a hora do envio de documentos</div>
                  <div className="box-text">Os documentos necessários são: RG, CPF, comprovante de residência e renda, carteira assinada e uma selfie.</div>
                  <div className="bottom">
                    <div className="info">Leva apenas 2 minutos</div>
                    <div className="nr">3</div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box">
                  <div className="icon">
                    <img src={IconBox4} alt="" />
                  </div>
                  <div className="box-title">Momento de análise e confirmação</div>
                  <div className="box-text">Pronto! Agora é só aguardar o contrato para ter o crédito com a Ali! O nosso processo de validação é rapidinho.</div>
                  <div className="bottom">
                    <div className="info">Confirmação rápida e fácil</div>
                    <div className="nr">4</div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="show-more">
            <img src={iconShowMore} alt="" />
          </div>
          <div className="icon-arrow">
            <img src={IconBoxArrow} alt="" />
            <span>Um baita benefício para os colaboradores da sua empresa!</span>
          </div>
        </div>
      </HowWorks>
      <SliderDepositions />
      <Faq faqAccordion={FAQ} />
      {/* <Simulation blogCards={BlogCards} /> */}
    </CntEmpresas>
  );
};

export default ParaEmpresas;
