import styled from 'styled-components';

export const CntAd = styled.div`
  min-height: 44px;
  background: #fe4059;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 117.7%;
  color: #FFFFFF;
  position: relative;
  z-index: 7;
  @media screen and (max-width: 768px) {
    padding: 10px 0;
    line-height: 1.2em;
  }
  .container {
    text-align: center;
  }
  a {
    font-weight: bold;
    text-decoration-line: underline;
  }
  img {
    margin-left: 11px;
  }
`;
