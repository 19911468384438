import styled from 'styled-components';

export const CntFaq = styled.section`
  background: rgba(245, 248, 253, 0.6);
  padding: 13.9rem 0 12.1rem;
  @media screen and (max-width: 800px) {
    padding: 8.9rem 0 7.1rem;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* gap: 20px; */
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
    .text {
      max-width: 338px;
      @media screen and (max-width: 800px) {
        max-width: 538px;
        margin: auto;
        * {
          text-align: center;
        }
      }
      .sub-title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 140%;
        letter-spacing: 0.1em;
        color: #6690f8;
        margin-bottom: 1.6rem;
      }

      .title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 140%;
        color: #0f204d;
        margin-bottom: 1rem;
      }

      .txt {
        margin-bottom: 3.3rem;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 174.5%;
        color: #606f8e;
      }

      .talk-to-us {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 150%;
        color: #0f204d;
        display: flex;
        align-items: center;
        @media screen and (max-width: 800px) {
          justify-content: center;
          margin-bottom: 4rem;
          text-align: left;
        }
        img {
          margin-right: 1.2rem;
        }
      }
    }
    .faq {
      flex: 1;
      max-width: 794px;
      margin-left: 2rem;
      @media screen and (max-width: 800px) {
        margin-left: 0;
      }
      .MuiPaper-elevation1 {
        box-shadow: none;
      }
      /* .MuiAccordion-root {
        border: 1px solid #e4ecfd;
        border-left: none;
        border-right: none;
      } */
      .MuiAccordion-root {
        padding: 0px 1.6rem 0 2.4rem;
        background: transparent;
        border-radius: 8px;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 600px) {
          padding: 3.4rem 1.6rem 3.4rem 2.4rem;
        }
        .MuiAccordionSummary-root {
          height: 84px;
          @media screen and (max-width: 600px) {
            height: unset;
          }
        }
        &:not(:last-child) {
          .MuiCollapse-entered:before {
            background: #e4ecfd;
            bottom: -1px;
            left: 0;
            right: 0;
            z-index: 1;
            height: 1px;
            content: '';
            opacity: 1;
            position: absolute;
            transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }
        }
        svg {
          circle {
            transition: fill 0.2s ease-in-out;
          }
          path {
            transition: fill 0.2s ease-in-out;
          }
        }
        &:hover,
        :active {
          background: #f0f6ff;
          svg {
            circle {
              fill: #fe4059;
            }
            path {
              fill: white;
            }
          }
        }
      }
      .MuiAccordionSummary-expandIcon.Mui-expanded {
        transform: rotate(0);
      }
      .MuiAccordion-root.Mui-expanded {
        margin: 0;
      }
      .MuiAccordion-root:before {
        /* opacity: 0; */
        background-color: #e4ecfd;
      }
      .MuiAccordionDetails-root {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 174.5%;
        color: #606f8e;
        padding: 0 16px 48px;
        @media screen and (max-width: 600px) {
          margin-top: 35px;
        }
      }

      .MuiAccordion-root.Mui-expanded:before {
        opacity: 1;
      }
      .MuiAccordionSummary-content {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 174.5%;
        color: #606f8e;
        @media screen and (max-width: 600px) {
          margin: 0;
          font-size: 1.6rem;
          line-height: 150%;
        }
      }
    }
  }
`;
