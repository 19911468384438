import styled from 'styled-components';

export const CntBlogCard = styled.a`
  max-width: 384px;
  border-radius: 8px;
  display: block;
  margin: 0 auto;
  width: 100%;
  .img {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 227px;
    border-radius: 8px 8px 0px 0px;

    img {
      transition: all 0.2s ease-in-out;
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: center;
    }
    .overlay-blog {
      position: absolute;
      z-index: 2;
      background: linear-gradient(
          0.37deg,
          #031946 -38.88%,
          rgba(4, 26, 69, 0) 75.8%
        ),
        url(image.png);
      mix-blend-mode: normal;
      width: 100%;
      height: 100%;
    }
  }
  .cnt-text {
    padding: 4.3rem 4.7rem;
    border: 1px solid #e4e7f2;
    border-radius: 0 0 8px 8px;
    border-top: 0;
    background: white;
    min-height: 260px;
    display: flex;
    flex-direction: column;
    .title {
      text-transform: uppercase;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 140%;
      letter-spacing: 0.1em;
      color: #6690f8;
      margin-bottom: 1.7rem;
    }
    .text {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;
      font-size: 1.9rem;
      line-height: 152%;
      color: #0f204d;
      margin-bottom: 2.9rem;
    }
    .read-more {
      margin-top: auto;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 150%;
      color: #fe4059;
      display: flex;
      align-items: center;
      img {
        margin-left: 8px;
      }
    }
  }
  &:hover {
    .img {
      img {
        transform: translateZ(0) scale(1.1);
      }
    }
  }
`;
