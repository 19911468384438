export const getEconomia = () => {
  const baseValue = 60000000;
  const tax = 1.8;
  const date1 = new Date('01/01/2022');
  const date2 = new Date();
  const difference = date2.getTime() - date1.getTime();
  const days = Math.ceil(difference / (1000 * 3600 * 24));
  return Math.floor(baseValue + (days ** tax * 1000));
};

export const getItemAmountByValue = (itemName = 'carro') => {
  const items = {
    carro: 30000,
    celular: 2500,
    tv: 1800,
    casa: 125000,
  };
  const qtt = Math.floor(getEconomia() / items[itemName]);
  if (qtt >= 1000) return `${Math.floor(qtt / 1000)} mil`;
  return qtt;
};
