import React from 'react';
import Marquee from 'react-fast-marquee';

import { MarqueeCnt } from './styles';

const AliMarquee = () => (
  <MarqueeCnt>
    <Marquee pauseOnHover gradient={false}>
      • Liberdade Financeira, de verdade
      • Liberdade Financeira, de verdade
      • Liberdade Financeira, de verdade
      • Liberdade Financeira, de verdade
      • Liberdade Financeira, de verdade
      • Liberdade Financeira, de verdade
      • Liberdade Financeira, de verdade
      • Liberdade Financeira, de verdade
      • Liberdade Financeira, de verdade
      • Liberdade Financeira, de verdade
      • Liberdade Financeira, de verdade
      • Liberdade Financeira, de verdade
    </Marquee>
  </MarqueeCnt>
);

export default AliMarquee;
