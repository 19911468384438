/* eslint-disable react/no-danger */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import SignupForm from 'components/signup-form';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ExpandMoreOutlined } from '@material-ui/icons';
import LpFooterBtg from 'components/lp-footer-btg';
import EconomizometroMockup from '../../images/btg_mockup.svg';
import {
  EconomizometroHeader,
  EconomizometroContent,
  MockupBox,
  Header,
  Content,
  Middle,
  InfoMiddle,
  FormBox,
  KnowAli,
  VideoBox,
  StyledVideo,
  When,
  Carousel,
  CarouselItem,
  CarouselButtonBox,
  Dot,
  NavButton,
  CarouselInner,
  CheckYourLimit,
  Faq,
  FaqItem,
  Question,
  Icon,
  Answer,
} from './styles';
import AliIso from '../../images/isotipo_ali.svg';
import BtgLogo from '../../images/logo_btg_pactual.svg';
import WomanWithRealAliLogo from '../../images/woman_with_real_ali_logo.svg';
import WomanSmiling from '../../images/woman_smiling.svg';
import TaxFee from '../../images/tax_fee.svg';
import Clock from '../../images/clock.svg';
import CreditSecured from '../../images/credit_secured.svg';
import Coins from '../../images/icon_coins.svg';
import MoneyPayroll from '../../images/money_payroll.svg';
import DigitalOnline from '../../images/digital_online.svg';
import Percentage from '../../images/percentage.svg';

const BtgLandingPageContent = ({
  logoUrl, videoUrl, pageTitle, pageContent, id, live, liveDate,
}) => {
  const formatText = (text) => {
    const formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    return formattedText.replace(/\n/g, '<br/>');
  };

  const [visibleIndex, setVisibleIndex] = React.useState(null);

  const toggleAnswer = (index) => {
    setVisibleIndex(visibleIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'Quem pode solicitar o crédito consignado?',
      answer: 'Todos os clientes BTG que trabalhem em empresas com convênio para crédito consignado com a Ali Crédito, parceira do Banco.',
    },
    {
      question: 'Como simular e contratar o Crédito Consignado?',
      answer: `Com o app BTG Banking você pode simular e contratar o crédito consignado em poucos minutos.

      1. Baixe e acesse o app BTG Banking;
      2. Selecione o “Menu” no fim da tela;
      3. Vá em “Crédito e Financiamento”;
      4. Clique em “Solicitar Crédito”;
      5. Selecione a opção “Crédito Consignado”;
      6. Inicie a sua simulação na nossa página e conclua a contratação.`,
    },
    {
      question: 'Como identificar se a empresa que trabalho tem convênio com a Ali Crédito?',
      answer: 'Você deve acessar o app BTG Banking, solicitar o crédito e avaliar se o produto é ofertado.',
    },
    {
      question: 'Preciso ter conta no app BTG Banking para continuar?',
      answer: 'Sim, a contratação se dá na aba financiamentos do app BTG Banking.',
    },
    {
      question: 'Onde é feito o pagamento das parcelas?',
      answer: 'O pagamento das parcelas é descontado diretamente da folha de pagamento do cliente que contratou o crédito.',
    },
    {
      question: 'Como identificar o valor que tenho disponível e a taxa de juros?',
      answer: 'Para verificar a taxa, o cliente deve acessar o app BTG Banking na aba financiamentos, clicar em solicitar crédito, crédito consignado e logo terá a taxa para a contratação.',
    },
    {
      question: 'Tenho um Crédito Consignado em andamento, posso contratar outro?',
      answer: 'Pode, caso as parcelas do crédito atual contratado não ultrapassem 30% do seu salário',
    },
  ];

  const [itemsToShow, setItemsToShow] = React.useState(4);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(1);
  const items = [
    { src: TaxFee, title: 'Taxas de juros reduzidas' },
    { src: Clock, title: 'Mais prazo para começar a pagar' },
    { src: CreditSecured, title: 'Parcelas fixas, sem surpresas' },
    { src: Coins, title: 'Parcelamento em até 60 vezes' },
    { src: DigitalOnline, title: 'Contratação 100% on-line' },
    { src: MoneyPayroll, title: 'Desconto direto no holerite' },
  ];

  const itemWidth = 380;
  const itemGap = 20;
  const visibleWidth = itemsToShow * itemWidth + (itemsToShow - 1) * itemGap;

  React.useEffect(() => {
    const updateItemsToShow = () => {
      if (window.innerWidth >= 1720) {
        setItemsToShow(4);
      } else if (window.innerWidth >= 1300) {
        setItemsToShow(3);
      } else if (window.innerWidth >= 910) {
        setItemsToShow(2);
      } else {
        setItemsToShow(1);
      }
    };

    window.addEventListener('resize', updateItemsToShow);
    updateItemsToShow();

    return () => {
      window.removeEventListener('resize', updateItemsToShow);
    };
  }, []);

  React.useEffect(() => {
    setTotalPages(Math.ceil(items.length / itemsToShow));
    setCurrentIndex(0);
  }, [itemsToShow, items.length]);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - itemsToShow);
    }
  };

  const handleNext = () => {
    if (currentIndex < items.length - itemsToShow) {
      setCurrentIndex(currentIndex + itemsToShow);
    }
  };

  const getActivePage = () => Math.floor(currentIndex / itemsToShow);

  return (
    <>
      <Helmet>
        <title>
          Ali |&nbsp;
        </title>
      </Helmet>
      <Header>
        <img src={BtgLogo} alt="Btg logo" style={{ height: '40px' }} />
        <div>
          <img src={AliIso} alt="Ali logo" style={{ height: '40px' }} />
          <div>
            <p>Crédito consignado BTG</p>
            <p><b>operado por Ali</b></p>
          </div>
        </div>
        <img src={logoUrl} alt="Company logo" style={{ height: '40px' }} />
      </Header>
      <Content>
        <Middle>
          <InfoMiddle>
            <h1>
              {pageTitle ?? 'Participe do encontro online com a Ali,'}
            </h1>
            <p dangerouslySetInnerHTML={{ __html: formatText(pageContent ?? 'e conheça o novo benefício de crédito para redução de dívidas que seu RH preparou para você.') }} />
            {live && (
              <When>
                <div>
                  <p>Quando?</p>
                  <h2>{format(liveDate, "d 'de' MMMM 'às' HH'h'mm", { locale: ptBR }) ?? 'Em breve'}</h2>
                </div>
              </When>
            )}
          </InfoMiddle>
          <FormBox>
            <SignupForm company={id} origin={live ? 'live' : 'institutional'} />
          </FormBox>
        </Middle>
      </Content>
      <KnowAli>
        <img src={WomanWithRealAliLogo} alt="Mulher segurando logo da Ali" />
        <div>
          <h1>Conheça a Ali</h1>
          <h2>
            A Ali é a empresa escolhida pelo BTG para operar seu benefício de crédito consignado
            para redução de dívidas. Com objetivo de descomplicar a vida financeira das pessoas,
            a Ali trabalha para evitar novas dívidas e resolver a sua saúde financeira por meio
            de crédito sustentável.
          </h2>
        </div>
      </KnowAli>
      <VideoBox>
        <StyledVideo>
          <iframe title="Video" src={videoUrl} allow="autoplay;" />
        </StyledVideo>
      </VideoBox>
      <KnowAli>
        <img src={WomanSmiling} alt="Mulher sorrindo em frente a um prédio, segurando um celular e um copo de café" />
        <div>
          <p>O que é Crédito Consignado?</p>
          <h1>Entenda o que é o Crédito Consignado Privado</h1>
          <p>
            O crédito consignado Privado é uma modalidade de empréstimo que pode
            ser contratada por colaboradores de empresas conveniadas e oferece
            uma série de vantagens, principalmente quando comparado a outros
            tipos de crédito.
          </p>
          <p>
            O pagamento das parcelas é descontado diretamente no seu holerite.
          </p>
        </div>
      </KnowAli>
      <Carousel visibleWidth={visibleWidth}>
        <h1>Benefícios do crédito consignado privado</h1>
        <div>
          <CarouselInner translate={currentIndex * (itemWidth + itemGap)}>
            {items.map((item) => (
              <CarouselItem key={item.title}>
                <img src={item.src} alt="Ícone do benefício" />
                <h2>{item.title}</h2>
              </CarouselItem>
            ))}
          </CarouselInner>
        </div>
        <CarouselButtonBox>
          <NavButton onClick={handlePrev} disabled={currentIndex === 0}>
            &lt;
          </NavButton>
          {Array.from({ length: totalPages }).map((_, index) => (
            <Dot key={_} active={getActivePage() === index} />
          ))}
          <NavButton onClick={handleNext} disabled={currentIndex >= items.length - itemsToShow}>
            &gt;
          </NavButton>
        </CarouselButtonBox>
      </Carousel>
      <CheckYourLimit>
        <div>
          <p>Diferenciais do Consiginado Privado</p>
          <h1>Consignado Privado vs Outros tipos de Crédito</h1>
          <h2>
            O crédito consignado é uma alternativa de crédito que possui uma taxa de juros
            menor em relação a outras modalidades de crédito disponíveis no mercado.
          </h2>
        </div>
        <img src={Percentage} alt="Ícone de porcentagem" />
      </CheckYourLimit>
      <EconomizometroHeader>
        <div>
          <h1>
            Já possui consignado Privado em outra instituição?
          </h1>
          <h2>
            Pelo aplicativo BTG Banking, você pode realizar a portabilidade do
            seu crédito consignado contratado para o BTG Pactual.
          </h2>
          <a href="https://vai.ali.com.vc/consignadobtg">Fazer portabilidade</a>
        </div>
        <MockupBox>
          <img src={EconomizometroMockup} alt="Economizometro mockup" />
        </MockupBox>
      </EconomizometroHeader>
      <EconomizometroContent>
        <div>
          <p>Utilize seu crédito também para redução de dívidas</p>
          <h1>Economize hoje mesmo com o Economizômetro® da Ali.</h1>
          <h2>
            O Economizômetro® é uma tecnologia exclusiva da Ali, onde você troca
            a dívida cara por outra mais barata e decide se vai pagar em parcelas
            mais leves ou menos parcelas. Tudo em poucos cliques. E você ainda sabe
            exatamente quanto economiza.
          </h2>
          <a href="https://vai.ali.com.vc/consignadobtg">Simule agora</a>
        </div>
      </EconomizometroContent>
      <Faq>
        <h1>Perguntas frequentes</h1>
        {faqData.map((item, index) => (
          <FaqItem key={item.answer}>
            <Question onClick={() => toggleAnswer(index)}>
              {item.question}
              <Icon isVisible={visibleIndex === index}><ExpandMoreOutlined /></Icon>
            </Question>
            <Answer isVisible={visibleIndex === index}>{item.answer}</Answer>
          </FaqItem>
        ))}
      </Faq>
      <LpFooterBtg />
    </>
  );
};

BtgLandingPageContent.propTypes = {
  logoUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  pageTitle: PropTypes.string,
  pageContent: PropTypes.string,
  id: PropTypes.number.isRequired,
  live: PropTypes.bool.isRequired,
  liveDate: PropTypes.string,
};

BtgLandingPageContent.defaultProps = {
  logoUrl: '',
  videoUrl: '',
  pageTitle: '',
  pageContent: '',
  liveDate: '',
};

export default BtgLandingPageContent;
