import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useLax } from 'use-lax';
import { Link } from 'react-router-dom';

import { CntPageFaq, FaqHero } from './styles';
import Simulation from '../../components/simulation';

import { ReactComponent as IconAccordion } from '../../images/icon_accordion.svg';
import IconArrowRed from '../../images/icon_arrow_right.svg';
import { CntFaq } from '../../components/faq/styles';
import { ReactComponent as IconChevron } from '../../images/icon_chevron_right_gray.svg';
import { ReactComponent as IconDoubt } from '../../images/icon_faq_doubt.svg';
import { ReactComponent as IconGlobe } from '../../images/icon_faq_globe.svg';
import { ReactComponent as IconPerson } from '../../images/icon_faq_person.svg';
import { ReactComponent as IconStart } from '../../images/icon_faq_start.svg';

const FaqPage = () => {
  useLax();
  const [FAQGerais] = React.useState([
    {
      title: 'Posso confiar na Ali?',
      text: 'Somos uma fintech de crédito que concorre com os bancos para conseguir oferecer juros realmente justos e com muito menos burocracia! De forma completamente transparente, queremos ajudar a vida financeira das pessoas, de verdade. Além disso, também temos como prioridade oferecer um processo 100% seguro.Um exemplo disso é o nosso protocolo SSL(Secure Socket Layer), que permite a troca de informações entre clientes e os servidores da Ali em total segurança, além de impossibilitar fraudes e outros riscos.Uma maneira de conferir essa proteção(no nosso site e em outros também!) é através do seu endereço, que deve começar com "https://", onde o S significa “secured” (seguro, em inglês).',
    },
    {
      title: 'Para quem é o crédito da Ali?',
      text: 'Ele é para todos os funcionários das empresas parceiras da Ali que estejam trabalhando na instituição e dentro da política de crédito! Sua empresa ainda não tem uma parceria com a gente? É só falar com o RH da sua empresa ou acessar nosso site e clicar em Seja um parceiro Ali! Esse processo é rápido, fácil, gratuito e sigiloso.',
    },
    {
      title: 'A Ali pede alguma taxa ou valor antecipado?',
      text: 'Não! A Ali não pede nenhuma taxa ou valor antecipado. Caso isso aconteça com você, fique atento, pois você pode estar sendo vítima de uma tentativa de fraude. Nesta situação, não prossiga com a conversa e entre em contato com a nossa equipe de atendimento assim que possível. Também é importante ressaltar que, no crédito consignado da Ali, nenhum valor é cobrado diretamente para você, já que as mensalidades são descontadas diretamente da sua folha de pagamento.',
    },
    {
      title: 'Esse benefício vai gerar mais trabalho para o RH?',
      text: 'Não! Nossa tecnologia automatiza todo o processo e a interface do dashboard é completamente intuitiva, sem necessidade de treinamento. Assim, facilitamos a navegação e não depositamos mais trabalho para o RH da sua empresa.',
    },
    {
      title: 'Como posso habilitar o benefício para os meus colaboradores?',
      text: 'Você pode entrar em contato através do nosso próprio site, clicando em Seja um parceiro da Ali, ou pelo nosso telefone (11) 4003-4458. Ah! E não se preocupe, esse processo é rápido, gratuito e muito fácil.',
    },
    {
      title: 'O que a Ali gera de vantagem para minha empresa?',
      text: 'Primeiro de tudo, fazemos o salário do seu colaborador valer mais, sem que a sua empresa gaste mais. Fazemos isso através de uma tecnologia proprietária da Ali que evita ou reduz a dívida dos colaboradores. Com isso, a melhora da produtividade e satisfação dos seus colaboradores com a empresa é nítida, além disso, com a ajuda da nossa solução é possível melhorar taxas de turnover e aumentar a retenção da equipe. O melhor de tudo é que não há custo ou fidelidade para a empresa e também elimina todo e qualquer retrabalho que o RH tenha, graças à automatização e integração com os principais sistemas de folha de pagamento.',
    },
  ]);
  const [FAQEmpresas] = React.useState([
    {
      title: 'O que a Ali gera de vantagem para minha empresa?',
      text: 'Primeiro de tudo, fazemos o salário do seu colaborador valer mais, sem que a sua empresa gaste mais. Fazemos isso através de uma tecnologia proprietária da Ali que evita ou reduz a dívida dos colaboradores. Com isso, a melhora da produtividade e satisfação dos seus colaboradores com a empresa é nítida, além disso, com a ajuda da nossa solução é possível melhorar taxas de turnover e aumentar a retenção da equipe. O melhor de tudo é que não há custo ou fidelidade para a empresa e também elimina todo e qualquer retrabalho que o RH tenha, graças à automatização e integração com os principais sistemas de folha de pagamento.',
    },
    {
      title: 'Como posso habilitar o benefício para os meus colaboradores?',
      text: 'Você pode entrar em contato através do nosso próprio site, clicando em Seja um parceiro da Ali, ou pelo nosso telefone (11) 4003-4458. Ah! E não se preocupe, esse processo é rápido, gratuito e muito fácil.',
    },
    {
      title: 'Tenho controle sobre quais colaboradores solicitam o crédito?',
      text: 'Sim! Sua empresa terá total autonomia através do nosso dashboard de controle, onde é possível administrar o limite de crédito, liberação por funcionário ou por departamento, entre outras regras estabelecidas por você.',
    },
    {
      title: 'Esse benefício vai gerar mais trabalho para o RH?',
      text: 'Não! Nossa tecnologia automatiza todo o processo e a interface do dashboard é completamente intuitiva, sem necessidade de treinamento. Assim, facilitamos a navegação e não depositamos mais trabalho para o RH da sua empresa.',
    },
    {
      title: 'Quanto tempo demora para disponibilizar esse benefício?',
      text: 'A liberação do crédito consignado acontece em aproximadamente 20 dias. Nesse período, todas as etapas são informadas para você com total clareza e simplificamos todo o processo para que ele seja o mais rápido e fácil possível.',
    },
    {
      title: 'Qual é o limite de crédito para os meus colaboradores?',
      text: 'O limite de crédito é estabelecido pela análise da empresa, pelo tempo de casa do funcionário e pela sua própria instituição, através do dashboard. Além disso, o colaborador pode ter parcelas que comprometem até 30% do salário.',
    },
  ]);
  const [FAQColaboadores] = React.useState([
    {
      title: 'A Ali pede alguma taxa ou valor antecipado?',
      text: 'Não! A Ali não pede nenhuma taxa ou valor antecipado. Caso isso aconteça com você, fique atento, pois você pode estar sendo vítima de uma tentativa de fraude. Nesta situação, não prossiga com a conversa e entre em contato com a nossa equipe de atendimento assim que possível. Também é importante ressaltar que, no crédito consignado da Ali, nenhum valor é cobrado diretamente para você, já que as mensalidades são descontadas diretamente da sua folha de pagamento.',
    },
    {
      title: 'Posso confiar na Ali?',
      text: 'Somos uma fintech de crédito que concorre com os bancos para conseguir oferecer juros realmente justos e com muito menos burocracia! De forma completamente transparente, queremos ajudar a vida financeira das pessoas, de verdade. Além disso, também temos como prioridade oferecer um processo 100% seguro. Um exemplo disso é o nosso protocolo SSL (Secure Socket Layer), que permite a troca de informações entre clientes e os servidores da Ali em total segurança, além de impossibilitar fraudes e outros riscos. Uma maneira de conferir essa proteção (no nosso site e em outros também!) é através do seu endereço, que deve começar com "https://", onde o S significa “secured” (seguro, em inglês).',
    },
    {
      title: 'Preciso enviar muitos documentos?',
      text: 'Não! O envio de documentos é rápido e simples, de verdade. :) Os documentos necessários para fazer o consignado da Ali são RG ou CNH atualizados, um comprovante de endereço que pode ser uma conta de consumo, como água, luz e internet em seu nome ou de parentesco de primeiro grau, como pai, mãe ou filhos, e um comprovante de renda, que pode ser seu holerite. Nesta parte, também vamos precisar saber em qual empresa você trabalha para checar se temos convênio com a instituição. Mas não se preocupe, seu nome não é exposto em nenhum momento. Ah! E também vamos precisar de uma selfie sua, só para ter mais segurança durante o nosso processo.',
    },
    {
      title: 'Para quem é o crédito da Ali?',
      text: 'Ele é para todos os funcionários das empresas parceiras da Ali que estejam trabalhando na instituição e dentro da política de crédito! Sua empresa ainda não tem uma parceria com a gente? É só falar com o RH da sua empresa ou acessar nosso site e clicar em Seja um parceiro Ali! Esse processo é rápido, fácil, gratuito e sigiloso.',
    },
    {
      title: 'Posso contratar mesmo com restrições no CPF?',
      text: 'Sim! Como o pagamento das parcelas sai direto da folha de pagamento, também oferecemos crédito consignado para pessoas negativadas ou com restrições financeiras. A melhor parte é que, ao reduzir suas dívidas, você finalmente pode acabar com as pendências e ter mais liberdade financeira.',
    },
    {
      title: 'Como e quando recebo o dinheiro?',
      text: 'Depois da assinatura do seu contrato, o dinheiro cai na sua conta em até 24 horas! Além disso, o depósito do crédito é feito na conta informada por você durante o pedido de crédito. A única exigência é que ela tenha a mesma titularidade, ou seja, a conta precisa estar no seu nome!',
    },
  ]);
  const [FAQStart] = React.useState([
    {
      title: 'O que a minha empresa precisa fazer para se conveniar a Ali?',
      text: 'Basta acessar Seja um parceiro Ali em nosso site! Esse processo é rápido, fácil, gratuito e sigiloso.',
    },
    {
      title: 'Como posso solicitar o empréstimo?',
      text: 'Se você trabalha em uma empresa conveniada com a Ali, basta acessar o nosso site www.alicredito.com.br e clicar em “Solicitar empréstimo” ou envie um “Oi” no nosso WhatsApp (11) 4003-4458.',
    },
    {
      title: 'Quais documentos preciso enviar para solicitar meu crédito?',
      text: 'Os documentos necessários para fazer o consignado da Ali são RG ou CNH atualizados, um comprovante de endereço que pode ser uma conta de consumo, como água, luz e internet em seu nome ou de parentesco de primeiro grau, como pai, mãe ou filhos, e um comprovante de renda, que pode ser seu holerite. Nesta parte, também vamos precisar saber em qual empresa você trabalha para checar se temos convênio com a instituição. Mas não se preocupe, seu nome não é exposto em nenhum momento. Ah! E também vamos precisar de uma selfie sua, só para ter mais segurança durante o nosso processo.',
    },
    {
      title: 'Como posso fazer uma simulação sem compromisso?',
      text: 'Basta enviar uma mensagem em nosso WhatsApp (11) 4003-4458 ou acessar nosso site na aba “Para colaboradores” e escolher o valor que precisa e em quantas vezes deseja parcelar. Nossa plataforma irá te mostrar uma estimativa que está sujeita a análise de crédito.',
    },
    {
      title: 'Após a contratação, em quanto tempo o dinheiro cai na conta?',
      text: 'Depois da assinatura do seu contrato, o dinheiro cai na sua conta em até 24 horas! Além disso, o depósito do crédito é feito na conta informada por você durante o pedido de crédito. A única exigência é que ela tenha a mesma titularidade, ou seja, a conta precisa estar no seu nome!',
    },
    {
      title: 'Como é feita a divulgação para os meus colaboradores?',
      text: 'Toda a divulgação é feita a partir das políticas da própria empresa, respeitando seus limites e formatos. Aqui na Ali, temos uma equipe disponível para visitas e, é claro, para o esclarecimento de todas as dúvidas. Além disso, a divulgação também pode ser feita por email ou através dos nossos materiais de comunicação, como banners, flyers e apresentações.',
    },
  ]);
  const [expanded, setExpanded] = React.useState('');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const isInViewPort = (elem) => {
    if (elem) {
      const bounding = elem.getBoundingClientRect();
      return (
        bounding.top >= 0
        && bounding.left >= 0
        && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
    return false;
  };
  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      switch (true) {
        case isInViewPort(document.querySelector('#doubt')):
          document.querySelectorAll('.item').forEach((element) => {
            element.classList.remove('active');
          });
          document.querySelector('.link_doubt').classList.add('active');
          break;
        case isInViewPort(document.querySelector('#company')):
          document.querySelectorAll('.item').forEach((element) => {
            element.classList.remove('active');
          });
          document.querySelector('.link_company').classList.add('active');
          break;
        case isInViewPort(document.querySelector('#people')):
          document.querySelectorAll('.item').forEach((element) => {
            element.classList.remove('active');
          });
          document.querySelector('.link_people').classList.add('active');
          break;
        case isInViewPort(document.querySelector('#start')):
          document.querySelectorAll('.item').forEach((element) => {
            element.classList.remove('active');
          });
          document.querySelector('.link_start').classList.add('active');
          break;
        default:
          break;
      }
    }, false);
  }, []);
  return (
    <CntPageFaq>
      <FaqHero>
        <div className="container">
          <div className="up-title">PROCESSO PRÁTICO, FÁCIL E RÁPIDO</div>
          <h1 className="title">Como podemos te ajudar?</h1>
          <div className="cnt-action">
            <a href={`${process.env.REACT_APP_WEBAPP_URL}`} target="_blank" rel="noreferrer" className="btn-default">Simule aqui</a>
            <Link to="/para-empresas" className="action">
              <span>
                Seja um parceiro Ali
              </span>
              <img src={IconArrowRed} alt="" />
            </Link>
          </div>
        </div>
      </FaqHero>
      <CntFaq className="cnt-faq-page">
        <div className="container cnt-faq">
          <div className="text">
            <div className="up-title">SUMÁRIO DAS DÚVIDAS</div>
            <div className="cnt-itens">
              <a href="#doubt" className="item link_doubt">
                <div className="icon"><IconDoubt /></div>
                <div className="faq-text">Dúvidas Gerais</div>
                <div className="icon-btn"><IconChevron /></div>
              </a>
              <a href="#company" className="item link_company">
                <div className="icon"><IconGlobe /></div>
                <div className="faq-text">Para Empresas</div>
                <div className="icon-btn"><IconChevron /></div>
              </a>
              <a href="#people" className="item link_people">
                <div className="icon"><IconPerson /></div>
                <div className="faq-text">Para Colaboradores</div>
                <div className="icon-btn"><IconChevron /></div>
              </a>
              <a href="#start" className="item link_start">
                <div className="icon"><IconStart /></div>
                <div className="faq-text">Como começar?</div>
                <div className="icon-btn"><IconChevron /></div>
              </a>
            </div>
          </div>
          <div className="faq">
            <div
              id="doubt"
              className="mt"

            >
              <h3 className="faq-title">Dúvidas gerais</h3>
              {FAQGerais.map((el) => (
                <Accordion key={el.title} expanded={expanded === `panel${el.title}`} onChange={handleChange(`panel${el.title}`)}>
                  <AccordionSummary
                    expandIcon={<IconAccordion />}
                    aria-controls={`panel${el.title}a-content`}
                    id={`panel${el.title}a-header`}
                  >
                    {el.title}
                  </AccordionSummary>
                  <AccordionDetails>
                    {el.text}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            <div
              id="company"
              className="mt"
            >
              <h3 className="faq-title">Para empresas</h3>
              {FAQEmpresas.map((el) => (
                <Accordion key={el.title} expanded={expanded === `panel${el.title}`} onChange={handleChange(`panel${el.title}`)}>
                  <AccordionSummary
                    expandIcon={<IconAccordion />}
                    aria-controls={`panel${el.title}a-content`}
                    id={`panel${el.title}a-header`}
                  >
                    {el.title}
                  </AccordionSummary>
                  <AccordionDetails>
                    {el.text}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            <div
              className="mt"
              id="people"
            >
              <h3 className="faq-title">Para colaboradores</h3>
              {FAQColaboadores.map((el) => (
                <Accordion key={el.title} expanded={expanded === `panel${el.title}`} onChange={handleChange(`panel${el.title}`)}>
                  <AccordionSummary
                    expandIcon={<IconAccordion />}
                    aria-controls={`panel${el.title}a-content`}
                    id={`panel${el.title}a-header`}
                  >
                    {el.title}
                  </AccordionSummary>
                  <AccordionDetails>
                    {el.text}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            <div
              className="mt"
              id="start"
            >
              <h3 className="faq-title">Como começar?</h3>
              {FAQStart.map((el) => (
                <Accordion key={el.title} expanded={expanded === `panel${el.title}`} onChange={handleChange(`panel${el.title}`)}>
                  <AccordionSummary
                    expandIcon={<IconAccordion />}
                    aria-controls={`panel${el.title}a-content`}
                    id={`panel${el.title}a-header`}
                  >
                    {el.title}
                  </AccordionSummary>
                  <AccordionDetails>
                    {el.text}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </CntFaq>
      <Simulation />
    </CntPageFaq>
  );
};

export default FaqPage;
