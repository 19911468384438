import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import { useLax } from 'use-lax';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { Link } from 'react-router-dom';

import {
  CntAboutUs,
  CntHeroAboutUs,
  Policy,
  BePartner,
} from './styles';

import arrowRight from '../../images/icon_arrow_right_white.svg';

import logoAliSmall from '../../images/logo_ali_small_white.svg';
import IconStepCheck from '../../images/icon_step_check.svg';
import IconDots from '../../images/icon_dots_form.svg';

SwiperCore.use([Navigation, Pagination]);

const PrivacyPolicy = () => {
  useLax();
  useHubspotForm({
    portalId: '20869742',
    formId: '3b157591-e008-4855-9cd3-ba7e55777eca',
    target: '#my-hubspot-form',
  });
  return (
    <CntAboutUs>
      <CntHeroAboutUs>
        <div className="city lax" data-lax-translate-y="(vh) -200, (-elh) 200" data-lax-scale=" 500 1,1000 1.4" data-lax-anchor="self" />
        <div className="persons lax" data-lax-translate-y="(vh) -50, (-elh) 50" data-lax-anchor="self" />
        <div className="cloud1 lax" data-lax-translate-y="(vh) 200, (-elh) -400" data-lax-anchor="self" />
        <div className="cloud2 lax" data-lax-translate-y="(vh) 200, (-elh) -200" data-lax-anchor="self" />
        <div className="container">
          <div className="up-title">ACESSO A CRÉDITO JUSTO DE VERDADE</div>
          <h1 className="title">
            Impactando positivamente a vida
            de milhares de brasileiros
          </h1>
          <div className="cnt-btn">
            <a href={`${process.env.REACT_APP_WEBAPP_URL}`} target="_blank" rel="noreferrer" className="btn-default">
              Simule aqui
              <img src={arrowRight} alt="" />
            </a>
            <Link to="/para-empresas" className="desc">Seja um parceiro Ali</Link>
          </div>
        </div>
      </CntHeroAboutUs>
      <Policy>
        <div className="container">
          <div className="square">
            <img src={logoAliSmall} alt="" />
          </div>
          <div className="cnt-text">
            <div className="up-title">A SUA MAIOR ALIADA FINANCEIRA</div>
            <h2 className="title">Política de Privacidade e Tratamento de Dados</h2>
            <div className="desc">
              Esta Política de Privacidade e Tratamento de Dados (&quot;Política de Privacidade e
              tratamento de dados&quot;) contém as condições gerais aplicáveis ao tratamento dos
              Dados Pessoais realizado pela ALI CRÉDITO E PAGAMENTOS S.A., sociedade anônima
              inscrita no CNPJ sob o nº 29.279.532/0001-91, com sede na R. Gomes de Carvalho,
              1765, 7° andar, Vila Olímpia, São Paulo - SP, 05401-200, Brasil
              (denominado simplesmente &quot;ALI&quot;), por meio do Site www.ali.com.vc e
              www.alicredito.com.br, bem como aplicativos (&quot;Plataformas Ali&quot;),
              tendo como objetivo fornecer uma visão transparente das práticas relacionadas
              à coleta, ao uso, ao armazenamento e ao tratamento dos dados pela ALI.
            </div>
            <div className="desc">
              Quando um usuário interage com Ali, por meio de nossas aplicações online ou meios de
              contato como nosso WhatsApp, canais ativos e receptivos, são coletadas e tratadas
              informações pessoais que devem ser protegidas.
            </div>
            <div className="desc">
              A Ali reafirma seu compromisso em cumprir, plenamente, a legislação de proteção de
              dados pessoais, em especial a Lei nº 13.709/2018 (Lei Geral de Proteção de Dados -
              aqui também chamada de LGPD).
            </div>
            <div className="desc">
              A presente Política de Privacidade contém informações a respeito do modo como
              tratamos os Dados Pessoais dos usuários que acessam nossos produtos/serviços.
            </div>
            <div className="desc">
              O objetivo desta Política é esclarecer, aos interessados, acerca dos tipos de
              Dados Pessoais que são coletados, a forma como os Dados são tratados e, os
              motivos da coleta e a forma como o usuário poderá interagir conosco para
              atualizar e gerenciar estas informações.
            </div>
            <div className="desc">
              Esta Política foi elaborada em conformidade com a Lei Federal nº 12.965
              de 23 de abril de 2014 (Marco Civil da Internet) e com a Lei Federal
              nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais).
            </div>
            <div className="desc">
              Se após a leitura deste documento, ainda restarem dúvidas com relação ao
              tratamento dos Dados Pessoais, solicitamos que entre em contato conosco por
              meio de nosso canal de atendimento.
            </div>
            <div className="desc">
              Se após a leitura deste documento, ainda restarem dúvidas com relação ao
              tratamento dos Dados Pessoais, solicitamos que entre em contato conosco por
              meio de nosso canal de atendimento.
            </div>
            <div className="desc-subtitle">Quem é o Controlador dos dados?</div>
            <div className="desc">
              Ali Crédito e Pagamentos S.A. (&quot;Ali&quot;), CNPJ 29.279.532/0001-91,
              com sede na R. Gomes de Carvalho, 1765, 7° andar, Vila Olímpia,
              São Paulo - SP, 05401-200, Brasil, é o responsável pelo Tratamento
              de Dados Pessoais de seus clientes e colaboradores, na função
              de Controlador.
            </div>
            <div className="desc-subtitle">Abrangência da Política de Privacidade de Dados</div>
            <div className="desc">
              Os termos da Política de Privacidade aqui expostos dizem respeito, exclusivamente,
              aos Dados Pessoais e Dados Pessoais Sensíveis, conforme abaixo definido, que
              venham a ser disponibilizadas a Ali, pelo Titular de Dados, para a utilização
              de seus produtos e serviços.
            </div>
            <div className="desc">
              Por consequência, a Política de Privacidade aqui exposta não será aplicável
              a qualquer outro serviço online que não os disponibilizados pela Ali, incluídos
              aqueles que estejam de alguma forma vinculados a Ali, por meio de links ou
              quaisquer outros recursos tecnológicos disponibilizados em seu website, e, ainda,
              a quaisquer outros serviços que, de qualquer forma, venham a ser conhecidos ou
              utilizados pela Ali.
            </div>
            <div className="desc">
              Neste sentido, alertamos aos Titulares de Dados que os serviços disponibilizados
              por Terceiros, podem conter uma política de privacidade diversa da adotada pela
              Ali ou podem, até mesmo, não adotar qualquer política nesse sentido, não se
              responsabilizando, a Ali, pelos Dados Pessoais que o Titular dos Dados compartilhar
              diretamente com tais terceiros.
            </div>
            <div className="desc">
              As palavras e os termos constantes nesta Política de Privacidade, aqui não
              expressamente definidos, grafados em português ou em qualquer outro idioma,
              bem como, quaisquer outros de linguagem técnica e/ou financeira ou não, que,
              eventualmente, durante a vigência do presente instrumento, no cumprimento de direitos
              e obrigações assumidos por ambas as partes, sejam utilizados para identificar a
              prática de quaisquer atos, deverão ser compreendidos e interpretados em consonância
              com o conceito internacionalmente consagrado.
            </div>
            <div className="desc-subtitle">Definições</div>
            <div className="desc">
              Para uma melhor compreensão desta Política de Privacidade, considerem os seguintes
              conceitos:
            </div>
            <ul>
              <li className="desc">
                Dado Pessoal: informação relacionada a pessoa natural identificada ou
                identificável;
              </li>
              <li className="desc">
                Dado Pessoal Sensível: dado pessoal sobre origem racial ou étnica, convicção
                religiosa, opinião política, filiação a sindicato ou a organização de caráter
                religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado
                genético ou biométrico, quando vinculado a uma pessoa natural;
              </li>
              <li className="desc">
                Dado Anonimizado: dado relativo à Titular de Dados que não possa ser identificado,
                considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de
                seu Tratamento;
              </li>
              <li className="desc">
                Banco de Dados: conjunto estruturado de dados pessoais, estabelecido em um ou em
                vários locais, em suporte eletrônico ou físico;
              </li>
              <li className="desc">
                Titular(es) de Dados: pessoa natural a quem se referem os dados pessoais
                que são objeto de Tratamento;
              </li>
              <li className="desc">
                Controlador: pessoa natural ou jurídica, de direito público ou privado,
                a quem competem as decisões referentes ao Tratamento de dados pessoais;
              </li>
              <li className="desc">
                Operador: pessoa natural ou jurídica, de direito público ou privado,
                que realiza o Tratamento de dados pessoais em nome do controlador;
              </li>
              <li className="desc">
                Encarregado de Proteção de Dados (DPO): pessoa indicada pelo controlador
                e operador para atuar como canal de comunicação entre o controlador, os
                Titulares de Dados e a Autoridade Nacional de Proteção de Dados (ANPD);
              </li>
              <li className="desc">
                Agentes de Tratamento: o controlador e o operador;
              </li>
              <li className="desc">
                Tratamento: toda operação realizada com dados pessoais, como as que se
                referem a coleta, produção, recepção, classificação, utilização, acesso,
                reprodução, transmissão, distribuição, processamento, arquivamento,
                armazenamento, eliminação, avaliação ou controle da informação,
                modificação, comunicação, transferência, difusão ou extração;
              </li>
              <li className="desc">
                Anonimização: utilização de meios técnicos razoáveis e disponíveis no
                momento do Tratamento, por meio dos quais um dado perde a possibilidade
                de associação, direta ou indireta, a um indivíduo;
              </li>
              <li className="desc">
                Consentimento: manifestação livre, informada e inequívoca pela qual o
                Titular de Dados concorda com o Tratamento de seus dados pessoais para
                uma finalidade determinada;
              </li>
              <li className="desc">
                Bloqueio: suspensão temporária de qualquer operação de Tratamento,
                mediante guarda do dado pessoal ou do banco de dados;
              </li>
              <li className="desc">
                Eliminação: exclusão de dado ou de conjunto de dados armazenados
                em banco de dados, independentemente do procedimento empregado;
              </li>
              <li className="desc">
                Transferência Internacional de Dados: transferência de dados
                pessoais para país estrangeiro ou organismo internacional do
                qual o país seja membro;
              </li>
              <li className="desc">
                Uso Compartilhado de Dados: comunicação, difusão, transferência
                internacional, interconexão de dados pessoais ou Tratamento
                compartilhado de bancos de dados pessoais por órgãos e entidades
                públicos no cumprimento de suas competências legais, ou entre esses
                e entes privados, reciprocamente, com autorização específica, para
                uma ou mais modalidades de Tratamento permitidas por esses entes
                públicos, ou entre entes privados; e
              </li>
              <li className="desc">
                Autoridade Nacional de Proteção de Dados (ANPD): órgão da administração
                pública responsável por zelar, implementar e fiscalizar o cumprimento
                desta lei em todo o território nacional.
              </li>
            </ul>
            <div className="desc-subtitle">Princípios para o Tratamento de Dados</div>
            <div className="desc">
              A Ali se compromete a cumprir as normas previstas na LGPD, e respeitar,
              no tratamento dos Dados Pessoais, os seguintes princípios:
            </div>
            <ul>
              <li className="desc">
                Uso legítimo, adequado e proporcional dos Dados Pessoais
              </li>
              <li className="desc">
                Realizamos o Tratamento dos Dados Pessoais para propósitos legítimos,
                específicos e explícitos, sem possibilidade de Tratamento posterior de
                forma incompatível com as finalidades que foram apresentadas ao Titular de
                Dados ou em desacordo com o que foi informado ao Titular de Dados Pessoais.
              </li>
            </ul>
            <div className="desc">
              O Tratamento dos Dados Pessoais é limitado ao mínimo necessário para a
              realização das finalidades, sendo que os Dados Pessoais são pertinentes
              e proporcionais ao Tratamento realizado.
            </div>
            <ul>
              <li className="desc">
                Garantia de livre-acesso
              </li>
              <li className="desc">
                Garantimos consulta facilitada com relação ao uso dos Dados Pessoais,
                prestando informações claras, precisas e acessíveis sobre a realização
                do Tratamento dos Dados aos Titulares de Dados.
              </li>
              <li className="desc">
                Transparência e qualidade
              </li>
              <li className="desc">
                A Ali informa sobre cada Tratamento de dados que realiza. Os Dados
                Pessoais serão atualizados e claros, de acordo com a necessidade e
                para o cumprimento da finalidade do Tratamento.
              </li>
              <li className="desc">
                Segurança no uso dos Dados Pessoais
              </li>
              <li className="desc">
                Utilizamos medidas técnicas e administrativas aptas a proteger os Dados
                Pessoais de acessos não autorizados e de situações acidentais ou ilícitas
                de destruição, perda, alteração, comunicação ou difusão
              </li>
              <li className="desc">
                Adoção de medidas de prevenção
              </li>
              <li className="desc">
                Adotamos medidas para prevenir a ocorrência de danos em virtude do uso ou
                acesso ilegítimo ou não autorizado aos Dados Pessoais.
              </li>
              <li className="desc">
                Não-discriminação no uso dos Dados Pessoais
              </li>
              <li className="desc">
                Não realizamos o Tratamento de Dados Pessoais para fins discriminatórios,
                ilícitos ou abusivos.
              </li>
              <li className="desc">
                Responsabilização e prestação de contas
              </li>
            </ul>
            <div className="desc">
              Adotamos medidas eficazes e capazes de comprovar a observância e o
              cumprimento das normas de proteção de Dados Pessoais e da eficácia destas
              medidas.
            </div>
            <div className="desc-subtitle">Quem é o Controlador dos dados?</div>
            <div className="desc">
              Ali e Pagamentos LTDA (&quot;Ali&quot;), CNPJ 29.279.532/0001-91, com sede na
              R. Gomes de Carvalho, 1765, 7° andar, Vila Olímpia, São Paulo - SP, 05401-200,
              Brasil, é o responsável pelo Tratamento de Dados Pessoais de seus clientes
              e colaboradores, na função de Controlador.
            </div>
            <div className="desc-subtitle">Encarregado pelo Tratamento de Dados Pessoais (DPO)</div>
            <div className="desc">
              O Encarregado pelo Tratamento de Dados Pessoais (DPO) poderá ser contatado pelo
              e-mail dados@ali.com.vc.
            </div>
            <div className="desc">
              O Encarregado pelo Tratamento de Dados Pessoais é responsável por receber e
              endereçar reclamações e comunicações dos Titulares de Dados, receber comunicações
              da Autoridade Nacional de Proteção de Dados (ANPD), orientar os funcionários da
              Ali sobre as práticas de proteção de Dados Pessoais, e executar as demais
              atribuições determinadas pela Ali, ou estabelecidas em normas complementares.
            </div>
            <div className="desc-subtitle">Dados Pessoais Coletados</div>
            <div className="desc">
              Os seguintes Dados Pessoais ou Dados Pessoais Sensíveis do Titular de Dados
              poderão ser coletados e tratados pela Ali, dependendo do aplicativo, programa
              ou relacionamento: Nome Completo; Nome Social; Data de nascimento; Sobrenome;
              CPF; Carteira de identidade; Carteira Nacional de habilitação (CNH); Carteira
              de Trabalho e Previdência Social (CTPS); Passaporte; Idade; Nacionalidade;
              E-mail; Naturalidade; Nome da mãe; Nome do pai; Endereço Residencial; Endereço
              Comercial; Estado Civil; Sexo; Telefones residencial; Telefones comercial; RNE
              (Registro Nacional de Estrangeiros), Telefones celular; Condição Pessoal
              (espólio, interditado, portador de deficiência, etc); Renda; Patrimônio;
              IMEI do celular; Origem racial; Geolocalização; Foto; Filmagens; Biometria;
              Áudio/Voz; Pessoa Politicamente Exposta; Título de eleitor; Documentos
              profissionais (CREA, OAB e etc.); PIS/NIS - Programa de integração social;
              Profissão; Formação Acadêmica; IP; Cookies; Posicionamento político; Filiação
              sindical; Nome e idade dos filhos; Score de crédito; Nome dos pais e responsável
              legal; Mídias sociais; Escolaridade; Número de contrato de financiamento;
              Seguros; Exames e diagnósticos médicos.
            </div>
            <div className="desc-subtitle">A Coleta dos Dados Pessoais</div>
            <div className="desc">
              Os Dados Pessoais mencionados acima são obtidos nas seguintes situações:
            </div>
            <ul>
              <li className="desc">
                diretamente do Titular de Dados, quando da contratação dos produtos
                e serviços desta instituição financeira ou em simulações em fase de proposta;
              </li>
              <li className="desc">
                de fontes externas legítimas, com devido embasamento legal ou contratual, ou
                em razão de eventual compartilhamento de Dados Pessoais entre empresas
                parceiras da Ali, sem prejuízo do disposto na Lei e nas hipóteses em que o
                consentimento for necessário;
              </li>
              <li className="desc">
                de modo automático, utilizando algumas tecnologias como cookies, com o
                propósito de monitorar a experiência de navegação do Titular dos Dados nas
                aplicações online.
              </li>
            </ul>
            <div className="desc-subtitle">As Finalidades de Tratamento dos Dados Pessoais</div>
            <div className="desc">
              Os Dados Pessoais obtidos em processo de cadastro junto a Ali serão utilizados
              para permitir o acesso do Titular de Dados a determinados serviços, exclusivos
              para cadastrados.
            </div>
            <div className="desc">
              Para o fornecimento dos serviços decorrentes de contratos firmados entre a Ali
              e o Titular de Dados, poderão ser coletados e armazenados outros Dados Pessoais
              relacionados ou necessários à sua execução, incluindo o teor de eventuais
              comunicações tidas com o Titular de Dados.
            </div>
            <div className="desc">
              Os Dados Pessoais eventualmente informados por quem utilizar o formulário de
              contato, disponibilizado no site da Ali, na internet, incluindo o teor da
              mensagem enviada, serão coletados e tratados para atender às solicitações pelo
              Titular dos Dados, bem como, no interesse legítimo da Ali e do Titular dos
              Dados.
            </div>
            <div className="desc">
              Os Dados Pessoais poderão ser utilizados, também, com uma finalidade comercial,
              para personalizar o conteúdo dos produtos / serviços da Ali oferecido ao
              Titular de Dados, bem como para dar subsídio a Ali para melhorar a qualidade
              e funcionamento de seus serviços.
            </div>
            <div className="desc">
              Caso o Titular de Dados decida fornecer suas informações pessoais nos sites
              institucionais, aplicativos, whatsapp de negócios ou demais canais de atendimento
              da Ali para usufruir dos serviços, inclusive as informações pessoais sensíveis,
              tais informações serão tratadas atendendo às finalidades definidas em contrato de
              prestação de serviços e nos Termos e Condições de Uso da aplicação específica.
            </div>
            <div className="desc-subtitle">As bases legais utilizadas para o Tratamento dos Dados Pessoais</div>
            <div className="desc">
              Uma vez provido das informações pessoais a respeito do Titular de Dados, a Ali
              poderá utilizar, com fundamento em seu legítimo interesse, os Dados Pessoais do
              Titular de Dados, tendo como objetivo enviar publicidade, direcionada por
              e-mail ou por quaisquer outros meios de comunicação, contendo informações
              sobre os serviços da Ali que possam ser interessantes para o Titular dos Dados.
              Entretanto, fica reservado ao Titular de Dados o direito de, a qualquer momento,
              inclusive no ato da disponibilização das informações, informar a Ali, por meio
              dos canais de comunicação disponíveis para o cadastramento de tais informações,
              a respeito do não interesse em receber tais anúncios, inclusive por e-mail
              (opt-out), hipótese em que a Ali interromperá o Tratamento dos Dados Pessoais
              no menor tempo possível.
            </div>
            <div className="desc">
              A Ali também poderá tratar Dados Pessoais do Titular de Dados para o
              cumprimento de obrigações legais decorrentes de leis e regulamentos aos
              quais a Ali é obrigado a cumprir em razão de sua natureza jurídica, ou em
              razão das atividades que desenvolve, inclusive, levando em consideração a
              importância de suas ações como agente de proteção ao crédito nacional, e na
              prevenção de fraudes.
            </div>
            <div className="desc">
              A Ali ainda poderá realizar o Tratamento dos Dados Pessoais para fins de
              exercer seus direitos em processos judiciais, administrativos ou arbitrais,
              ou ainda, para exercer seus direitos em contratos.
            </div>
            <div className="desc">
              Eventualmente, outros Dados Pessoais poderão ser coletados com o consentimento
              do Titular de Dados. Um termo de consentimento com informações relevantes a
              respeito do tratamento dos Dados Pessoais será apresentado ao Titular dos Dados
              sempre que isso ocorrer. Nesta situação, o Titular dos Dados tem o direito de
              retirar seu consentimento a qualquer momento, não comprometendo a licitude do
              Tratamento de seus Dados Pessoais antes da retirada, mediante procedimento
              descrito no item Exercício de Direitos do Titular de Dados.
            </div>
            <div className="desc">
              Os Dados Pessoais de crianças sempre serão tratados mediante o consentimento
              específico e em destaque dado por, pelo menos, um dos pais ou pelo responsável
              legal.
            </div>
            <div className="desc-subtitle">Compartilhamento de Dados Pessoais e Transferência Internacional</div>
            <div className="desc">
              A Ali, na condição de Controlador dos Dados poderá compartilhar com empresas
              parceiras, sempre com a estrita observância à Lei, Dados Pessoais e informações
              cadastrais, financeiras e de operações ativas e passivas e serviços contratados
              dos Titulares de Dados para:
            </div>
            <ul>
              <li className="desc">
                garantir maior segurança e prevenir fraudes;
              </li>
              <li className="desc">
                assegurar sua adequada identificação, qualificação e autenticação;
              </li>
              <li className="desc">
                prevenir atos relacionados à lavagem de dinheiro e outros atos ilícitos;
              </li>
              <li className="desc">
                realizar análises de risco de crédito;
              </li>
              <li className="desc">
                aperfeiçoar o atendimento e os produtos e serviços prestados;
              </li>
              <li className="desc">
                fazer ofertas de produtos e serviços adequados e relevantes aos seus
                interesses e necessidades de acordo com o perfil do Titular de Dados;
              </li>
              <li className="desc">
                outras hipóteses baseadas em finalidades legítimas, como apoio e promoção
                de atividades da Ali e de empresas parceiras ou para a prestação de serviços
                que beneficiem seus clientes.
              </li>
            </ul>
            <div className="desc">
              Os prestadores de serviços em questão podem estar localizados fora do Brasil,
              e o tratamento de seus Dados Pessoais estará submetido a critérios contratuais
              semelhantes aqueles constantes desta Política de Privacidade.
            </div>
            <div className="desc">
              O compartilhamento em questão será realizado com base no consentimento do
              titular dos Dados, ou no contexto do cumprimento de obrigações legais ou na
              execução de contratos firmados com o Titular dos Dados Pessoais.
            </div>
            <div className="desc-subtitle">Empresas que prestam serviços para a ALI</div>
            <ul>
              <li className="desc">
                A ALI poderá compartilhar dados coletados com prestadores de serviços
                terceirizados na medida em que tais informações sejam necessárias à prestação
                do serviço ou aprimoramento de algum finalidade do aplicativo/site.
                Esses prestadores de serviços terceirizados podem ter sido contratados para,
                por exemplo, prestar atendimento ao Usuário, auxiliar na resolução de
                problemas técnicos, realizar a checagem de informações e prevenção à fraude,
                avaliar e gerenciar riscos, entre outros.
              </li>
            </ul>
            <div className="desc-subtitle">Com terceiros conforme permitido ou exigido por lei</div>
            <ul>
              <li className="desc">
                A ALI poderá compartilhar dados com terceiros quando permitido ou exigido por
                lei como, por exemplo, para o cumprimento de obrigação legal ou regulatória;
                para fins de auditoria; prevenção à fraude e lavagem de dinheiro; para investigar
                violações ou cumprir uma previsão de um contrato celebrado com o Usuário; para
                a proteção do crédito; para cumprir uma ordem judicial, ou para a proteção
                jurídica de nossos colaboradores ou interesses legítimos, sempre de acordo com
                as leis aplicáveis.
              </li>
            </ul>
            <div className="desc-subtitle">Parceiros</div>
            <ul>
              <li className="desc">
                Com a finalidade de recomendar ao usuário, de forma personalizada, produtos e
                serviços oferecidos por Parceiros, a ALI poderá compartilhar seus dados com o
                Parceiro quando tal compartilhamento for necessário para a oferta ou contratação
                do produto/serviço (e o Parceiro poderá checar seu status em bureaus de
                crédito/órgãos de proteção ao crédito, por exemplo).
              </li>
              <li className="desc">
                Lembre-se de que a ALI apenas recomenda esses produtos e serviços, portanto,
                o envio dos dados ao Parceiro em alguns casos é necessário para que ele possa
                (i) verificar se o você é elegível a um serviço ou produto a ser recomendado
                pela ALI como, por exemplo, uma determinada modalidade de empréstimo ou outro
                serviço financeiro, e (ii) concluir a contratação mediante solicitação do
                Usuário.
              </li>
              <li className="desc">
                Da mesma forma, a ALI poderá receber do Parceiro informações sobre a
                disponibilidade de uma oferta, a contratação ou não do produto/serviço
                pelo usuário, bem como outros dados relacionados à performance da parceria
                como, por exemplo, casos de inadimplência e fraude.
              </li>
            </ul>
            <div className="desc-subtitle">Potenciais Parceiros</div>
            <ul>
              <li className="desc">
                Com o objetivo de estabelecer novas parcerias, a ALI poderá compartilhar
                com potenciais Parceiros algumas informações que sejam necessárias à
                concretização de tal parceria. O potencial Parceiro apenas poderá receber
                as informações após a assinatura de Acordo de Confidencialidade prevendo
                que os dados compartilhados serão utilizados única e exclusivamente para os
                fins da concretização da parceria e serão destruídos caso essa não aconteça.
              </li>
              <li className="desc">
                Fique tranquilo: nenhum dado financeiro não anonimizado poderá ser
                compartilhado sem a autorização do Usuário.
              </li>
            </ul>
            <div className="desc">
              Ainda, a Ali compartilha infraestrutura, sistemas e tecnologias com outras
              empresas do setor financeiro para fornecer uma experiência inovadora,
              relevante, consistente e segura em todos os produtos e serviços da empresa.
            </div>
            <div className="desc">
              Ressaltamos que novos serviços online, disponibilizados pela Ali, estarão
              automaticamente sujeitos a este Política, de acordo com a versão vigente à
              época de sua utilização. Tais informações são coletadas por meio dos canais
              de atendimento e armazenadas utilizando-se rígidos padrões de sigilo e
              integridade, bem como controles de acesso físico e lógico, observando-se,
              sempre, os mais elevados princípios éticos e legais.
            </div>
            <div className="desc-subtitle">Responsabilidades</div>
            <div className="desc">
              Durante a utilização dos serviços disponibilizados pela Ali, o Titular de Dados
              é responsável:
            </div>
            <ul>
              <li className="desc">
                Por todo ato, por ação ou omissão, realizado em nossos sites institucionais,
                aplicativos, whatsapp de negócios, ou outro canal de atendimento,
                responsabilizando-se pela prática de todo e qualquer ato ilícito
                comprovadamente praticado;
              </li>
              <li className="desc">
                Pelo conteúdo gerado na utilização dos serviços;
              </li>
              <li className="desc">
                Pela reparação de danos causados a terceiros e a Ali, a partir do uso dos
                serviços / produtos;
              </li>
              <li className="desc">
                Pelo equipamento necessário para realizar a conexão à internet, como computador
                e modem, garantindo a segurança adequada do ambiente, mediante a utilização de
                ferramentas como antivírus e firewall; e
              </li>
              <li className="desc">
                Por não utilizar os Serviços para outras finalidades além daquelas indicadas
                nos sites institucionais, aplicativos, whatsapp de negócios, ou outro canal
                de atendimento.
              </li>
            </ul>
            <div className="desc-subtitle">Propriedade Intelectual</div>
            <div className="desc">
              A Ali assegura que as informações (textos, imagens, sons e/ou aplicativos)
              contidas nos seus sites estão de acordo com a legislação e normativos que
              regulam os direitos de propriedade intelectual (autoral e industrial), bem
              como pertencem a Ali ou a terceiro que licitamente / contratualmente cedeu
              o seu direito de uso, não sendo permitidas modificações, cópias, reproduções
              ou quaisquer outras formas de utilização para fins comerciais sem o
              consentimento prévio e expresso da Ali. Você declara que irá respeitar todos
              os direitos de propriedade intelectual contidos nos nossos serviços, ciente
              de que o direito de acesso ao serviço não concede qualquer autorização para
              uso de direitos de propriedade intelectual constantes daquele serviço.
            </div>
            <div className="desc-subtitle">Acesso a Conteúdo Restrito e Suspensão de Acesso</div>
            <div className="desc">
              Considerando que o Titular dos Dados é responsável pela veracidade das informações
              cadastradas, informamos que o cadastro de informações falsas poderá gerar
              inconsistência na prestação dos serviços, bem como impactar ou interromper o acesso
              às funcionalidades. A qualquer tempo, sem aviso prévio, a Ali poderá suspender,
              cancelar ou interromper o acesso aos serviços, respeitadas as condições da legislação
              aplicável.
            </div>
            <div className="desc">
              A Ali não se responsabiliza por eventuais danos e/ou problemas decorrentes da
              demora, interrupção ou bloqueio nas transmissões de Dados Pessoais decorrentes
              da conexão de Internet do Titular dos Dados ou do ambiente que se encontra.
            </div>
            <div className="desc">
              Ao utilizar os serviços / produtos da Ali, o titular dos Dados se compromete a
              respeitar a legislação brasileira vigente e o disposto nesta Política, não
              devendo produzir, disponibilizar ou compartilhar qualquer conteúdo que:
            </div>
            <ul>
              <li className="desc">
                Implique a prática de ato ilícito e viole a legislação brasileira vigente;
              </li>
              <li className="desc">
                Viole direitos de terceiro ou direitos da Ali;
              </li>
              <li className="desc">
                Seja falso, incorreto, impreciso, extemporâneo e que possa induzir outrem a erro;
              </li>
              <li className="desc">
                Seja ilegal, violento, pornográfico ou qualquer outro ato contrário à lei e à ordem
                pública;
              </li>
              <li className="desc">
                Induza a discriminação ou incite o ódio contra pessoas e/ou grupos de pessoas em
                razão de nacionalidade, raça, religião, orientação sexual, gênero, condição física,
                nacionalidade, dentre outros atos que contrariem a ordem pública e a legislação
                brasileira vigente;
              </li>
              <li className="desc">
                Seja resguardado por direito de propriedade intelectual de terceiro e você não tenha
                autorização prévia para utilizá-lo;
              </li>
              <li className="desc">
                Contenha vírus ou outro elemento que seja capaz, em razão de suas características
                (como forma, extensão, etc.), de causar danos ou impedir o funcionamento regular da
                rede, do sistema, de aplicativos e/ou de equipamentos informáticos
                (&quote;hardware&quote; e &quote;software&quote;) da Ali ou de terceiros.
              </li>
            </ul>
            <div className="desc-subtitle">Segurança no Tratamento dos Dados Pessoais</div>
            <div className="desc">
              A Ali se compromete a aplicar as Medidas Técnicas e Administrativas aptas a
              proteger os Dados Pessoais de acessos não autorizados e de situações de
              destruição, perda, alteração, comunicação ou difusão de tais Dados.
            </div>
            <div className="desc">
              Para a garantia da segurança, serão adotadas soluções que levem em consideração:
              as técnicas adequadas; os custos de aplicação; a natureza, o âmbito, o contexto e
              as finalidades do Tratamento de Dados Pessoais; e os riscos para os direitos e
              liberdades do Titular de Dados.
            </div>
            <div className="desc">
              As aplicações eletrônicas da Ali utilizam a tecnologia de certificado SSL
              (Secure Socket Layer), a qual garante que os Dados Pessoais sejam transmitidos
              de forma segura e confidencial, de maneira que a transmissão de quaisquer informações
              entre o servidor de dados e o Titular de Dados, e em retroalimentação, ocorra de
              maneira totalmente cifrada ou encriptada.
            </div>
            <div className="desc">
              No entanto, a Ali se exime de responsabilidade por culpa exclusiva do Titular
              de Dados como no caso em que ele mesmo transfira seus Dados a terceiros ou
              adote comportamento que facilite o ataque de hackers ou crackers.
            </div>
            <div className="desc">
              A Ali se compromete, ainda, a comunicar o Titular de Dados, em prazo adequado,
              caso ocorra algum tipo de violação da segurança de seus Dados Pessoais e que
              possa lhe causar um alto risco para seus direitos e liberdades pessoais.
            </div>
            <div className="desc">
              A violação de Dados Pessoais é uma violação de segurança que provoque, de modo
              acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o
              acesso não autorizado a Dados Pessoais transmitidos, conservados ou sujeitos a
              qualquer outro tipo de Tratamento.
            </div>
            <div className="desc">
              O acesso aos Dados Pessoais coletados, armazenados ou de outra forma tratados
              pela Ali é restrito aos profissionais autorizados ao uso direto dessas
              informações, e necessário à prestação de seus serviços, sendo limitado o uso
              para outras tarefas.
            </div>
            <div className="desc">
              É exigido, também, de toda organização ou do indivíduo contratado para a prestação
              de serviços de apoio, que sejam cumpridas as Políticas de Segurança da Informação e
              o Código de Ética adotado pela Ali.
            </div>
            <div className="desc">
              A Ali poderá revelar as informações pessoais que tenha recebido nas seguintes
              hipóteses:
            </div>
            <ul>
              <li className="desc">
                sempre que estiver obrigado a revelá-las, seja em virtude de dispositivo legal,
                ato de autoridade competente, ordem ou mandado judicial;
              </li>
              <li className="desc">
                aos seus parceiros comerciais e/ou prestadores de serviço, a fim de atender à
                solicitação de serviços efetuada pelos Titulares de Dados;
              </li>
              <li className="desc">
                aos órgãos de proteção e defesa de crédito e prestadores de serviços autorizados
                pela Ali a defender seus direitos e créditos;
              </li>
              <li className="desc">
                aos órgãos que administrem cadastros de consumidores;
              </li>
              <li className="desc">
                para outras instituições financeiras, desde que dentro dos parâmetros legais
                estabelecidos para tanto, podendo, nesta hipótese, o Titular de Dados, a qualquer
                tempo, cancelar esta autorização.
              </li>
            </ul>
            <div className="desc">
              A Ali trabalha exaustivamente para assegurar que as informações divulgadas para os
              clientes sejam verdadeiras e íntegras, contando com controles apurados de
              monitoramento das informações fornecidas. Sua participação no processo é revisar
              as informações, valores e informativos e enviar, para o Titular de Dados, um
              comunicado de qualquer discrepância nas informações fornecidas.
            </div>
            <div className="desc">
              Por fim, a Ali se compromete a tratar os Dados Pessoais do Titular de Dados com
              confidencialidade, dentro dos limites legais.
            </div>
            <div className="desc-subtitle">Gestão de Cookies e Configurações do Navegador</div>
            <div className="desc">
              Os cookies são arquivos de Internet que armazenam, temporariamente, o que o
              internauta está visitando na rede. Esses bytes geralmente possuem formato de
              texto e não ocupam praticamente nenhum espaço no disco rígido do computador do
              Titular de Dados.
            </div>
            <div className="desc">
              Os cookies de sites são aqueles enviados ao computador ou dispositivo do
              Titular de Dados e administrado exclusivamente pelo site.
            </div>
            <div className="desc">
              As informações coletadas por meio destes cookies são utilizadas para melhorar
              e personalizar a experiência do Titular de Dados, sendo que alguns cookies
              podem, por exemplo, ser utilizados para lembrar as preferências e escolhas
              do Titular de Dados, bem como para o oferecimento de conteúdo personalizado.
            </div>
            <div className="desc">
              Estes dados de navegação poderão, ainda, ser compartilhados com eventuais
              parceiros do site, buscando o aprimoramento dos produtos e serviços ofertados
              ao Titular de Dados.
            </div>
            <div className="desc">
              O Titular de Dados poderá se opor ao registro de cookies pelos sites
              institucionais da Ali, bastando que desative esta opção no seu próprio
              navegador ou aparelho.
            </div>
            <div className="desc">
              A desativação dos cookies, no entanto, pode afetar a disponibilidade de
              algumas ferramentas e funcionalidades dos sites, comprometendo seu correto
              e esperado funcionamento. Outra consequência possível é a remoção das
              preferências do Titular de Dados que eventualmente tiverem sido salvas,
              prejudicando sua experiência.
            </div>
            <div className="desc">
              A Ali utiliza plugins de redes sociais, que permitem acessá-las a partir dos
              sites institucionais. Assim, ao fazê-lo, os cookies utilizados por elas poderão
              ser armazenados no navegador do Titular de Dados.
            </div>
            <div className="desc">
              Cada rede social possui sua própria política de privacidade e de proteção de
              Dados Pessoais, sendo as pessoas físicas ou jurídicas que as mantêm,
              responsáveis pelos dados coletados e pelas práticas de privacidade adotadas.
            </div>
            <div className="desc-subtitle">Prazo de Conservação dos Dados Pessoais</div>
            <div className="desc">
              Os Dados Pessoais do Titular de Dados serão conservados pelo prazo legal
              permitido ou enquanto perdurar a relação contratual da Ali com o Titular
              de Dados, exceto se o Titular de Dados solicitar a eliminação de seus Dados
              Pessoais tratados com seu Consentimento, ressalvadas as situações legais e
              regulatórias que autorizem a Ali a conservar esses dados, por exemplo:
            </div>
            <ul>
              <li className="desc">
                para o cumprimento de obrigação legal ou regulatória pela Ali;
              </li>
              <li className="desc">
                para a transferência a terceiro, desde que respeitados os requisitos
                de Tratamento de dados dispostos na legislação; e
              </li>
              <li className="desc">
                para uso exclusivo da Ali, vedado seu acesso por terceiro, e desde
                que ocorra a anonimização dos dados.
              </li>
            </ul>
            <div className="desc-subtitle">Exercício de Direitos do Titular de Dados</div>
            <div className="desc">
              O Titular de Dados poderá exercer seus direitos diretamente ou por meio de
              representante legalmente constituído. Seus direitos são:
            </div>
            <ul>
              <li className="desc">
                Confirmação da existência de Tratamento de seus Dados Pessoais;
              </li>
              <li className="desc">
                Acesso a seus Dados Pessoais;
              </li>
              <li className="desc">
                Correção de dados incompletos, inexatos ou desatualizados;
              </li>
              <li className="desc">
                Anonimização, bloqueio ou eliminação de dados desnecessários,
                excessivos ou tratados em desconformidade com o disposto na LGPD;
              </li>
              <li className="desc">
                Portabilidade dos dados a outro fornecedor de serviço ou produto,
                mediante requisição expressa, de acordo com a regulamentação da Autoridade
                Nacional de Proteção de Dados (ANPD), observados os segredos comercial e
                industrial;
              </li>
              <li className="desc">
                Eliminação dos seus Dados Pessoais tratados com o seu consentimento,
                exceto nas hipóteses previstas no art. 16 da LGPD;
              </li>
              <li className="desc">
                Informação das entidades públicas e privadas com as quais o Controlador
                realizou uso compartilhado de dados;
              </li>
              <li className="desc">
                Informação sobre a possibilidade de não fornecer consentimento e sobre as
                consequências da negativa;
              </li>
              <li className="desc">
                Revogação do seu consentimento, na forma e hipóteses previstas na legislação,
                ratificados os Tratamentos realizados sob o amparo do consentimento
                anteriormente manifestado;
              </li>
              <li className="desc">
                Oposição a Tratamento realizado com fundamento em uma das hipóteses de
                dispensa de consentimento, em caso de descumprimento ao disposto na LGPD;
              </li>
              <li className="desc">
                Solicitar a revisão de decisões tomadas unicamente com base em Tratamento
                automatizado de Dados Pessoais que afetem seus interesses, incluídas as
                decisões destinadas a definir o seu perfil pessoal, profissional, de consumo
                e de crédito ou os aspectos de sua personalidade.
              </li>
            </ul>
            <div className="desc">
              Você poderá exercer os seus direitos por meio de comunicação escrita enviada
              a Ali com o assunto &quot;LGPD-Ali&quot;, especificando:
            </div>
            <ul>
              <li className="desc">
                Nome completo, número do CPF (Cadastro de Pessoas Físicas, da Receita Federal do
                Brasil) e endereço de e-mail do Titular de Dados e, se for o caso, do seu
                representante;
              </li>
              <li className="desc">
                Direito que deseja exercer junto a Ali;
              </li>
              <li className="desc">
                Data do pedido;
              </li>
              <li className="desc">
                Qualquer documento que comprove ser o Titular de Dados do direito a ser exercido.
              </li>
            </ul>
            <div className="desc">
              Durante a utilização dos serviços, a fim de resguardar e de proteger os direitos de
              terceiros, o Titular de Dados deverá fornecer, somente, seus Dados Pessoais, e não
              os de terceiros.
            </div>
            <div className="desc">
              A coleta de Dados se dará em conformidade com o disposto nesta Política e dependerá
              do consentimento do Titular de Dados, sendo este dispensável somente nas hipóteses
              previstas no art. 11, inciso II e art. 7, incisos II a X, e §§ 4º e 6º da LGPD.

            </div>
            <div className="desc">
              A Ali se esforça para atender os direitos do Titular dos Dados. No entanto,
              caso o Titular dos Dados entenda que sua solicitação não foi atendida, poderá
              peticionar para a Autoridade Nacional de Proteção de Dados (ANPD).
            </div>
            <div className="desc">
              Quando alguma autoridade, a exemplo da Autoridade Nacional de Proteção de
              Dados (ANPD), requerer o acesso a seus Dados Pessoais e este pedido estiver
              respaldado por lei vigente, a Ali compartilhará as suas informações. Nestes
              casos, a Ali fornecerá as informações estritamente necessárias ao cumprimento
              do pedido.
            </div>
            <div className="desc-subtitle">Das Alterações da Política de Privacidade</div>
            <div className="desc">
              A versão desta Política de Privacidade foi atualizada em: 18/09/2020.
            </div>
            <div className="desc">
              A Ali se reserva o direito de modificar, a qualquer momento, em seus sites
              institucionais, as presentes normas, especialmente para adaptá-las às
              evoluções dos serviços, seja pela disponibilização de novas funcionalidades,
              seja pela supressão ou modificação daquelas já existentes, seja para atender
              atos regulatórios / legais.
            </div>
            <div className="desc">
              Ao utilizar o serviço após eventuais modificações, o Titular de Dados
              demonstra sua concordância com as novas normas. Caso discorde de alguma das
              modificações, deverá pedir, imediatamente, o cancelamento de sua conta ou do
              serviço e apresentar a sua ressalva ao serviço de atendimento, se assim o
              desejar.
            </div>
            <div className="desc-subtitle">Disposições Finais</div>
            <div className="desc">
              Eventuais omissões ou meras tolerâncias das partes ao exigir o estrito e pleno
              cumprimento desta Política de Privacidade e/ou de prerrogativas decorrentes
              dela ou da lei, não constituirão novação ou renúncia, nem afetarão o exercício
              de quaisquer direitos aqui previstos, que poderão ser plena e integralmente
              exercidos, a qualquer tempo.
            </div>
            <div className="desc">
              Caso se perceba que uma disposição é nula, as disposições restantes desta
              Política de Privacidade permanecerão em pleno vigor e um termo válido
              substituirá o termo nulo, refletindo nossa intenção, tanto quanto possível.
            </div>
            <div className="desc-subtitle">Alterações nesta Política</div>
            <div className="desc">
              Essa Política pode passar por atualizações. Desta forma, recomendamos visitar
              periodicamente esta página para que você tenha conhecimento sobre as
              modificações. Caso sejam feitas alterações relevantes que ensejem novas
              autorizações suas, publicaremos uma nova política de privacidade.
            </div>
            <div className="desc">
              A utilização dos serviços online disponibilizados pela Ali, por qualquer
              Titular de Dados, implicará em expressa aceitação das diretrizes e regras
              constantes neste documento.
            </div>
          </div>
        </div>
      </Policy>
      <BePartner>
        <div className="container" id="contact">
          <div className="cnt-text">
            <h3 className="sup-title">FAÇA A DIFERENÇA NA SUA EMPRESA</h3>
            <h2 className="title">
              Tenha um impacto positivo na vida de todos os seus colaboradores
            </h2>
            <div className="cnt-steps">
              <div className="group">
                <div className="icon">
                  <img src={IconStepCheck} alt="" />
                </div>
                <div className="step">Simplifica a rotina do RH</div>
              </div>
              <div className="group">
                <div className="icon">
                  <img src={IconStepCheck} alt="" />
                </div>
                <div className="step">Plano de saúde financeira</div>
              </div>
              <div className="group">
                <div className="icon">
                  <img src={IconStepCheck} alt="" />
                </div>
                <div className="step">Nenhum custo para a empresa</div>
              </div>
              <div className="group">
                <div className="icon">
                  <img src={IconStepCheck} alt="" />
                </div>
                <div className="step">Dashboards de acompanhamento </div>
              </div>
            </div>
          </div>
          <div className="cnt-form">
            <div className="bg-box-form">
              <div className="box-form">
                <div className="top-info">
                  <div className="box-title">Seja  parceira da Ali</div>
                  <div className="dots">
                    <img src={IconDots} alt="" />
                  </div>
                </div>
                <div id="my-hubspot-form" />
              </div>
            </div>
          </div>
        </div>
      </BePartner>
    </CntAboutUs>
  );
};

export default PrivacyPolicy;
