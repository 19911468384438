import styled from 'styled-components';

export const CntHeader = styled.div`
  background: #ffffff;
  position: sticky;
  z-index: 10;
  border-bottom: 1px solid #e4ecfd;
  top: 0;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.2s;
  &.hide {
    top: -90px;
  }
  .container {
    min-height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
    }
    .links {
      .link {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 130%;
        color: rgba(15, 32, 77, 0.7);
        margin: 0 10px;
        transition: color 0.1s ease-in-out;
        position: relative;
        &::after {
          transition: all 0.2s ease-in-out;
          content: ' ';
          position: absolute;
          width: 0px;
          height: 1px;
          background: #fe4059;
          bottom: -10px;
          left: 0;
        }
        &.is-active {
          color: #0f204d;
          &::after {
            width: 22px;
          }
        }
        &:hover {
          color: #6690f8;
        }
      }
      @media screen and (max-width: 1005px) {
        display: none;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      .login {
        display: flex;
        align-items: center;
        margin-right: 5.2rem;
        @media screen and (max-width: 1005px) {
          margin-right: 3rem;
        }
        .icon {
          display: flex;
          align-items: center;
          margin-right: 10px;
        }
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: #0f204d;
      }
      .account {
        width: 176px;
        height: 40px;
        border: 1px solid #fe4059;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: #0f204d;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: 1005px) {
          display: none;
        }
        &:hover {
          background: #fe4059;
          color: #ffffff;
        }
      }
    }
    .mobile {
      margin-right: 0.6rem;
      padding-left: 3rem;
      border-left: 1px solid #f1f0f5;
      display: none;
      @media screen and (max-width: 1005px) {
        display: block;
      }
    }
  }
`;
export const CntMenuMobile = styled.div`
  position: fixed;
  width: 100%;
  top: -100%;
  z-index: 6;
  background: white;
  min-height: 100%;
  transition: all 0.5s ease-in-out;
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
  opacity: 0;
  pointer-events: none;
  &.active {
    top: 88px;
    opacity: 1;
    pointer-events: initial;
  }
  .links {
    display: flex;
    flex-direction: column;
    a {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 150%;
      color: #0f204d;
      border-bottom: 1px solid #e4ecfd;
      padding: 2.15rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .btn {
    border: 1px solid #fe4059;
    box-sizing: border-box;
    border-radius: 8px;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-align: center;
    height: 40px;
    color: #0f204d;
    margin-top: 8.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
