import styled from 'styled-components';

export const Container = styled.div`
  * {
    font-size: 12px;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;

  img:first-child {
    padding-right: 2rem;
    border-right: 1px solid #C0C0C0;
    margin-right: 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #0F62FE;
  padding: 0rem 1rem;
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 5rem;
  z-index: 2;
  overflow: hidden;

  h1 {
    font-size: 18px;
    color: white;
    padding: 3rem;
  }
`;

export const BackgroundImage = styled.div`
  position: absolute;
  left: 0;
  /* bottom: 0; */
  width: 100%;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
  img {
    width: 70%;
    height: auto;
    object-fit: cover;
  }
`;
