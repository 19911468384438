import styled from 'styled-components';
import bgHero from '../../images/bg_hero_about_us.svg';
import iconHero from '../../images/icon_about_us_hero_persons.svg';
import BoxEffectBePartner from '../../images/bg_form_box.svg';
import BoxEffectMobile from '../../images/top_box_effect_mobile.svg';
import iconHeroCloud1 from '../../images/bg_hero_about_us_cloud1.svg';
import iconHeroCloud2 from '../../images/bg_hero_about_us_cloud2.svg';

export const CntAboutUs = styled.div`
  overflow: hidden;
`;
export const CntHeroAboutUs = styled.section`
  background-color: #f9fbfe;

  background-position: center bottom, center bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, cover;
  padding-top: 7.1rem;
  padding-bottom: 37rem;
  position: relative;
  overflow: hidden;
  .persons,
  .cloud1,
  .cloud2,
  .city {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    pointer-events: none;
  }
  .persons {
    background-image: url(${iconHero});
    background-position: center bottom;
    z-index: 4;
  }
  .city {
    background-image: url(${bgHero});
    background-position: center 70px;
    z-index: 1;
    background-size: cover;
  }
  .cloud1 {
    background-image: url(${iconHeroCloud1});
    background-position: 5% 50%;
    z-index: 2;
  }
  .cloud2 {
    background-image: url(${iconHeroCloud2});
    background-position: 95% 35%;
    z-index: 2;
  }

  .container {
    max-width: 861px;
    z-index: 5;
    position: relative;
    .up-title {
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .title {
      font-size: 4.6rem;
      text-align: center;
      margin-bottom: 2.8rem;
      @media screen and (max-width: 600px) {
        font-size: 3.2rem;
      }
    }

    .cnt-btn {
      display: flex;
      align-items: center;
      max-width: 557px;
      margin: 0 auto;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      .btn-default {
        margin-right: 3.6rem;
        font-size: 1.6rem;
        line-height: 2rem;
        @media screen and (max-width: 768px) {
          margin-right: 0;
          max-width: 100%;
          width: 100%;
          height: 62px;
        }
        img {
          margin-left: 6px;
        }
      }

      .desc {
        max-width: 241px;
        font-family: Space Grotesk;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 150%;
        color: #606f8e;
        text-decoration: underline;
        @media screen and (max-width: 768px) {
          margin-top: 2.9rem;
          text-align: center;
          text-decoration-line: underline;
        }
      }
    }
  }
`;
export const Policy = styled.section`
  padding-top: 11.9rem;
  padding-bottom: 14.4rem;
  .container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 968px) {
      flex-direction: column;
    }
    @media screen and (max-width: 450px) {
      padding-bottom: 55rem;
    }
    .square {
      width: 62px;
      height: 62px;
      position: absolute;
      bottom: -17.5rem;
      right: 1rem;
      background: #182d67;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      @media screen and (max-width: 968px) {
        display: none;
      }
    }
    .cnt-text {
      @media screen and (max-width: 968px) {
        max-width: 100%;
        margin: 0 auto;
      }
      .up-title {
        @media screen and (max-width: 968px) {
          text-align: center;
        }
      }

      .title {
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 140%;
        color: #343a40;
        max-width: 385px;
        margin: 1.5rem 0 2.3rem;
        @media screen and (max-width: 968px) {
          text-align: center;
          margin: 1.9rem auto 2.8rem;
        }
      }

      .desc {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 150%;
        color: #606f8e;
        margin-bottom: 1.5rem;
        @media screen and (max-width: 968px) {
          text-align: center;
          max-width: 462px;
          margin-bottom: 5.7rem;
        }
      }

      .desc-subtitle {
        font-family: Inter;
        font-style: normal;
        font-weight: Bold;
        font-size: 1.8rem;
        line-height: 150%;
        color: #606f8e;
        margin-bottom: 1.5rem;
        @media screen and (max-width: 968px) {
          text-align: center;
          max-width: 462px;
          margin-bottom: 5.7rem;
        }
      }

      .cnt-itens {
        @media screen and (max-width: 968px) {
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
          display: grid;
          grid-gap: 1.5rem;
        }
        .line {
          height: 1px;
          width: 279px;
          background: #f0f6ff;
          @media screen and (max-width: 968px) {
            display: none;
          }
        }
        .item {
          display: flex;
          /* border-bottom: 1px solid #f0f6ff; */
          padding-bottom: 20px;
          padding-top: 20px;

          &:last-of-type {
            border-bottom: none;
          }
          &:first-of-type {
            padding-top: 0px;
          }
          @media screen and (max-width: 968px) {
            width: 220px;
            padding-top: 0;

            padding-bottom: 15px;
            border-bottom: 1px solid #f0f6ff;
            &:last-of-type {
              border-bottom: 1px solid #f0f6ff;
            }
          }
          @media screen and (max-width: 450px) {
            margin: 0 auto;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 3.6rem;
            padding-top: 3.6rem;
            &:last-of-type {
              border-bottom: none;
            }
            * {
              text-align: center;
            }
          }
          .icon {
            margin-right: 2.1rem;
          }

          .txt {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 150%;
            color: #0f204d;
            @media screen and (max-width: 968px) {
              font-size: 1.4rem;
            }
            @media screen and (max-width: 450px) {
              font-size: 1.6rem;
            }
          }
        }
      }
    }

    .cnt-imgs {
      margin-top: 6.8rem;
      flex: 1;
      text-align: right;
      img {
        max-width: 100%;
      }
      @media screen and (max-width: 968px) {
        width: 100%;
        text-align: center;
      }
      @media screen and (max-width: 450px) {
        width: 700px;
        position: absolute;
        bottom: 0;
        right: 0;
        text-align: right;
      }
    }
  }
`;

export const BePartner = styled.section`
  min-height: 814px;
  padding-top: 7.7rem;
  position: relative;
  padding-bottom: 10rem;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    overflow: unset;
    margin-bottom: 500px;
    margin-top: 10rem;
  }
  @media screen and (max-width: 600px) {
    background-size: cover;
    margin-bottom: 350px;
    margin-top: 1rem;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
    .cnt-text {
      max-width: 586px;
      flex: 1;
      margin-right: 2.5rem;
      margin-top: 7rem;
      @media screen and (max-width: 900px) {
        margin: 0 auto;
      }
      .sup-title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 140%;
        letter-spacing: 0.1em;
        color: #3067f9;
        margin-bottom: 2rem;
        @media screen and (max-width: 600px) {
          font-size: 1.2rem;
          text-align: center;
        }
      }

      .title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 600;
        font-size: 6.2rem;
        line-height: 120%;
        color: #0f204d;
        padding-bottom: 8.2rem;
        border-bottom: 1px dashed rgba(15, 32, 77, 0.2);
        @media screen and (max-width: 900px) {
          font-size: 4.2rem;
          text-align: center;
        }
        @media screen and (max-width: 600px) {
          font-size: 2.2rem;
          text-align: center;
        }
      }

      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 150%;
        color: #606f8e;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
        padding-bottom: 5.8rem;
        @media screen and (max-width: 600px) {
          font-size: 1.4rem;
          text-align: center;
        }
      }

      .cnt-steps {
        flex: 1;
        margin-top: 5.8rem;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        display: grid;
        grid-gap: 3.2rem;
        margin-bottom: 3rem;
        @media screen and (max-width: 425px) {
          grid-gap: 3rem;
        }
        .group {
          display: flex;
          align-items: flex-start;
          .icon {
            margin-top: 3px;
            margin-right: 1.8rem;
          }
          .step {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 150%;
            color: #606f8e;
            max-width: 180px;
            @media screen and (max-width: 425px) {
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    .cnt-form {
      max-width: 447px;
      flex: 1;
      @media screen and (max-width: 900px) {
        margin: 5rem auto;
        max-width: 500px;
        width: 100%;
      }
      .bg-box-form {
        background: linear-gradient(
          118.34deg,
          rgba(255, 229, 233, 0) -11.88%,
          rgba(137, 191, 215, 0.807384) 59.56%,
          #acbeff 155.35%
        );
        backdrop-filter: blur(30px);
        border-radius: 8px;
        min-height: 600px;
        padding: 1.6rem 1.4rem;
        display: flex;
        flex-direction: column;
        position: relative;
        &::after {
          position: absolute;
          content: ' ';
          width: 125%;
          height: 125%;
          background: url(${BoxEffectBePartner}) no-repeat center right;
          background-size: revert;
          z-index: -1;
          bottom: -143px;
          left: -59px;
          @media screen and (max-width: 900px) {
            /* background: url(${BoxEffectMobile}) no-repeat center center; */
            /* background-size: contain;
            width: 100%;
            height: 100%;
            bottom: -251px;
            right: 0px; */
          }
        }
        @media screen and (max-width: 900px) {
          margin-bottom: -500px;
          width: 100%;
        }
        .box-form {
          background: #ffffff;
          border-radius: 8px;
          flex: 1;
          padding: 4.6rem 5.6rem;
          @media screen and (max-width: 400px) {
            padding: 2rem;
          }
          .top-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2.9rem;
            .box-title {
              font-family: Inter;
              font-style: normal;
              font-weight: bold;
              font-size: 1.6rem;
              line-height: 150%;
              color: #0f204d;
            }

            .dots {
              img {
              }
            }
          }

          form {
            .btn-default {
              height: 55px;
              width: 100%;
              max-width: unset;
              margin-top: 5.1rem;
              font-family: 'Space Grotesk';
              font-style: normal;
              font-weight: bold;
              font-size: 1.6rem;
              line-height: 130%;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .icon-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    position: absolute;
    bottom: 3.8rem;
    @media screen and (max-width: 900px) {
      display: none;
    }
    .mouse {
      margin-bottom: 1rem;
    }
  }
`;
