import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { Footer, SecondFooter } from './styles';
import AliLogo from '../../images/Logo-ali-2023.svg';

function LpFooter() {
  return (
    <>
      <Footer>
        <img src={AliLogo} alt="Ali logo" style={{ height: '40px' }} />
        {' '}
        A Ali tem o compromisso de proteger e respeitar sua privacidade
        e nós usaremos suas informações pessoais somente para administrar
        sua conta e fornecer os produtos e serviços que você nos solicitou.
        Ocasionalmente, gostaríamos de contatá-lo sobre os nossos produtos
        e serviços, também sobre outros assuntos que possam ser do seu interesse.
        Você pode cancelar o recebimento dessas comunicações quando quiser.
        Para obter mais informações sobre esse cancelamento,
        nossas práticas de privacidade e nosso compromisso em proteger e
        respeitar sua privacidade, confira nossa política de privacidade.
        Ao fornecer suas informações nessa página,
        você concorda em permitir que a Ali armazene e processe as
        informações pessoais enviadas acima para fornecer o conteúdo
        e serviços solicitados.
      </Footer>
      <SecondFooter>
        <h2>© 2024 Ali e Economizômetro® | Todos os direitos reservados.</h2>
        <h3>
          Saiba mais em
          {' '}
          <Link href="https://www.ali.com.vc" target="_blank">www.ali.com.vc</Link>
        </h3>
      </SecondFooter>
    </>
  );
}

export default withRouter(LpFooter);
