import React from 'react';

import { CntAd } from './styles';

const WarningAd = () => (
  <CntAd>
    <div className="container">
      <span>
        Atenção! Instituições Financeiras autorizadas pelo Banco Central NÃO EXIGEM PAGAMENTOS
        PRÉVIOS para qualquer tipo de concessão de empréstimos. Dessa forma, não deposite nenhum
        valor em contas de terceiros nem envie seus dados por e-mail para obter um empréstimo.
      </span>
      {' '}
      <a target="_blank" href="https://www.bcb.gov.br/detalhenoticia/15352/nota" rel="noreferrer">
        Saiba mais
      </a>
    </div>
  </CntAd>
);

export default WarningAd;
