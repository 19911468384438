import styled from 'styled-components';
import bgHero from '../../images/bg_hero_empresas.jpg';
import bgHeroMobile from '../../images/bg_hero_empresas_mobile.jpg';
import bgBenefits from '../../images/bg_benefits.jpg';
import BoxEffect from '../../images/bg_form_box.svg';
import BoxEffectMobile from '../../images/bg_form_box_mobile.svg';

export const CntEmpresas = styled.div`
  overflow: hidden;
`;
export const HeroEmpresas = styled.section`
  background: url(${bgHero});
  background-size: cover;
  min-height: 814px;
  padding-top: 7.7rem;
  position: relative;
  padding-bottom: 8rem;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    overflow: unset;
    margin-bottom: 500px;
  }
  @media screen and (max-width: 600px) {
    background: url(${bgHeroMobile}) center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
    .cnt-text {
      max-width: 586px;
      flex: 1;
      margin-right: 2.5rem;
      @media screen and (max-width: 900px) {
        margin: 0 auto;
      }
      .sup-title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 140%;
        letter-spacing: 0.1em;
        color: #48fbff;
        margin-bottom: 2rem;
        @media screen and (max-width: 600px) {
          font-size: 1.2rem;
          text-align: center;
        }
      }

      .title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 600;
        font-size: 4.6rem;
        line-height: 130%;
        color: #ffffff;
        margin-bottom: 2rem;
        @media screen and (max-width: 600px) {
          font-size: 2.2rem;
          text-align: center;
        }
      }

      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 150%;
        color: #e4ecfd;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
        padding-bottom: 5.8rem;
        @media screen and (max-width: 600px) {
          font-size: 1.4rem;
          text-align: center;
        }
      }

      .cnt-steps {
        flex: 1;
        margin-top: 5.8rem;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        display: grid;
        grid-gap: 3.2rem;
        .group {
          display: flex;
          align-items: flex-start;
          .icon {
            margin-top: 3px;
            margin-right: 1.8rem;
          }
          .step {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 150%;
            color: #e4ecfd;
          }
        }
      }
    }

    .cnt-form {
      max-width: 447px;
      flex: 1;
      @media screen and (max-width: 900px) {
        margin: 5rem auto;
        max-width: 500px;
        width: 100%;
      }
      .bg-box-form {
        background: linear-gradient(
          118.34deg,
          rgba(255, 229, 233, 0) -11.88%,
          rgba(137, 191, 215, 0.807384) 59.56%,
          #acbeff 155.35%
        );
        backdrop-filter: blur(30px);
        border-radius: 8px;
        min-height: 600px;
        padding: 1.6rem 1.4rem;
        display: flex;
        flex-direction: column;
        position: relative;
        &::after {
          position: absolute;
          content: ' ';
          width: 105%;
          height: 105%;
          background: url(${BoxEffect}) no-repeat center right;
          background-size: cover;
          z-index: -1;
          bottom: -53px;
          right: -47px;
          @media screen and (max-width: 900px) {
            background: url(${BoxEffectMobile}) no-repeat center center;
            bottom: -64px;
            background-size: contain;
            right: 0px;
          }
        }
        @media screen and (max-width: 900px) {
          margin-bottom: -500px;
          width: 100%;
        }
        .box-form {
          background: #ffffff;
          border-radius: 8px;
          flex: 1;
          padding: 4.6rem 5.6rem;
          @media screen and (max-width: 400px) {
            padding: 2rem;
          }
          .top-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2.9rem;
            .box-title {
              font-family: Inter;
              font-style: normal;
              font-weight: bold;
              font-size: 1.6rem;
              line-height: 150%;
              color: #0f204d;
            }

            .dots {
              img {
              }
            }
          }

          form {
            .btn-default,.actions  input[type="submit"] {
              height: 55px;
              width: 100%;
              max-width: unset;
              margin-top: 5.1rem;
              font-family: 'Space Grotesk';
              font-style: normal;
              font-weight: bold;
              font-size: 1.6rem;
              line-height: 130%;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .icon-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    position: absolute;
    bottom: 3.8rem;
    @media screen and (max-width: 900px) {
      display: none;
    }
    .mouse {
      margin-bottom: 1rem;
      animation: scrollDownMouse 6s ease 0s infinite normal none;

      @keyframes scrollDownMouse {
        10% {
          transform: translate(0, 5px) scale(1.01);
        }
        15% {
          transform: translate(0, 5px) scale(1.01);
        }
        25% {
          transform: translate(0, 0px);
        }
      }
    }
    .arrow {
      animation: scrollDown 6s ease 0s infinite normal none;

      @keyframes scrollDown {
        10% {
          transform: translate(0, 15px) scale(1.1);
        }
        15% {
          transform: translate(0, 15px) scale(1.1);
        }
        25% {
          transform: translate(0, 0px);
        }
      }
    }
  }
`;
export const DashBoards = styled.section`
  padding-top: 8rem;
  .container {
    .up-title {
      text-align: center;
      color: #3067f9;
    }

    .title {
      max-width: 587px;
      margin: 1.7rem auto 5.6rem;
      font-size: 3.2rem;
      text-align: center;
      font-weight: 500;
      b {
        display: block;
      }
      @media screen and (max-width: 600px) {
        font-size: 2.2rem;
      }
    }
  }

  .bot-img {
    background: #f5f8fd;
    background: linear-gradient(0deg, #f5f8fd 68%, #ffffff 68%);
    .container {
      display: flex;
      position: relative;
      /* overflow: hidden; */
      .rive {
        min-height: 500px;
        display: flex;
        @media screen and (max-width: 600px) {
          width: 100% !important;
          height: 100% !important; 
          min-width: 800px;
          canvas {

            object-fit: cover;
            position: absolute;
            top: 0;
            left: -25px;
          }
        }
      }
      .anchor {
        position: absolute;
        bottom: -30px;
        right: 15px;
      }
      img {
        max-width: 100%;
      }
    }
  }
`;
export const BestOption = styled.section`
  padding-top: 13.1rem;
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #e4ecfd;
    padding-bottom: 10.7rem;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      border: none;
    }
    .cnt-titles {
      max-width: 463px;
      @media screen and (max-width: 808px) {
        max-width: 293px;
      }
      @media screen and (max-width: 600px) {
        margin: 0 auto;
        padding-bottom: 4.3rem;
        border-bottom: 1px solid #e4ecfd;
        * {
          text-align: center;
        }
      }
      .up-title {
        margin-bottom: 2.2rem;
      }

      .title {
        margin-bottom: 2.2rem;
        @media screen and (max-width: 808px) {
          font-size: 2.2rem;
        }
      }

      .desc {
        position: relative;
        max-width: 293px;
        &.mob {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 1.4rem;
          line-height: 150%;
          color: #606f8e;
          margin: 0;
          margin-bottom: 4.3rem;
          @media screen and (min-width: 809px) {
            display: none;
          }
        }
        @media screen and (max-width: 808px) {
          margin-left: 25%;
        }
        .arrow {
          position: absolute;
          left: -120px;
          bottom: -28px;
          @media screen and (max-width: 808px) {
            bottom: -20px;
          }
        }

        span {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 174.5%;
          color: #606f8e;
        }
      }
    }
    .cnt-info {
      max-width: 554px;
      flex: 1;
      margin-left: 25px;
      @media screen and (max-width: 600px) {
        margin: 0 auto;
        padding-top: 9.2rem;
        * {
          text-align: center;
        }
      }
      .top-info {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 124.5%;
        color: #0f204d;

        display: flex;
        align-items: center;
        margin-bottom: 3.6rem;
        img {
          display: flex;
          margin-right: 1.6rem;
        }
        @media screen and (max-width: 808px) {
          margin-left: 2rem;
        }
        @media screen and (max-width: 600px) {
          margin: 0 auto;
          justify-content: center;
        }
      }
      ul {
        padding-left: 1.8rem;
        @media screen and (max-width: 808px) {
          padding-left: 0;
        }
      }
      .item {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 174.5%;
        color: #606f8e;
        margin-bottom: 2.5rem;
        padding-left: 0.5rem;
        @media screen and (max-width: 808px) {
          display: block;
        }
      }
    }
  }
`;
export const Benefits = styled.section`
  padding-top: 12.6rem;
  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
  .top {
    .title {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: normal;
      font-size: 3.2rem;
      line-height: 140%;
      color: #0f204d;
      margin-bottom: 5.3rem;
      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }

    .cnt-boxes {
      /* display: flex;
      flex-wrap: wrap;
      justify-content: space-between; */
      .swiper-slide {
        max-width: 384px;
      }
      .box {
        max-width: 384px;
        overflow: hidden;
        &:hover {
          .img {
            img {
              transform: scale(1.05) translateY(-4px);
            }
          }
        }
        .img {
          min-height: 214px;
          display: flex;
          justify-content: center;
          align-items: flex-end;

          border-radius: 18px 18px 0px 0px;
          &.green {
            background: #48fbff;
          }
          &.blue {
            background: #6690f8;
            align-items: center;
          }
          &.yellow {
            background: #fcfa5a;
          }
          img {
            transition: transform 0.2s ease-in-out;
          }
        }

        .cnt-text {
          padding: 3.8rem 4.8rem 4.2rem;
          text-align: center;
          background: #ffffff;
          border-radius: 0px 0px 18px 18px;
          @media screen and (max-width: 400px) {
            padding: 3.8rem 2.8rem 4.2rem;
            min-height: 260px;
          }
          .box-title {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 1.4rem;
            line-height: 140%;
            letter-spacing: 0.1em;
            color: #0f204d;
            margin-bottom: 1rem;
          }
          .box-text {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 150%;
            color: #606f8e;
          }
          .highlight {
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 126.7%;
            color: #fe4059;
            margin-top: 1.9rem;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              margin-left: 1.2rem;
            }
          }
        }
      }
    }
    .show-more {
      display: none;
      @media screen and (max-width: 768px) {
        display: block;
        margin-top: 4.6rem;
        text-align: center;
      }
    }
  }
  .benefits {
    background: url(${bgBenefits}) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 43.6rem;
    padding-bottom: 16.9rem;
    margin-top: -260px;
    @media screen and (max-width: 768px) {
      margin-top: -331px;
    }
    .bot {
      .up-title {
        text-align: center;
        margin-bottom: 2.3rem;
        color: #48fbff;
      }

      .title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 4.8rem;
        line-height: 130%;
        text-align: center;
        color: #ffffff;
        margin-bottom: 4.4rem;
        max-width: 880px;
        margin: 0 auto;
        @media screen and (max-width: 768px) {
          font-size: 3.2rem;
        }
        @media screen and (max-width: 600px) {
          font-size: 2.2rem;
        }
        b {
          font-weight: 700;
        }
      }

      .bot-cnt {
        margin-top: 4.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
        .btn-default {
          margin-right: 4.8rem;
          width: 270px;
          height: 64px;

          font-family: 'Space Grotesk';
          font-size: 1.6rem;
          line-height: 2rem;
          text-align: center;
          color: #e9ecef;
          @media screen and (max-width: 768px) {
            width: 100%;
            max-width: 463px;
            margin-bottom: 3.8rem;
            margin-right: 0;
          }
          @media screen and (max-width: 600px) {
            border: 1px solid #fe4059;
            background: transparent;
            font-weight: bold;
          }
        }

        .text {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 2rem;
          color: #ffffff;
          max-width: 240px;
          text-decoration-line: underline;
          display: none;
          @media screen and (max-width: 768px) {
            display: block;
            color: #c0cdec;
            text-align: center;
          }
        }
      }
    }
  }
`;
export const HowWorks = styled.section`
  padding-top: 16.4rem;
  margin-bottom: -3rem;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 768px) {
    padding-top: 7.7rem;
  }
  .container {
    padding-bottom: 7rem;
    border-bottom: 1px solid #e4ecfd;
    .up-title {
      text-align: center;
      margin-bottom: 0.7rem;
    }
    .title {
      text-align: center;
      margin-bottom: 6.4rem;
      font-size: 3.2rem;
      @media screen and (max-width: 600px) {
        font-size: 2.2rem;
      }
    }

    .cnt-boxes {
      margin-bottom: 4.7rem;
      .swiper-slide {
        max-width: 281px;
      }
      .box {
        background: #ffffff;
        border: 1px solid #e4ecfd;
        min-width: 288px;
        min-height: 376px;
        border-radius: 8px;
        padding: 3.5rem 2.6rem;
        display: flex;
        flex-direction: column;
        .icon {
          margin-bottom: 2.5rem;
          img {
          }
        }
        .box-title {
          font-family: Space Grotesk;
          font-style: normal;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 138%;
          color: #0f204d;
          margin-bottom: 2rem;
        }
        .box-text {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 174.5%;
          color: #606f8e;
        }
        .bottom {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          margin-top: auto;
          .info {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 150%;
            color: #6690f8;
          }
          .nr {
            position: absolute;
            right: 16px;
            bottom: 16px;
            background: linear-gradient(
              180deg,
              #f0f6ff 0%,
              rgba(240, 246, 255, 0) 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 8rem;
            line-height: 124.5%;
          }
        }
      }
    }
    .show-more {
      display: none;
      @media screen and (max-width: 768px) {
        display: block;
        margin-top: 4.6rem;
        text-align: center;
      }
    }
    .icon-arrow {
      max-width: 280px;
      display: flex;
      margin-left: auto;
      img {
        margin-right: 15px;
        margin-top: -18px;
      }

      span {
        max-width: 164px;
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 124.5%;
        color: #606f8e;
        display: inline-block;
      }
    }
  }
`;
