import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';

import { CntSliderCards } from './styles';
import ImgBotCardHouse from '../../images/icon_bot_card_house.svg';
import ImgBotCardCards from '../../images/icon_bot_card_cards.svg';
import ImgBotCardCash from '../../images/icon_bot_card_cash.svg';
import ImgBotCardCar from '../../images/icon_bot_card_car.svg';
import ImgBotCardHealth from '../../images/icon_bot_card_health.svg';
import ImgBotCardBag from '../../images/icon_bot_card_bag.svg';
import ImgBotCardCompany from '../../images/icon_bot_card_store.svg';
import ImgBotCardFamily from '../../images/icon_bot_card_family.svg';
import ImgBotCard1 from '../../images/icon_bot_card.svg';
import ImgBotCard2 from '../../images/icon_bot_card2.svg';
import ImgBotCard3 from '../../images/icon_bot_card3.svg';
import ImgBotCardTag from '../../images/icon_bot_card_tag.svg';
import IconArrowRight from '../../images/icon_arrow_right.svg';

SwiperCore.use([Navigation, Pagination]);

const SliderCards = () => (
  <CntSliderCards>
    <div className="container">
      <div className="top">
        <div className="infos">
          <h3 className="sub-title">DINHEIRO NA MÃO</h3>
          <h2 className="title">Qual o seu objetivo financeiro?</h2>
        </div>
      </div>
      <div className="swiper">
        <Swiper
          spaceBetween={32}
          slidesPerView="auto"
          pagination={{
            el: '.swiper-paginator-cards',
            type: 'fraction',
            renderFraction: (currentClass, totalClass) => (`
            <span class="${currentClass}"></span> 
            de 
            <span class="${totalClass}"></span>`),
          }}
          navigation={{
            nextEl: '.swiper-button-next-cards',
            prevEl: '.swiper-button-prev-cards',
          }}
        >
          <SwiperSlide>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="box" data-aos="fade-up">
              <div className="info">
                <div className="tag">INVESTIMENTO</div>
                <div className="box-title">Reformar ou melhorar a casa</div>
                <span className="btn-know-more">
                  Simule no WhatsApp
                  <img src={IconArrowRight} alt="" />
                </span>
              </div>
              <div className="bottom-icons red">
                <img src={ImgBotCardHouse} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="box" data-aos="fade-up">
              <div className="info">
                <div className="tag">DÍVIDAS</div>
                <div className="box-title">Pagar cartão de crédito rotativo</div>
                <span className="btn-know-more">
                  Simule no WhatsApp
                  <img src={IconArrowRight} alt="" />
                </span>
              </div>
              <div className="bottom-icons yel mtn">
                <img src={ImgBotCardCards} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="box" data-aos="fade-up" data-aos-delay="100">
              <div className="info">
                <div className="tag">INVESTIMENTO</div>
                <div className="box-title">Investir em educação e aprendizado</div>
                <span className="btn-know-more">
                  Simule no WhatsApp
                  <img src={IconArrowRight} alt="" />
                </span>
              </div>
              <div className="bottom-icons blu">
                <img src={ImgBotCard2} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="box" data-aos="fade-up">
              <div className="info">
                <div className="tag">DÍVIDAS</div>
                <div className="box-title">Pagar o cheque especial</div>
                <span className="btn-know-more">
                  Simule no WhatsApp
                  <img src={IconArrowRight} alt="" />
                </span>
              </div>
              <div className="bottom-icons gre mtn">
                <img src={ImgBotCard1} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="box" data-aos="fade-up">
              <div className="info">
                <div className="tag">DÍVIDA</div>
                <div className="box-title">Extra para acertar as dívidas</div>
                <span className="btn-know-more">
                  Simule no WhatsApp
                  <img src={IconArrowRight} alt="" />
                </span>
              </div>
              <div className="bottom-icons red">
                <img src={ImgBotCardCash} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="box" data-aos="fade-up">
              <div className="info">
                <div className="tag">INVESTIMENTO</div>
                <div className="box-title">Entrada no carro novo</div>
                <span className="btn-know-more">
                  Simule no WhatsApp
                  <img src={IconArrowRight} alt="" />
                </span>
              </div>
              <div className="bottom-icons yel mtn">
                <img src={ImgBotCardCar} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="box" data-aos="fade-up">
              <div className="info">
                <div className="tag">DÍVIDA</div>
                <div className="box-title">Dinheiro para emergências</div>
                <span className="btn-know-more">
                  Simule no WhatsApp
                  <img src={IconArrowRight} alt="" />
                </span>
              </div>
              <div className="bottom-icons blu mtn">
                <img src={ImgBotCardHealth} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="box" data-aos="fade-up">
              <div className="info">
                <div className="tag">INVESTIMENTO</div>
                <div className="box-title">Comprar bens de consumo</div>
                <span className="btn-know-more">
                  Simule no WhatsApp
                  <img src={IconArrowRight} alt="" />
                </span>
              </div>
              <div className="bottom-icons gre mtn">
                <img src={ImgBotCardBag} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="box" data-aos="fade-up">
              <div className="info">
                <div className="tag">DÍVIDA</div>
                <div className="box-title">Ajudar algum familiar</div>
                <span className="btn-know-more">
                  Simule no WhatsApp
                  <img src={IconArrowRight} alt="" />
                </span>
              </div>
              <div className="bottom-icons yel">
                <img src={ImgBotCardFamily} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="box" data-aos="fade-up">
              <div className="info">
                <div className="tag">INVESTIMENTO</div>
                <div className="box-title">Começar um novo negócio</div>
                <span className="btn-know-more">
                  Simule no WhatsApp
                  <img src={IconArrowRight} alt="" />
                </span>
              </div>
              <div className="bottom-icons red">
                <img src={ImgBotCardCompany} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="box" data-aos="fade-up" data-aos-delay="300">
              <div className="info">
                <div className="tag">DÍVIDA</div>
                <div className="box-title">Imprevistos de última hora</div>
                <span className="btn-know-more">
                  Simule no WhatsApp
                  <img src={IconArrowRight} alt="" />
                </span>
              </div>
              <div className="bottom-icons gre mtn">
                <img src={ImgBotCardTag} alt="" />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="box" data-aos="fade-up" data-aos-delay="200">
              <div className="info">
                <div className="tag">INVESTIMENTO</div>
                <div className="box-title">Comprar ou trocar de celular</div>
                <span className="btn-know-more">
                  Simule no WhatsApp
                  <img src={IconArrowRight} alt="" />
                </span>
              </div>
              <div className="bottom-icons blu">
                <img src={ImgBotCard3} alt="" />
              </div>
            </a>
          </SwiperSlide>
        </Swiper>
        <div className="navigation">
          <div className="swiper-paginator-cards" />
          <div className="swiper-button-prev-cards" />
          <div className="swiper-button-next-cards" />
        </div>
      </div>
    </div>
  </CntSliderCards>
);

export default SliderCards;
