import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AnimatedNumber from '@theankur/react-animated-numbers';

const AnimatedNumbers = ({ savings }) => {
  const [valueEco, setValueEco] = useState(Number(savings));
  const [valueDecEco, setValueDecEco] = useState(43);
  useEffect(() => {
    setTimeout(() => {
      setValueEco(valueEco + Math.floor(Math.random() * 1234));
      setValueDecEco(Math.floor(Math.random() * 99));
    }, 5000);
  }, [valueEco]);
  return (
    <>
      R$
      <AnimatedNumber
        fontStyle={{ fontFamily: 'Space Grotesk' }}
        animateToNumber={valueEco}
        includeComma
        config={{ tension: 89, friction: 40 }}
        animationType="calm"
        onStart={() => {
          const numbers = document.querySelector('#value-eco').querySelectorAll('div')[0];
          for (let index = 0; index < numbers.children.length; index += 1) {
            const element = numbers.children[index];
            if (element.textContent === ',') {
              element.textContent = '.';
            }
          }
        }}
      />
      <span className="tailcomma">,</span>
      <AnimatedNumber
        fontStyle={{ fontFamily: 'Space Grotesk' }}
        animateToNumber={valueDecEco}
        includeComma
        config={{ tension: 89, friction: 40 }}
        animationType="calm"
      />
    </>
  );
};
AnimatedNumbers.propTypes = {
  savings: PropTypes.number.isRequired,
};
export default AnimatedNumbers;
