import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  align-items: center;
  background-color: #05132A;
  z-index: 3;
  position: relative;
  color: white;
  font-size: 12px;
  font-weight: 300;
  padding: 10rem;

  a {
    color: white;
    font-size: 24px;

    b {
      font-size: 24px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 4rem;
    margin-bottom: 80px;
    align-items: center; 
    
    > div {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

  }

  @media (min-width: 800px) {
    flex-direction: column;

    > div {
      flex-direction: row;
    }
  }
`;

export const SecondFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 6rem;
  align-items: center;
  background-color: #071833;
  z-index: 3;
  position: relative;
  color: #ffffff;

  h2 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 300;
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;
