/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextTransition, { presets } from 'react-text-transition';
import { useLax } from 'use-lax';
import AnimatedNumber from '../../components/animatedNumber/AnimatedNumber';
import { getEconomia, getItemAmountByValue } from '../../helpers/functions';
// import AliBlogApi from '../../services/api';

import {
  CntHome,
  Hero,
  AboutUs,
  WhyUs,
  StartsNow,
  KnowUs,
} from './styles';
// import SliderBrands from '../../components/slider-brands';
// import Simulation from '../../components/simulation';
import SliderCards from '../../components/slider-cards';
import SliderDepositions from '../../components/slider-depositions';
import Faq from '../../components/faq';
import AliMarquee from '../../components/marquee';
import IconArrowRight from '../../images/icon_arrow_right_white.svg';
import FiveStar from '../../images/icon_5star.svg';
import IconCheck from '../../images/icon_check.svg';
import { ReactComponent as IconCar } from '../../images/icon_car.svg';
import { ReactComponent as IconSmart } from '../../images/icon_smart.svg';
import { ReactComponent as IconKey } from '../../images/icon_key.svg';
import { ReactComponent as IconPhone } from '../../images/icon_smartphone.svg';
import IconWhats from '../../images/icon_whats_red.svg';
import iconEnergyBlue from '../../images/icon_energy_blue.svg';
import iconEnergyPurple from '../../images/icon_energy_purple.svg';
import icon3Dots from '../../images/icon_3dots.svg';
import IconTab1 from '../../images/icon_tab_box1.svg';
import IconTab2 from '../../images/icon_tab_box2.svg';
import IconTab3 from '../../images/icon_tab_box3.svg';
import IconTab4 from '../../images/icon_tab_box4.svg';
import IconTab5 from '../../images/icon_tab_box5.svg';
import IconTab6 from '../../images/icon_tab_box6.svg';
import IconTab7 from '../../images/icon_tab_box7.svg';
import IconTab8 from '../../images/icon_tab_box8.svg';
import IconTab9 from '../../images/icon_tab_box9.svg';
import IconTab10 from '../../images/icon_tab_box10.svg';
import IconTab11 from '../../images/icon_tab_box11.svg';
import IconTab12 from '../../images/icon_tab_box12.svg';
import { ReactComponent as IconTabUser } from '../../images/icon_tab_user.svg';
import { ReactComponent as IconTabGlobe } from '../../images/icon_tab_globe.svg';
import IconStepCheck from '../../images/icon_step_check.svg';
import ImgStep1 from '../../images/img_step1.svg';
import ImgStep1Icon from '../../images/img_step1_icon.svg';
import ImgStep2 from '../../images/img_step2.svg';
import ImgStep2Icon from '../../images/img_step2_icon.svg';
import ImgStep3 from '../../images/img_step3.svg';
import ImgStep3Icon from '../../images/img_step3_icon.svg';
import ImgStep4 from '../../images/img_step4.svg';

const TEXTS = [
  ` ${getItemAmountByValue('carro')} carros`,
  ` ${getItemAmountByValue('tv')} Smart Tv's`,
  ` ${getItemAmountByValue('casa')} casas`,
  ` ${getItemAmountByValue('celular')} Smartphones`,
];
const Home = () => {
  useLax();
  // const [BlogCards, setBlogCards] = React.useState([]);
  const [FAQ] = React.useState([
    {
      title: 'Posso confiar na Ali?',
      text: 'Somos uma fintech de crédito que concorre com os bancos para conseguir oferecer juros realmente justos e com muito menos burocracia! De forma completamente transparente, queremos ajudar a vida financeira das pessoas, de verdade. Além disso, também temos como prioridade oferecer um processo 100% seguro.Um exemplo disso é o nosso protocolo SSL(Secure Socket Layer), que permite a troca de informações entre clientes e os servidores da Ali em total segurança, além de impossibilitar fraudes e outros riscos.Uma maneira de conferir essa proteção(no nosso site e em outros também!) é através do seu endereço, que deve começar com "https://", onde o S significa “secured” (seguro, em inglês).',
    },
    {
      title: 'Para quem é o crédito da Ali?',
      text: 'Ele é para todos os funcionários das empresas parceiras da Ali que estejam trabalhando na instituição e dentro da política de crédito! Sua empresa ainda não tem uma parceria com a gente? É só falar com o RH da sua empresa ou acessar nosso site e clicar em Seja um parceiro Ali! Esse processo é rápido, fácil, gratuito e sigiloso.',
    },
    {
      title: 'A Ali pede alguma taxa ou valor antecipado?',
      text: 'Não! A Ali não pede nenhuma taxa ou valor antecipado. Caso isso aconteça com você, fique atento, pois você pode estar sendo vítima de uma tentativa de fraude. Nesta situação, não prossiga com a conversa e entre em contato com a nossa equipe de atendimento assim que possível. Também é importante ressaltar que, no crédito consignado da Ali, nenhum valor é cobrado diretamente para você, já que as mensalidades são descontadas diretamente da sua folha de pagamento.',
    },
    {
      title: 'Esse benefício vai gerar mais trabalho para o RH?',
      text: 'Não! Nossa tecnologia automatiza todo o processo e a interface do dashboard é completamente intuitiva, sem necessidade de treinamento. Assim, facilitamos a navegação e não depositamos mais trabalho para o RH da sua empresa.',
    },
    {
      title: 'Como posso habilitar o benefício para os meus colaboradores?',
      text: 'Você pode entrar em contato através do nosso próprio site, clicando em Seja um parceiro da Ali, ou pelo nosso telefone (11) 4003-4458. Ah! E não se preocupe, esse processo é rápido, gratuito e muito fácil.',
    },
    {
      title: 'O que a Ali gera de vantagem para minha empresa?',
      text: 'Primeiro de tudo, fazemos o salário do seu colaborador valer mais, sem que a sua empresa gaste mais. Fazemos isso através de uma tecnologia proprietária da Ali que evita ou reduz a dívida dos colaboradores. Com isso, a melhora da produtividade e satisfação dos seus colaboradores com a empresa é nítida, além disso, com a ajuda da nossa solução é possível melhorar taxas de turnover e aumentar a retenção da equipe. O melhor de tudo é que não há custo ou fidelidade para a empresa e também elimina todo e qualquer retrabalho que o RH tenha, graças à automatização e integração com os principais sistemas de folha de pagamento.',
    },
  ]);
  const [tabOption, setTabOption] = React.useState(0);
  const [indexText, setIndexText] = React.useState(0);
  // const setBlogFormatted = (data) => {
  //   data.forEach((element) => {
  //     let title = '';
  //     element._embedded['wp:term'][0].forEach((el) => {
  //       if (title) {
  //         title = `${title} | ${el.name}`;
  //       } else {
  //         title = el.name;
  //       }
  //     });
  //     setBlogCards((prevState) => [...prevState, {
  //       section: 'Aprenda tudo sobre educação financeira',
  //       link: element.slug,
  //       urlImg: element.jetpack_featured_media_url,
  //       title,
  //       text: element.title.rendered,
  //     }]);
  //   });
  // };
  React.useEffect(() => {
    setTimeout(() => {
      if (indexText === TEXTS.length - 1) {
        setIndexText(0);
      } else {
        setIndexText((index) => index + 1);
      }
    }, 3000);
  }, [indexText]);
  const handleChange = (event, newValue) => {
    setTabOption(newValue);
  };
  const iconForText = () => {
    switch (indexText) {
      case 0:
        return <IconCar />;
      case 1:
        return <IconSmart />;
      case 2:
        return <IconKey />;
      case 3:
        return <IconPhone />;

      default:
        return <IconCar />;
    }
  };
  return (
    <CntHome>
      <Hero>
        <div className="container top">
          <div className="cnt-info">
            <h2 className="sup-title">LIBERDADE FINANCEIRA, DE VERDADE</h2>
            <h1 className="title">Você no controle do seu dinheiro</h1>
            <div className="action">
              <a href={`${process.env.REACT_APP_WEBAPP_URL}`} target="_blank" rel="noreferrer" className="btn-default">
                Simule aqui
                <img src={IconArrowRight} alt="" />
              </a>
              <Link to="/para-empresas" className="text">Ofereça para os colaboradores da sua empresa</Link>
            </div>
          </div>
          <div className="cnt-img">
            <div
              className="energy-img top lax"
              data-lax-translate-y="(vh) -250, (-elh) 50"
              data-lax-anchor="self"
            >
              <img src={iconEnergyBlue} alt="Icone" />
            </div>
            <div className="box">
              <div className="top-desc">
                <div className="text">Já economizamos para os nossos clientes:</div>
                <div className="dots">
                  <img src={icon3Dots} alt="Icone" />
                </div>
              </div>
              <div className="box-white">
                <div className="value" id="value-eco">
                  <AnimatedNumber savings={getEconomia()} />
                </div>
              </div>
            </div>
            <div className="energy-img bot lax" data-lax-translate-y="(vh) -100, (-elh) 75" data-lax-anchor="self">
              <img src={iconEnergyPurple} alt="" />
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="container">
            <div className="feedback">
              <div className="left">
                <div className="text">Avaliação dos usuários</div>
                <div className="star">
                  <img src={FiveStar} alt="" />
                  4,7 de 5
                </div>
              </div>
              <div className="right">
                <div className="text">Satisfação dos usuários</div>
                <div className="percentage">
                  94%
                  <img src={IconCheck} alt="" />
                </div>
              </div>
            </div>
            <div className="buy">
              <span>
                Dá para comprar
                <TextTransition
                  text={TEXTS[indexText]}
                  springConfig={presets.wobbly}
                  style={{ margin: '0 4px' }}
                  inline
                />
              </span>
              <div className="icon">{iconForText()}</div>
            </div>
          </div>
        </div>
      </Hero>
      <span className="about-tablet">
        <AboutUs>
          <div className="container">
            <div className="top-info">
              <div className="col left" data-aos="fade-right">
                <div className="line" />
                <h2 className="title">
                  Em parceria com as empresas oferecemos soluções
                  de bem-estar financeiro para os colaboradores.
                </h2>
              </div>
              <div className="col right" data-aos="fade-left">
                <div className="sub-title">PLANO DE SAÚDE FINANCEIRA</div>
                <div className="text">
                  Conquistar bem-estar financeiro não é só a ausência de dívidas.
                  É sobre ter poder de escolha sob seu próprio dinheiro.
                  Seja para fazer um investimento para o futuro, uma compra importante no presente,
                  ou reduzir aquela dívida do passado.
                  <b>
                    E sabe o melhor? Você nem precisa sair de casa!
                    <mark />
                    {' '}
                    Tudo é feito 100% online pelo WhatsApp da Ali.
                  </b>
                </div>
                <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="action">
                  <div className="icon">
                    <img src={IconWhats} alt="" />
                  </div>
                  Falar com a gente agora
                </a>
              </div>
            </div>
          </div>
        </AboutUs>
        {/* <Partners className="partners">
          <div className="container">
            <h3 className="sub-title">Vale a pena ser parceiro Ali </h3>
            <div className="cnt-info">
              <div className="box-info">
                <div className="highlight">40 mil</div>
                <div className="text">colaboradores que possuem o benefício</div>
              </div>
              <div className="arrow">
                <img src={ChevronBlue} alt="Icone" />
              </div>
              <div className="box-info">
                <div className="highlight">82%</div>
                <div className="text">de satisfação de quem usa o benefício Ali</div>
              </div>
              <div className="arrow">
                <img src={ChevronBlue} alt="Icone" />
              </div>
              <div className="box-info">
                <div className="highlight">1.521</div>
                <div className="text">empréstimos já foram concedidos</div>
              </div>
              <div className="arrow last">
                <img src={ChevronBlue} alt="Icone" />
              </div>
              <div className="box-btn">
                <Link to="/" className="btn-default">
                  <img src={iconLogo} alt="" />
                  SIMULAR CRÉDITO
                </Link>
                <div className="text">É fácil e grátis!</div>
              </div>
            </div>
          </div>
        </Partners> */}
      </span>
      {/* <SliderBrands /> */}
      <WhyUs>
        <div className="container">
          <div className="up-title" data-aos="fade-up">POR QUE FAZER EMPRÉSTIMO NA ALI?</div>
          <h2 data-aos="fade-up">Todos ganham com o crédito da Ali</h2>
          <div className="tabs">
            <AppBar position="static">
              <Tabs value={tabOption} onChange={handleChange} aria-label="icon simple tabs example">
                <Tab label="Para o colaborador" icon={<IconTabUser />} />
                <Tab label="Para a empresa" icon={<IconTabGlobe />} />
              </Tabs>
            </AppBar>
            <div
              role="tabpanel"
              hidden={tabOption !== 0}
              id={`simple-tabpanel-${0}`}
              aria-labelledby={`simple-tab-${0}`}
            >
              {tabOption === 0 && (
                <div className="cnt-tabs">
                  <div className="box delay1">
                    <div className="icon">
                      <img src={IconTab1} alt="Icone" />
                    </div>
                    <h3 className="box-title">Somos diferentes dos bancos</h3>
                    <div className="text">Queremos mudar o mercado de crédito e oferecer as melhores soluções para os brasileiros. </div>
                  </div>
                  <div className="box delay2">
                    <div className="icon">
                      <img src={IconTab2} alt="Icone" />
                    </div>
                    <h3 className="box-title">Nem precisa sair de casa</h3>
                    <div className="text">Tudo é feito de forma 100% digital e, claro, com muita segurança pelo nosso WhatsApp oficial da Ali. </div>
                  </div>
                  <div className="box delay3">
                    <div className="icon">
                      <img src={IconTab3} alt="Icone" />
                    </div>
                    <h3 className="box-title">As melhores taxas</h3>
                    <div className="text">Oferecemos crédito com taxas justas de verdade. Nada de custos adicionais ou abusivos.</div>
                  </div>
                  <div className="box delay4">
                    <div className="icon">
                      <img src={IconTab4} alt="Icone" />
                    </div>
                    <h3 className="box-title">O dinheiro entra na hora!</h3>
                    <div className="text">Isso mesmo! Logo após a aprovação de crédito o seu dinheiro cai no mesmo dia em sua conta.</div>
                  </div>
                  <div className="box delay5">
                    <div className="icon">
                      <img src={IconTab5} alt="Icone" />
                    </div>
                    <h3 className="box-title">100% de suporte no que precisar</h3>
                    <div className="text">Temos um time comprometido em te dar a melhor experiência em todas as etapas da sua jornada.</div>
                  </div>
                  <div className="box delay6">
                    <div className="icon">
                      <img src={IconTab6} alt="Icone" />
                    </div>
                    <h3 className="box-title">A gente te faz economizar</h3>
                    <div className="text">Com o Economizômetro você mede a economia gerada e a sua evolução financeira.</div>
                  </div>
                </div>
              )}
            </div>
            <div
              role="tabpanel"
              hidden={tabOption !== 1}
              id={`simple-tabpanel-${1}`}
              aria-labelledby={`simple-tab-${1}`}
            >
              {tabOption === 1 && (
                <div className="cnt-tabs">
                  <div className="box delay1">
                    <div className="icon">
                      <img src={IconTab7} alt="Icone" />
                    </div>
                    <h3 className="box-title">Zero burocracias</h3>
                    <div className="text">Chega de processos burocráticos na hora de contratar consignados com bancos tradicionais.</div>
                  </div>
                  <div className="box delay2">
                    <div className="icon">
                      <img src={IconTab8} alt="Icone" />
                    </div>
                    <h3 className="box-title">Nenhum custo para a empresa</h3>
                    <div className="text">A empresa tem custo zero para se conveniar a Ali. Sem letras miúdas ou surpresas.</div>
                  </div>
                  <div className="box delay3">
                    <div className="icon">
                      <img src={IconTab9} alt="Icone" />
                    </div>
                    <h3 className="box-title">Reports e acompanhamento</h3>
                    <div className="text">Uma plataforma rica em informações para o RH acompanhar toda a evolução dos seus colaboradores.</div>
                  </div>
                  <div className="box delay4">
                    <div className="icon">
                      <img src={IconTab10} alt="Icone" />
                    </div>
                    <h3 className="box-title">Oferta de crédito justa</h3>
                    <div className="text">Seus colaboradores terão crédito de forma rápida, fácil e digital com taxas realmente justas.</div>
                  </div>
                  <div className="box delay5">
                    <div className="icon">
                      <img src={IconTab11} alt="Icone" />
                    </div>
                    <h3 className="box-title">Processo 100% digital</h3>
                    <div className="text">Tudo é feito de forma 100% digital e, claro, com muita segurança pelo WhatsApp oficial da Ali.</div>
                  </div>
                  <div className="box delay6">
                    <div className="icon">
                      <img src={IconTab12} alt="Icone" />
                    </div>
                    <h3 className="box-title">Benefício, de verdade</h3>
                    <div className="text">Um benefício que economiza até 2 salários do seu colaborador? Com a Ali sua equipe tem benefícios de verdade!</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </WhyUs>
      <SliderCards />
      <StartsNow>
        <div className="container">
          <div className="up-title" data-aos="fade-up">
            COMECE AGORA
          </div>
          <h2 className="title" data-aos="fade-up" data-aos-delay="100">Como funciona tudo isso?</h2>
          <div className="cnt-info reverse">
            <div className="img">
              <img src={ImgStep1} alt="Ilustração" />
              <img src={ImgStep1Icon} alt="Ilustração" className="icon-step lax" data-lax-translate-y="(vh) 0, (-elh) -150" data-lax-anchor="self" />
            </div>
            <div className="text">
              <div className="step">1º PASSO</div>
              <h3 className="text-title">Para começar, informe o quanto você precisa </h3>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>Passe o quanto você precisa e defina a quantidade de parcelas;</span>
              </div>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>Informe seu CPF;</span>
              </div>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>Revise a sua simulação;</span>
              </div>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>Valide o token enviado em seu celular para continuar;</span>
              </div>
              <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="btn-default">
                Simule no WhatsApp
                <img src={IconArrowRight} alt="" />
              </a>
            </div>
          </div>
          <div className="cnt-info">
            <div className="text">
              <div className="step">2º PASSO</div>
              <h3 className="text-title">Confirme alguns dados</h3>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>Veja se sua empresa é conveniada;</span>
              </div>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>
                  Faça a confirmação do seu cargo;
                </span>
              </div>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>Escolha o motivo do crédito;</span>
              </div>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>Veja seu limite pré-aprovado.</span>
              </div>
              <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="btn-default">
                Simule no WhatsApp
                <img src={IconArrowRight} alt="" />
              </a>
            </div>
            <div className="img">
              <img src={ImgStep2} alt="Ilustração" />
              <img src={ImgStep2Icon} alt="Ilustração" className="icon-step2 lax" data-lax-translate-y="(vh) 0, (-elh) 150" data-lax-anchor="self" />
            </div>
          </div>
          <div className="cnt-info reverse setp3">
            <div className="img">
              <img src={ImgStep3} alt="Ilustração" />
              <img src={ImgStep3Icon} alt="Ilustração" className="icon-step3 lax" data-lax-translate-y="(vh) 0, (-elh) -100" data-lax-anchor="self" />
            </div>
            <div className="text">
              <div className="step">3º PASSO</div>
              <h3 className="text-title">Agora é só escolher o documento</h3>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>Preencha os dados do seu documento escolhido (CNH ou RG);</span>
              </div>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>
                  Informe o seu endereço residencial e tire uma foto do comprovante de residência;
                </span>
              </div>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>
                  Comprove seu vínculo empregatício e envie uma foto do comprovante de trabalho;
                </span>
              </div>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>
                  Preencha seus dados bancários para depósito do empréstimo;
                </span>
              </div>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>
                  Por fim, tire uma selfie com seu documento.
                </span>
              </div>
              <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="btn-default">
                Simule no WhatsApp
                <img src={IconArrowRight} alt="" />
              </a>
            </div>
          </div>
          <div className="cnt-info">
            <div className="text">
              <div className="step">4º PASSO</div>
              <h3 className="text-title">Pronto! O dinheiro irá cair em até 24 horas</h3>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>
                  Analisaremos o seu pedido de crédito e, através do nosso WhatsApp,
                  você poderá acompanhar o andamento da análise;
                </span>
              </div>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>
                  Revise a sua solicitação de crédito. Você ainda poderá comparar a sua dívida
                  antes da Ali e o quanto você está economizando no contrato atual;
                </span>
              </div>
              <div className="info">
                <div className="icon">
                  <img src={IconStepCheck} alt="Icone Check" />
                </div>
                <span>
                  É hora de assinar o contrato (tudo feito de forma 100% digital) e após a
                  aprovação do seu crédito o dinheiro cai em conta até 24 horas.
                </span>
              </div>
              <a href={`${process.env.REACT_APP_WHATSAPP_URL}`} target="_blank" rel="noreferrer" className="btn-default">
                Simule no WhatsApp
                <img src={IconArrowRight} alt="" />
              </a>
            </div>
            <div className="img">
              <img src={ImgStep4} alt="Ilustração" />
            </div>
          </div>
        </div>
      </StartsNow>
      <AliMarquee />
      <KnowUs>
        <div className="container">
          <h3 className="up-title" data-aos="fade-up">CONHEÇA A ALI</h3>
          <h2 className="title" data-aos="fade-up" data-aos-delay="100">Uma aliada para todos os brasileiros</h2>
          <div className="text" data-aos="fade-up" data-aos-delay="200">A Ali nasceu em 2018 com o objetivo de ser a sua aliada financeira. Oferecemos crédito justo, de verdade, e temos o compromisso em efetivamente reduzir o endividamento dos brasileiros. Facilitamos o processo burocrático e personalizamos o crédito para que cada vez mais pessoas alcancem seus sonhos e objetivos.</div>
        </div>
        {/* <div className="bottom-mob">
          <span>
            UM TIME 100% EMPENHADO EM TRANSFORMAR A VIDA FINANCEIRA
          </span>
        </div>
        <div className="team">
          <div className="team-desc" data-aos="fade-in" data-aos-delay="400">UM TIME 100% EMPENHADO EM</div>
          <div className="team-imgs">
            <img src={ImgAvatar1} alt="Avatar" data-aos="fade-in" data-aos-delay="300" />
            <img src={ImgAvatar2} alt="Avatar" data-aos="fade-in" data-aos-delay="200" />
            <img src={ImgAvatar3} alt="Avatar" data-aos="fade-in" data-aos-delay="100" />
            <img src={ImgAvatar4} alt="Avatar" data-aos="fade-in" />
            <img src={ImgAvatar5} alt="Avatar" data-aos="fade-in" data-aos-delay="100" />
            <img src={ImgAvatar6} alt="Avatar" data-aos="fade-in" data-aos-delay="200" />
            <img src={ImgAvatar7} alt="Avatar" data-aos="fade-in" data-aos-delay="300" />
          </div>
          <div className="team-desc" data-aos="fade-in" data-aos-delay="400">TRANSFORMAR A VIDA FINANCEIRA</div>
        </div> */}
      </KnowUs>
      <SliderDepositions />
      <Faq faqAccordion={FAQ} />
      {/* <Simulation blogCards={BlogCards} /> */}
    </CntHome>
  );
};

export default Home;
