import styled from 'styled-components';
import bgHero from '../../images/bg_faq_hero.jpg';

export const CntPageFaq = styled.div`
  .cnt-faq-page {
    padding-top: 0;
    background: white;
    position: relative;
    z-index: 1;
    padding-bottom: 0;
    @media screen and (max-width: 800px) {
      .cnt-faq {
        padding: 0;
      }
    }
    &:before {
      background: rgb(249 251 254);
      content: ' ';
      width: 30%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
      @media screen and (max-width: 800px) {
        background: white;
      }
    }
    .faq-title {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;
      font-size: 3.2rem;
      line-height: 140%;
      color: #0f204d;
      padding-left: 3.9rem;
      margin-bottom: 5.4rem;
      &:not(:first-of-type) {
        margin-top: 25rem;
      }
      @media screen and (max-width: 800px) {
        padding-left: 0;
        text-align: center;
      }
    }
    .text {
      position: sticky;
      top: 0;
      height: 100%;
      flex: 1;
      padding-bottom: 5rem;
      @media screen and (max-width: 800px) {
        max-width: 100%;
        z-index: 2;
        background: white;
        margin: 0;
        padding-bottom: 0;
      }
      .up-title {
        margin-top: 12.4rem;
        @media screen and (max-width: 800px) {
          display: none;
        }
      }
      .cnt-itens {
        margin-top: 5.2rem;
        @media screen and (max-width: 800px) {
          display: flex;
          border-bottom: 1px solid #e4ecfd;
          max-width: 100%;
          margin-top: 1.2rem;
          overflow: scroll;
          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }
        }
        .item {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e4ecfd;
          padding-bottom: 2.5rem;
          padding-top: 2.5rem;
          cursor: pointer;
          @media screen and (max-width: 800px) {
            padding-right: 1rem;
            border: none;
            min-width: 215px;
            justify-content: center;
          }
          &:last-of-type {
            border: none;
          }
          .icon {
            display: flex;
            margin-right: 1.8rem;

            svg {
              * {
                transition: all 0.2s ease;
              }
            }
          }
          .faq-text {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 150%;
            color: #0f204d;
            opacity: 0.25;
            transition: all 0.2s ease;
          }
          .icon-btn {
            margin-left: auto;
            border: 1px solid rgba(15, 32, 77, 0.25);
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: all 0.2s ease;
            @media screen and (max-width: 800px) {
              display: none;
            }
            svg {
              * {
                transition: all 0.2s ease;
              }
            }
          }
          &:hover,
          &.active {
            .icon-btn {
              background: #fe4059;
              border: 1px solid #fe4059;
              svg {
                g {
                  opacity: 1;
                  rect {
                    fill: #fff;
                    stroke: #fff;
                  }
                }
              }
            }
            .icon {
              svg {
                * {
                  opacity: 1;
                }
                circle {
                  fill: #3067f9;
                }
              }
            }
            .faq-text {
              opacity: 1;
            }
          }
        }
      }
    }
    .faq {
      background: rgb(249 251 254);
      padding: 0rem 3.9rem 15.8rem;
      position: relative;
      @media screen and (max-width: 800px) {
        padding: 0rem 0rem 15.8rem;
        background: transparent;
      }
      .mt {
        @media screen and (max-width: 800px) {
          background: rgb(249 251 254);
          &:nth-child(odd) {
            background: white;
          }
        }
        &:first-of-type {
          padding-top: 12.4rem;
        }
        &:not(:first-of-type) {
          margin-top: 14rem;
          padding-top: 11rem;
          @media screen and (max-width: 800px) {
            margin-top: 3rem;
          }
        }
      }
    }
  }
`;
export const FaqHero = styled.section`
  background: url(${bgHero}) no-repeat center top;
  background-size: cover;
  min-height: 468px;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    text-align: center;
    .up-title {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 140%;
      letter-spacing: 0.1em;
      color: #48fbff;
      margin-bottom: 28px;
    }

    .title {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: bold;
      font-size: 6.2rem;
      line-height: 120%;
      color: #ffffff;
      @media screen and (max-width: 768px) {
        font-size: 4.6rem;
      }
      @media screen and (max-width: 600px) {
        font-size: 3.2rem;
      }
    }

    .cnt-action {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 2.8rem;
      justify-content: center;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-top: 4.4rem;
      }
      .btn-default {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 124.5%;
        color: #ffffff;

        width: 209px;
        height: 62px;
        border-radius: 8px;
        margin-right: 4.9rem;
        @media screen and (max-width: 768px) {
          width: 100%;
          max-width: 75%;
          margin-right: 0;
        }
      }

      .action {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 124.5%;
        color: #ffffff;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          margin-top: 4.4rem;
        }
        img {
          margin-left: 7px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 1px solid rgba(252, 115, 132, 0.3);
          padding: 9px;
          transform: rotate(-45deg);
        }
      }
    }
  }
`;
