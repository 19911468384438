import styled from 'styled-components';

export const BackgroundImage = styled.div`
  position: absolute;
  height: 100%;
  right: 0;
  width: 90%;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
  transform: translateY(-60%);

  img {
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export const WithAli = styled.div`
  width: 100%;
  background-color: #0F62FE;
  padding: 5rem 2rem;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 30px;
    color: white;
  }

  div {
    margin-top: 4rem;
    padding: 1rem 3rem;
    border: 1px solid white;
    border-radius: 40px;
    font-size: 18px;
    color: white;
  }
`;

export const EconomizometroHeader = styled.div`
  width: 100%;
  background-color: white;
  padding: 5rem 2rem;

  > div:first-child {
    background-color: black;
    border-radius: 20px;
    padding: 6rem 1rem;
  }

  h1 {
    color: white;
    font-size: 32px;

    b {
      color: #0F62FE;
      font-size: 32px;
    }
  }

  @media (min-width: 790px) {
    padding: 5rem 4rem;

    > div:first-child {
      background-color: black;
      border-radius: 20px;
      padding: 8rem 4rem;
    }
  }
`;

export const EconomizometroContent = styled.div`
  width: 100%;
  background-color: #F6F7FB;
  padding: 3rem 4rem;
  margin-top: -200px;

  > div {
    border-radius: 20px;
    padding: 5rem 2rem;
  }

  > div:first-child {
    width: 100%;
    z-index: 2;
    position: relative;
  }

  h1 {
    color: black;
    font-size: 32px;

    b {
      color: #0F62FE;
      font-size: 32px;
    }
  }

  p {
    font-size: 14px;
  }

  @media (min-width: 790px) {
    margin-top: 0;

    > div:first-child {
      width: 50%;
      z-index: 2;
      position: relative;
    }

    > div {
      border-radius: 20px;
      padding: 5rem 4rem;
    }
  }
`;

export const ShortcutBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  @media (min-width: 890px) {
    width: 45%;
  }

  @media (min-width: 980px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const Shortcut = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid #0F62FE;
  border-radius: 20px;
  padding: 3rem;
  z-index: 2;

  > div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }

  svg {
    background-color: #0F62FE;
    padding: 1.1rem;
    border-radius: 10px;
    color: white;
    font-size: 52px;
  }

  h2 {
    color: #0F62FE;
    font-size: 24px;
  }

  @media (min-width: 500px) {
    > div {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
      align-items: center;
    }
  }
`;

export const DoubtsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: black;
  padding: 8rem;
  position: relative;
  z-index: 2;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    color: white;
    font-size: 28px;
  }

  p {
    color: white;
    font-size: 16px;
  }
`;

export const MockupBox = styled.div`
  display: flex;
  position: relative;
  z-index: 2;

  img {
    height: 300px;
    display: flex;
    position: relative;
  }

  @media (min-width: 500px) {
      img {
      height: 600px;
      display: flex;
      position: relative;
    }
  }

  @media (min-width: 790px) {
    display: flex;
    width: 90%;
    position: absolute;

    img {
      height: 700px;
      top: -210px;
      display: flex;
      position: relative;
      right: 0;
      margin-left: auto;
    }
  }
`;

export const Button = styled.a`
  display: flex;
  width: 80%;
  padding: 3rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #0F62FE;
  outline: none;
  border-radius: 50px;
  color: white;
  font-weight: 400;
  font-size: 18px !important;
  border: none;
  cursor: pointer;
  margin-top: 3rem;

  @media (min-width: 890px) {
    width: 40%;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  background-color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;

  img:first-child {
    padding-right: 2rem;
    border-right: 1px solid #C0C0C0;
    margin-right: 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #0F62FE;
  padding: 0rem 1rem;
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: auto;
  justify-content: space-evenly;
  gap: 2rem;
  align-items: center;
  text-align: center;
  padding: 1rem 2rem 1rem 1rem;;
  z-index: 2;

  @media (min-width: 800px) {
    padding: 2rem 4rem 2rem 1rem;
    flex-direction: row;
    height: 500px;
    gap: 3rem;
  }
`;

export const InfoMiddle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: flex-start;
  text-align: left;
  align-items: flex-start;
  padding: 1rem; 
  width: 100%;

  h1 {
    font-size: 38px;
    color: white;
  }

  h2 {
    font-size: 30px;
    color: white;
  }

  p {
    font-size: 18px;
    color: white;
    margin: 0;

    strong {
      font-size: 24px;
      font-weight: 500;
    }
  }

  @media (min-width: 800px) {
    width: 50%;
    padding: 1rem; 
  }
`;

export const FormBox = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

  @media (min-width: 800px) {
    width: 40%;
    top: 80px;
    position: relative;
  }

  @media (min-width: 1366px) {
    width: 25%;
  }
`;

export const KnowAli = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  padding: 4rem;
  background-color: white;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 38px;
    color: #0F62FE;
    width: 50%;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  h2 {
    font-size: 18px;
    color: black;
    font-weight: 400;
    text-align: left;

    b {
      font-size: 18px;
      font-weight: 600;
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
    margin-top: 120px;

    h1 {
      width: 30%;
    }
  }
`;

export const VideoBox = styled.div`
  width: 100%;
  margin: auto;
  background-color: #000;
  overflow: hidden;
  height: 720px;
`;

export const StyledVideo = styled.div`
  width: 100%;
  height: 100%;
  
  iframe {
    width: 100%;
    height: 100%;
  }
`;

export const When = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  z-index: 2;

  h2 {
    font-size: 24px;
  }
`;

export const Megaphone = styled.a`
  display: none;

  @media (min-width: 980px) {
    display: flex;
    height: 120px;
    z-index: 1;

    img {
      display: flex;
      height: 180px;
      position: absolute;
      bottom: 30px;
      left: -160px;
    }
  }
`;
