import React from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Rive from 'rive-react';

import { CntSimulation } from './styles';
import chevronRight from '../../images/icon_right_chevron.svg';
import iconEnergyBlue from '../../images/icon_energy_blue.svg';
import iconEnergyPurple from '../../images/icon_energy_purple.svg';
import BlogCard from '../blog-card';
import AnimationHero from '../../animations/573-1143-alicredito1.riv';

const Simulation = ({ blogCards }) => (
  <CntSimulation hasBlog={blogCards.length > 0}>

    {blogCards.length > 0
      && (
        <>
          <div className="container cnt-blog">
            <div className="top">
              <h4 className="sup-title">BLOG ALI </h4>
              <div className="cnt-top">
                <h3 className="title">{blogCards[0].section ? blogCards[0].section : ''}</h3>
                <Link to="/blog" className="go-blog ">
                  Veja mais no blog
                  <img src={chevronRight} alt="" />
                </Link>
              </div>
            </div>
            <div className="swiper">
              <Swiper
                spaceBetween={16}
                slidesPerView="auto"
              >
                {blogCards.slice(0, 3).map((el, index) => (
                  <SwiperSlide key={nanoid(5)}>
                    <BlogCard
                      link={el.link}
                      urlImg={el.urlImg}
                      title={el.title}
                      text={el.text}
                      data-aos="fade-up"
                      data-aos-delay={`${index}00`}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <Link to="/blog" className="go-blog mob ">
              Ver mais do blog
              <img src={chevronRight} alt="" />
            </Link>
          </div>
        </>
      )}
    <div className="container cnt-simu">

      <div className="text">
        <h3 className="sup-title">
          Parceria, de verdade
        </h3>
        <h2 className="title">Não deixe o seu time no vermelho</h2>
        <h4 className="text-desc">
          Faça o salário da sua equipe valer mais sem
          gastar mais por isso! É fácil e
          <b>leva menos de 2 minutos.</b>
        </h4>
        <Link to="/para-empresas" className="btn-default">
          Seja um parceiro Ali
        </Link>
      </div>
      <div className="imgs">
        <img className="first lax" data-lax-translate-y="(vh) 250, (-elh) 400" data-lax-anchor="self" src={iconEnergyPurple} alt="" />
        <Rive src={AnimationHero} className="rive" />
        <img className="last lax" data-lax-translate-y="(vh) -50, (-elh) 150" data-lax-anchor="self" src={iconEnergyBlue} alt="" />
      </div>
    </div>
  </CntSimulation>
);
Simulation.propTypes = {
  blogCards: PropTypes.arrayOf(PropTypes.shape({
    section: PropTypes.string,
    link: PropTypes.string.isRequired,
    urlImg: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
};
Simulation.defaultProps = {
  blogCards: [],
};

export default Simulation;
