import styled from 'styled-components';
import BgHero from '../../images/bg_hero.jpg';
import BgHeroRight from '../../images/bg_hero_right.svg';
import BoxEffect from '../../images/top_box_effect.svg';
import BoxEffectMobile from '../../images/top_box_effect_mobile.svg';
import BgKnowUs from '../../images/img_bg_knowus.jpg';

export const CntHome = styled.div`
  background: white;
  margin: 0 auto;
  overflow: hidden;
  .about-tablet {
    @media screen and (max-width: 900px) and (min-width: 601px) {
      position: relative;
      display: flex;
      border-top: 1px solid #e4ecfd;
      border-bottom: 1px solid #e4ecfd;
      .top-info {
        flex-direction: column;
      }
      .partners {
        border: none;
        padding-bottom: 35rem;
        @media screen and (max-width: 700px) {
          padding-bottom: 45rem;
        }
        @media screen and (max-width: 630px) {
          padding-bottom: 55rem;
        }
        .sub-title {
          max-width: 200px;
          margin-top: 3rem;
        }
        .cnt-info {
          display: flex;
          flex-direction: column;
          .box-btn {
            position: absolute;
            bottom: 9.8rem;
            left: 0;
            width: 100%;
            .btn-default {
              max-width: 700px;
              width: 95%;
              margin: 0 auto;
            }
          }
          .arrow {
            img {
              transform: rotate(90deg);
            }
            text-align: left !important;
            margin: 0 !important;
            &.last {
              display: none;
            }
          }
        }
      }
    }
  }
`;
export const Hero = styled.section`
  background: url(${BgHero}) center center no-repeat;
  min-height: 759px;
  background-size: cover;
  @media screen and (max-width: 900px) {
    height: 710px;
    min-height: unset;
    margin-bottom: 300px;
  }
  @media screen and (max-width: 600px) {
    height: 620px;
  }
  .top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .cnt-info {
      max-width: 593px;
      @media screen and (max-width: 1023px) {
        max-width: 85%;
        margin: 12.9rem auto 5rem;
        text-align: center;
      }
      @media screen and (max-width: 600px) {
        margin: 7.7rem auto 5rem;
      }
      .sup-title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 130%;
        letter-spacing: 0.1em;
        color: #3bf9fe;
        margin-bottom: 3.6rem;
      }
      .title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: bold;
        font-size: 7.2rem;
        line-height: 115%;
        color: #ffffff;
        margin-bottom: 3.6rem;
        @media screen and (max-width: 900px) {
          font-size: 6.2rem;
        }
        @media screen and (max-width: 600px) {
          font-size: 3.2rem;
        }
      }

      .action {
        display: flex;
        align-items: center;
        /* flex-wrap: wrap; */
        @media screen and (max-width: 1023px) {
          justify-content: center;
        }
        @media screen and (max-width: 900px) {
          flex-direction: column;
        }
        .btn-default {
          font-size: 1.6rem;
          line-height: 2rem;
          min-width: 250px;

          img {
            margin-left: 5.5px;
          }
        }
        .text {
          margin-left: 3.5rem;
          /* flex: 1; */
          width: 241px;
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 150%;
          color: #c0cdec;
          text-decoration: underline;
          @media screen and (max-width: 900px) {
            margin-top: 5.3rem;
            margin-left: 0;
          }
        }
      }
    }

    .cnt-img {
      background: url(${BgHeroRight}) top right no-repeat;
      min-height: 625px;
      flex: 1;
      margin-right: -10rem;
      margin-left: 13rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: 1175px) {
        margin-left: 5rem;
      }
      @media screen and (max-width: 1045px) {
        margin-right: -27rem;
      }
      @media screen and (max-width: 1023px) {
        margin: 0 2rem;
        width: 100%;
        flex: unset;
        background: unset;
        min-height: unset;
      }
      @media screen and (max-width: 600px) {
        margin: 0;
      }
      .energy-img {
        @media screen and (max-width: 1023px) {
          display: none;
        }
        &.top {
          margin-bottom: 7.1rem;
          margin-left: auto;
          margin-right: 100px;
          max-width: 72px;
          max-height: 72px;
          position: relative;
          &::after {
            position: absolute;
            content: ' ';
            background: linear-gradient(
              290.82deg,
              #3bf9fe 2.94%,
              rgba(48, 103, 249, 0) 70.85%
            );
            backdrop-filter: opacity(0.5);
            opacity: 0.5;
            width: 54px;
            height: 54px;
            border-radius: 50%;
            right: 5px;
            bottom: 5px;
          }
          img {
            max-width: 100%;
          }
        }
        &.bot {
          margin-top: 5.9rem;
          max-width: 72px;
          max-height: 72px;
          margin-left: 5rem;
          position: relative;
          &::after {
            position: absolute;
            content: ' ';
            background: linear-gradient(
              290.82deg,
              #c899ff 2.94%,
              rgba(202, 0, 255, 0) 70.85%
            );
            backdrop-filter: opacity(0.5);
            opacity: 0.5;
            width: 54px;
            height: 54px;
            border-radius: 50%;
            right: 5px;
            bottom: 5px;
          }
          img {
            max-width: 100%;
          }
        }
      }

      .box {
        background: linear-gradient(
          118.34deg,
          rgba(255, 229, 233, 0) -11.88%,
          rgba(137, 191, 215, 0.807384) 59.56%,
          #acbeff 155.35%
        );
        backdrop-filter: blur(30px);
        border-radius: 8px;
        max-width: 493px;
        min-height: 197px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        position: relative;
        @media screen and (max-width: 1080px) {
          max-width: 400px;
        }
        @media screen and (max-width: 1023px) {
          margin: 35px auto;
          .box-white {
            padding: 0 2.5rem;
          }
        }
        @media screen and (max-width: 900px) {
          max-width: 450px;
        }
        @media screen and (max-width: 600px) {
          width: 100%;
        }
        &::after {
          position: absolute;
          content: ' ';
          width: 100%;
          height: 100%;
          background: url(${BoxEffect}) no-repeat center right;
          z-index: -1;
          bottom: -26px;
          right: -26px;
          border-radius: 8px;
          @media screen and (max-width: 900px) {
            background: url(${BoxEffectMobile}) no-repeat center center;
            bottom: -39px;
            background-size: contain;
            right: 0px;
          }
        }
        .top-desc {
          margin-bottom: 1.1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .text {
            margin-left: 1.9rem;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 150%;
            color: #ffffff;
          }

          .dots {
            margin-right: 1.1rem;
            @media screen and (max-width: 600px) {
              display: none;
            }
          }
        }

        .box-white {
          background: #ffffff;
          backdrop-filter: blur(100px);
          border-radius: 8px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          .value {
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 600;
            font-size: 4.6rem;
            line-height: 130%;
            color: #021c47;
            display: flex;
            @media screen and (max-width: 450px) {
              font-size: 3.2rem;
            }
          }
        }
      }
    }
  }
  .bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    /* @media screen and (max-width: 1023px) {
      position: relative;
      bottom: -118px;
    } */
    /* @media screen and (max-width: 900px) {
      * {
        color: black;
      }
    } */
    .container {
      display: flex;
      align-items: center;
      min-height: 13.4rem;
      flex-wrap: wrap;
      justify-content: space-between;
      .feedback {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1;
        max-width: 498px;
        @media screen and (max-width: 900px) {
          margin: auto;
          flex-wrap: nowrap;
          justify-content: center;
          * {
            text-align: center;
          }
        }
        .left {
          padding-right: 6.7rem;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          @media screen and (max-width: 900px) {
            border-right: 1px solid rgba(192, 205, 236, 0.5);
          }
          @media screen and (max-width: 600px) {
            padding-right: 0.5rem;
          }
          .text {
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 130%;
            color: #ffffff;
            margin-bottom: 1.15rem;
            @media screen and (max-width: 900px) {
              color: #0f204d;
            }
          }

          .star {
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 130%;
            color: #ffffff;
            @media screen and (max-width: 900px) {
              color: #0f204d;
            }
            img {
              margin-right: 1rem;
              @media screen and (max-width: 600px) {
                width: 18px;
                object-fit: none;
                height: 15px;
              }
            }
          }
        }

        .right {
          padding-left: 6.7rem;

          font-family: 'Space Grotesk';
          font-style: normal;
          font-size: 1.6rem;
          line-height: 130%;
          @media screen and (max-width: 600px) {
            padding-left: 0.5rem;
          }
          .text {
            font-weight: normal;
            color: #ffffff;
            margin-bottom: 8px;
            @media screen and (max-width: 900px) {
              color: #0f204d;
            }
          }
          .percentage {
            font-weight: bold;
            color: #ffffff;
            display: flex;
            align-items: center;
            @media screen and (max-width: 900px) {
              color: #0f204d;
              justify-content: center;
            }
            img {
              margin-left: 1.1rem;
            }
          }
        }
      }

      .buy {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 130%;
        color: #ffffff;
        display: flex;
        align-items: center;
        @media screen and (max-width: 900px) {
          display: none;
        }
      }

      span {
      }

      .icon {
        margin-left: 1.6rem;
        img {
        }
      }
    }
  }
`;
export const AboutUs = styled.section`
  padding-bottom: 10.5rem;
  .container {
    margin-top: 12.8rem;
    .top-info {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
      .col {
        flex: 1;
        @media screen and (max-width: 600px) {
          margin: 0;
        }
      }
      .left {
        max-width: 550px;
        @media screen and (max-width: 900px) {
          max-width: unset;
          margin-bottom: 6.1rem;
        }

        .line {
          width: 36px;
          height: 4px;
          background: #48fbff;
          margin-bottom: 2.4rem;
          @media screen and (max-width: 900px) {
            display: none;
          }
        }
        .title {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 500;
          font-size: 3.2rem;
          line-height: 140%;
          color: #0f204d;
          @media screen and (max-width: 600px) {
            font-size: 2.2rem;
          }
        }
      }

      .right {
        margin-top: 2.4rem;
        max-width: 487px;
        @media screen and (max-width: 900px) {
          margin-top: 0;
        }
        @media screen and (max-width: 767px) {
          max-width: unset;
          margin-top: 0;
        }
        .sub-title {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 140%;
          letter-spacing: 0.1em;
          color: #333f55;
          margin-bottom: 0.8rem;
          @media screen and (max-width: 900px) {
            color: #6690f8;
            margin-bottom: 1.8rem;
          }
          @media screen and (max-width: 600px) {
            line-height: 140%;
            text-align: center;
            letter-spacing: 0.1em;
            font-size: 1.2rem;
          }
        }

        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 150%;
          color: #606f8e;
          margin-bottom: 2.4rem;
          @media screen and (max-width: 600px) {
            font-size: 1.4rem;
          }
          b {
            display: block;
            margin-top: 0.8rem;
            font-weight: 600;
            mark {
              display: block;
            }
            @media screen and (max-width: 900px) {
              mark {
                background: transparent;
                &::before {
                  content: '\\A';
                  white-space: pre;
                }
              }
              margin-top: 5.2rem;
            }
          }
        }

        .action {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 150%;
          color: #0f204d;
          display: flex;
          align-items: center;
          @media screen and (max-width: 600px) {
            justify-content: center;
            margin-top: 4.8rem;
          }
          .icon {
            display: flex;
            margin-right: 1.6rem;
          }
        }
      }
    }
  }
`;
export const Partners = styled.section`
  margin-top: 10.5rem;
  padding-bottom: 8rem;
  border-bottom: 1px solid #e4ecfd;
  @media screen and (max-width: 900px) {
    max-width: 389px;
    min-width: 240px;
  }
  @media screen and (max-width: 600px) {
    margin: 8.2rem auto 0;
    padding-top: 7.4rem;
    border: none;
    border-top: 1px solid #e4ecfd;
  }
  .container {
    .sub-title {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.3rem;
      color: #3067f9;
      margin-bottom: 2.3rem;
      @media screen and (max-width: 600px) {
        text-align: center;
        margin-bottom: 3.8rem;
      }
    }

    .cnt-info {
      grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr 1fr;
      grid-auto-columns: minmax(160px, auto);
      display: grid;
      grid-gap: 2.5rem;
      @media screen and (max-width: 1023px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .arrow {
          margin: 0 25px;
        }
        .box-btn {
          text-align: center;
        }
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        * {
          text-align: center;
        }
        .arrow {
          img {
            transform: rotate(90deg);
          }
          text-align: left !important;
          margin: 0 !important;
          &.last {
            display: none;
          }
        }
      }
      .box-info {
        max-width: 174px;
        .highlight {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 600;
          font-size: 4.6rem;
          line-height: 130%;
          color: #0f204d;
        }

        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 150%;
          color: #606f8e;
        }
      }

      .arrow {
        padding-top: 2rem;
        text-align: center;
        /* max-width: 70px; */
      }

      .box-btn {
        @media screen and (max-width: 600px) {
          margin-top: 6.8rem;
        }
        .btn-default {
        }

        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 150%;
          color: #606f8e;
          margin-top: 2rem;
        }
      }
    }
  }
`;
export const WhyUs = styled.section`
  background: #f5f8fd;
  padding-top: 9.1rem;
  padding-bottom: 4rem;
  .container {
    .up-title {
      margin-bottom: 1.6rem;
      text-align: center;
    }

    h2 {
      text-align: center;
      margin-bottom: 5.6rem;
    }
    .MuiAppBar-colorPrimary {
      color: #fff;
      background: transparent;
      box-shadow: none;
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 124.5%;
      /* opacity: 0.5; */
    }
    .MuiTab-wrapper {
      filter: grayscale(1);
      display: flex;
      flex-direction: row;

      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 124.5%;
      text-transform: none;
      color: #333f55;

      svg {
        margin-right: 2rem;
      }
      @media screen and (max-width: 550px) {
        flex-direction: column;
        svg {
          margin: 0 0 2rem;
        }
      }
    }
    .MuiTab-textColorInherit {
      opacity: 0.5;
      transition: all 0.2s ease-in-out;
    }
    .MuiTab-textColorInherit.Mui-selected {
      opacity: 1;
      .MuiTab-wrapper {
        filter: grayscale(0);
      }
    }
    .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
      margin-bottom: 0;
      @media screen and (max-width: 550px) {
        margin-bottom: 1rem;
      }
    }
    .MuiTabs-flexContainer {
      border-bottom: 1px solid #c0cdec;
      width: fit-content;
      margin: 0 auto;
    }
    .PrivateTabIndicator-colorSecondary-3 {
      background-color: #fe4059;
    }
    .MuiTab-root {
      padding: 6px 3.4rem;
      max-width: 300px;
      @media screen and (max-width: 550px) {
        padding: 6px 1.5rem;
      }
    }
    .cnt-tabs {
      margin-top: 7.2rem;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      display: grid;
      grid-gap: 3.2rem;
      min-height: 557px;
      .box {
        background: #ffffff;
        border-radius: 8px;
        padding: 3.5rem 4.6rem;
        max-width: 384px;

        animation-name: fadeIn;
        animation-duration: 1s;
        animation-fill-mode: both;
        @media screen and (max-width: 662px) {
          margin: 0 auto;
        }
        &.delay1 {
          animation-delay: 0.2s;
        }
        &.delay2 {
          animation-delay: 0.4s;
        }
        &.delay3 {
          animation-delay: 0.6s;
        }
        &.delay4 {
          animation-delay: 0.8s;
        }
        &.delay5 {
          animation-delay: 1s;
        }
        &.delay6 {
          animation-delay: 1.2s;
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .icon {
          margin-bottom: 1.5rem;
          @media screen and (max-width: 662px) {
            text-align: center;
          }
        }

        .box-title {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 174.5%;
          color: #0f204d;
          margin-bottom: 0.7rem;
          @media screen and (max-width: 662px) {
            text-align: center;
          }
        }

        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 150%;
          color: #606f8e;
          @media screen and (max-width: 662px) {
            text-align: center;
          }
        }
      }
    }
  }
`;
export const StartsNow = styled.section`
  padding-top: 17rem;
  margin-bottom: 5.7rem;
  .container {
    .up-title {
      text-align: center;
      margin-bottom: 9px;
    }

    .title {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;
      font-size: 3.2rem;
      line-height: 140%;
      text-align: center;
      color: #0f204d;
    }

    .cnt-info {
      margin-top: 8.7rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 13.3rem;
      border-bottom: 1px solid #e4ecfd;
      @media screen and (max-width: 900px) {
        justify-content: center;
      }
      &.reverse {
        flex-wrap: wrap-reverse;
      }
      &:last-child {
        border: none;
      }
      .img {
        position: relative;
        min-width: 280px;
        @media screen and (max-width: 1024px) {
          max-width: 525px;
        }
        .icon-step {
          position: absolute;
          bottom: 25%;
          left: 0;
        }
        .icon-step2 {
          position: absolute;
          bottom: 50%;
          left: 0;
        }
        .icon-step3 {
          position: absolute;
          bottom: 15%;
          left: 0;
        }
        img {
          max-width: 100%;
        }
      }

      .text {
        max-width: 468px;
        flex: 1;
        min-width: 280px;
        margin: 0 25px;
        .step {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 140%;
          letter-spacing: 0.1em;
          color: #6690f8;
          @media screen and (max-width: 886px) {
            text-align: center;
          }
        }

        .text-title {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 600;
          font-size: 2.2rem;
          line-height: 124.5%;
          color: #0f204d;
          margin: 1.5rem 0 3.6rem;
          max-width: 296px;
          @media screen and (max-width: 886px) {
            text-align: center;
            margin: 1.5rem auto 3.6rem;
          }
        }

        .info {
          display: flex;
          align-items: flex-start;
          margin-bottom: 3rem;
          @media screen and (max-width: 886px) {
            /* justify-content: center; */
          }
          .icon {
            margin-top: 5px;
            margin-right: 1.36rem;
            display: flex;
          }

          span {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 150%;
            color: #606f8e;
          }
        }

        .btn-default {
          margin-top: 4rem;
          width: 277px;
          height: 62px;

          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 20px;
          text-align: center;

          color: #ffffff;
          @media screen and (max-width: 886px) {
            background: #ffd5dc;
            color: #fe4059;
            width: 100%;
            max-width: 300px;
            margin: 0 auto 4rem;
            img {
              display: none;
            }
          }
          img {
            margin-left: 9px;
            margin-right: 0;
          }
        }
      }
      &.setp3 {
        .text {
          .text-title {
            margin: 1.8rem 0 2.6rem;
            max-width: unset;
          }
          .info {
            margin-bottom: 2.9rem;
          }
        }
        .btn-default {
          margin-top: 3rem;
        }
      }
    }
  }
`;

export const KnowUs = styled.section`
  background: url(${BgKnowUs}) no-repeat;
  background-size: cover;
  /* margin-bottom: 17.5rem; */
  position: relative;
  z-index: 1;
  .container {
    min-height: 386px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .up-title {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 140%;
      text-align: center;
      letter-spacing: 0.1em;
      color: #48fbff;
      margin-bottom: 0.8rem;
      @media screen and (max-width: 662px) {
        padding-top: 9.9rem;
      }
    }
    .title {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 600;
      font-size: 4.6rem;
      line-height: 130%;
      text-align: center;
      color: #ffffff;
      @media screen and (max-width: 662px) {
        font-size: 3.2rem;
        margin: 1.4rem 0 2.2rem;
      }
      @media screen and (max-width: 462px) {
        font-size: 2.8rem;
      }
    }

    .text {
      max-width: 734px;
      margin-top: 1.6rem;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
      opacity: 0.8;
      @media screen and (max-width: 662px) {
        margin-bottom: 7.8rem;
      }
    }
  }
  .bottom-mob {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #f9fbfe;
    padding-top: 5.9rem;
    padding-bottom: 1.9rem;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: none;
    @media screen and (max-width: 900px) {
      display: block;
    }
    span {
      margin: 0 auto;
      max-width: 344px;
      display: block;
    }
  }
  .team {
    max-width: 1218px;
    padding: 0 10px;
    background: #ffffff;
    height: 76px;
    border: 1px solid #e4ecfd;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    bottom: -28px;
    &:before {
      position: absolute;
      background: linear-gradient(270.07deg, #3067f9 0.34%, #fe4059 98.2%);
      opacity: 0.5;
      filter: blur(62.3003px);
      content: ' ';
      width: 80%;
      height: 50%;
      bottom: -10px;
      z-index: -1;
    }
    @media screen and (max-width: 900px) {
      width: fit-content;
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
    .team-desc {
      margin: 0px auto;
      padding: 0 1rem;
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.3em;
      color: #0f204d;
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    .team-imgs {
      display: flex;
      /* flex-wrap: wrap; */
      align-items: center;
      @media screen and (max-width: 400px) {
        max-width: 100%;
      }
      img {
        margin: 0 6px;
        border-radius: 50%;
        @media screen and (max-width: 400px) {
          max-width: 40px;
          margin: 0 3px;
        }
        @media screen and (max-width: 350px) {
          max-width: 39px;
          margin: 0 2px;
        }
      }
    }
  }
`;
