import React from 'react';
import PropTypes from 'prop-types';
import { CntBlogCard } from './styles';
import RightArrow from '../../images/icon_arrow_right.svg';

const BlogCard = ({
  link,
  urlImg,
  title,
  text,
}) => (
  <CntBlogCard href={`/blog/post/${link}`}>
    <div className="img">
      <div className="overlay-blog" />
      <img src={urlImg} alt="" />
    </div>
    <div className="cnt-text">
      <h3 className="title">{title}</h3>
      <h4 className="text">{text}</h4>
      <div className="read-more">
        <span>Ler mais</span>
        <img src={RightArrow} alt="Ícone Seta" />
      </div>
    </div>
  </CntBlogCard>
);

BlogCard.propTypes = {
  link: PropTypes.string.isRequired,
  urlImg: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
export default BlogCard;
