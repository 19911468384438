import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { CntFaq } from './styles';
import IconWhats from '../../images/icon_whats_red.svg';
// import IconAccordion from '../../images/icon_accordion.svg';
import { ReactComponent as IconAccordion } from '../../images/icon_accordion.svg';

const Faq = ({ faqAccordion }) => {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <CntFaq>
      <div className="container">
        <div className="text">
          <h3 className="sub-title">PERGUNTAS FREQUENTES</h3>
          <h2 className="title">Ficou com alguma dúvida?</h2>
          <div className="txt">Separamos algumas dúvidas comuns, mas caso essas não te ajudem, entre em contato com nosso time pelos canais de contato. Estamos prontos para te ajudar! ;)</div>
          <a href={`${process.env.REACT_APP_DUVIDAS_URL}`} target="_blank" rel="noreferrer" className="talk-to-us">
            <img src={IconWhats} alt="" />
            Ainda com dúvidas? Fale conosco!
          </a>
        </div>
        <div className="faq">
          {faqAccordion.map((el, index) => (
            <Accordion key={el.title} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
              <AccordionSummary
                expandIcon={<IconAccordion />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                {el.title}
              </AccordionSummary>
              <AccordionDetails>
                {el.text}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </CntFaq>
  );
};

Faq.propTypes = {
  faqAccordion: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
};
Faq.defaultProps = {
  faqAccordion: [],
};
export default Faq;
